var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen bow-screen"},[_c('div',{staticClass:"animation",attrs:{"id":"animation-bow"}},[_c('div',{staticClass:"arrow-up-space"},[_c('div',{staticClass:"arrow-up",style:(_vm.bowArrowUpStyles),attrs:{"id":"bow-arrow-up"}},[_c('div',{staticClass:"arrow-up-1 arrow-up-arrow"}),_c('div',{staticClass:"arrow-up-2 arrow-up-arrow"})])]),_c('div',{staticClass:"flip target",style:(_vm.targetStyles),attrs:{"id":"bow-target"}},[_vm._m(0)]),_c('div',{directives:[{name:"touch",rawName:"v-touch:touchhold",value:(_vm.drawString),expression:"drawString",arg:"touchhold"},{name:"touch",rawName:"v-touch:end",value:(_vm.releaseArrow),expression:"releaseArrow",arg:"end"}],staticClass:"arrow-space",on:{"mousedown":_vm.drawString,"mouseup":_vm.releaseArrow}},[_c('div',{staticClass:"box wide tall center",style:(_vm.tipDrawStyles),attrs:{"id":"tip-draw"}},[_vm._v(" Press and hold to aim ")]),_c('div',{staticClass:"box wide tall center",style:(_vm.tipReleaseStyles),attrs:{"id":"tip-release"}},[_vm._v(" Release to shoot ")]),_c('div',{staticClass:"bow",style:(_vm.bowStyles),attrs:{"id":"bow"}},[_c('div',{staticClass:"bow-string-1",style:(_vm.bowString1Styles)}),_c('div',{staticClass:"bow-string-2",style:(_vm.bowString2Styles)})]),_c('div',{staticClass:"bow svg",style:({
					backgroundImage: `url(${ _vm.bowImg })`,
					..._vm.bowStyles
				}),attrs:{"id":"bow-img"}}),_c('div',{staticClass:"bow-arrow",style:({
					backgroundImage: `url(${ _vm.bowArrowImg })`,
					..._vm.bowArrowStyles
				}),attrs:{"id":"bow-arrow"}}),_c('div',{staticClass:"bow-aim-wrap",style:(_vm.bowAimWrapStyles)},[_c('div',{staticClass:"bow-aim",style:(_vm.bowAimStyles)},[_c('div',{staticClass:"bow-aim-line-h"}),_c('div',{staticClass:"bow-aim-line-v"})])])]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"face"},[_c('div',{staticClass:"front"},[_c('div',{staticClass:"text-fixed"},[_vm._v(" The only way to do great work is to "),_c('span',{staticClass:"ion-ios-heart"}),_vm._v(" what you do ")]),_c('div',{staticClass:"target-heart"},[_c('div',{staticClass:"target-heart-ring target-heart-ring-1 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-2 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-3 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-4 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-5 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-6 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-7 ion-ios-heart"}),_c('div',{staticClass:"target-heart-ring target-heart-ring-8 ion-ios-heart"})])]),_c('div',{staticClass:"back"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flip keep"},[_c('div',{staticClass:"face"},[_c('div',{staticClass:"front"},[_c('div',{staticClass:"text-fixed"},[_vm._v(" If you haven't found it yet"),_c('br'),_vm._v(" keep looking"),_c('br'),_vm._v(" don't settle ")])]),_c('div',{staticClass:"back"})])])
}]

export { render, staticRenderFns }