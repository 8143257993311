<template>

	<div class="wizard-stepper-wrap box wide top middle" :class="classs">

		<div class="wizard-stepper-step box" :class="isCurrent(index)" v-for="(item, index) in steps">

			<div class="box wide center">

				<div class="box center fill tall">
					<div class="wizard-stepper-step-line before"></div>
				</div>

				<div class="box center wizard-stepper-step-icon">{{ index + 1 }}</div>

				<div class="box center fill tall">
					<div class="wizard-stepper-step-line after"></div>
				</div>

				<div class="wizard-stepper-step-icon-loader ion-ios-loop"></div>

			</div>

			<div class="box wide center mt-10 wizard-stepper-step-text">{{ item.text }}</div>

		</div>

	</div>

</template>

<script>

export default {

	name: "VueWizardStepper",

	props: ['classes', 'steps', 'current'],

	data() {

		return {

		}
	},

	computed: {

		classs() {
			return this.classes + " "
		}
	},

	methods: {

		isCurrent(index) {

			let step = index + 1
			return (
				this.current < step ?
					''
					:
					(this.current > step ? 'done' : 'current')
			)
		}
	}
}
</script>

<style lang="scss" scoped>

.wizard-stepper-wrap {

	user-select: none;

	.wizard-stepper-step {

		width: 100px;
		cursor: default;

		.wizard-stepper-step-line {
			width: 100%;
			height: 1px;
			background-color: lighten($theme-color, 40%);
		}

		.wizard-stepper-step-icon {
			width: 50px;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
			text-align: center;
			border-radius: 25px;
			border: 1px solid lighten($theme-color, 40%);
			color: lighten($theme-color, 40%);
		}

		@keyframes stepperLoader {
			from { transform: rotate(0deg); }
			to { transform: rotate(360deg); }
		}

		.wizard-stepper-step-icon-loader {
			position: absolute;
			display: none;
			width: 60px;
			height: 60px;
			line-height: 60px;
			font-size: 60px;
			color: $theme-color;
		}

		.wizard-stepper-step-text {
			color: $text-color;
			font-size: 16px;
			padding: 10px;
		}

		&.current {

			.wizard-stepper-step-line.before {
				background-color: $theme-color;
			}

			.wizard-stepper-step-icon {
				border-color: transparent;
				color: $theme-color;
			}

			.wizard-stepper-step-icon-loader {
				display: block;
				animation: stepperLoader 6s infinite linear;
			}

			.wizard-stepper-step-line.after {
				background-color: lighten($theme-color, 40%);
			}
		}

		&.done {

			.wizard-stepper-step-line.before {
				background-color: $theme-color;
			}

			.wizard-stepper-step-icon {
				border-color: $theme-color;
				background-color: $theme-color;
				color: $contrast-color;
			}

			.wizard-stepper-step-line.after {
				background-color: $theme-color;
			}
		}

		&:first-child {

			.wizard-stepper-step-line.before {
				height: 0px;
			}
		}

		&:last-child {

			.wizard-stepper-step-line.after {
				height: 0px;
			}
		}
	}
}

.theme-alt {

	.wizard-stepper-wrap {

		.wizard-stepper-step {

			.wizard-stepper-step-line {
				background-color: lighten($alt-theme-color, 30%);
			}

			.wizard-stepper-step-icon {
				border: 1px solid lighten($alt-theme-color, 30%);
				color: lighten($alt-theme-color, 30%);
			}

			.wizard-stepper-step-icon-loader {
				color: $alt-theme-color;
			}

			.wizard-stepper-step-text {
				color: $alt-text-color;
			}

			&.current {

				.wizard-stepper-step-line.before {
					background-color: $alt-theme-color;
				}

				.wizard-stepper-step-icon {
					border-color: transparent;
					color: $alt-theme-color;
				}

				.wizard-stepper-step-line.after {
					background-color: lighten($alt-theme-color, 30%);
				}
			}

			&.done {

				.wizard-stepper-step-line.before {
					background-color: $alt-theme-color;
				}

				.wizard-stepper-step-icon {
					border-color: $alt-theme-color;
					color: $alt-text-color;
					background-color: $alt-theme-color;
				}

				.wizard-stepper-step-line.after {
					background-color: $alt-theme-color;
				}
			}
		}
	}
}
</style>
