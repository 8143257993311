<template>

	<label class="checkbox box v-mid" :class="classNames" @click="toggleStatus">

		<input
			type="checkbox"
			:checked="status"
			:disabled="isDisabled"
		/>

		<span ref="checkmark" class="checkmark box center"></span>

		<slot></slot>

	</label>

</template>

<script>

export default {

	name: "VueCheckbox",

	props: ["classes", "checked", "disabled"],

	data() {

		return {
			status: this.checked === true ? true : false
		}
	},

	computed: {

		classNames() {

			return (
				this.classes +
				(this.isDisabled ? ' disabled' : '') +
				(this.isChecked ? ' checked' : '')
			)
		},

		isDisabled() {

			return this.disabled == null ? false : true
		},

		isChecked() {

			return !this.checked && !this.status ? false : true
		}
	},

	methods: {

		toggleStatus(e) {

			e.preventDefault()

			if (this.isDisabled)
				return

			this.status = !this.status

			this.$emit('toggle', this.status)
		}
	},

	watch: {

		checked: function(newVal, oldVal) {

			if (newVal === true || newVal === false)
				this.status = newVal
		}
	}
}

</script>

<style lang="scss" scoped>

$checkbox-box-background: lighten($highlight-color, 25%);
$checkbox-box-background-hover: darken($checkbox-box-background, 10%);
$checkbox-box-background-checked: $theme-color;
$checkbox-box-background-disabled: #bbb;
$checkbox-checkmark-color: rgba(255, 255, 255, 1);
$checkbox-checkmark-color-disabled: #999;
$checkbox-border: none;

.checkbox {

	position: relative;
	width: auto;
	height: 25px;
	line-height: 25px;
	font-size: $text-font-size;
	user-select: none;
	color: $text-color;
	transition: all 200ms linear;
	cursor: pointer;

	&:hover {
		color: $text-color-hover;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		width: 0;
		height: 0;
	}

	.checkmark {
		margin-right: 10px;
		width: 25px;
		height: 25px;
		background-color: $checkbox-box-background;
		border: $checkbox-border;
		transition: all 200ms linear;
	}

	&:hover .checkmark {
		background-color: $checkbox-box-background-hover;
	}

	input:checked ~ .checkmark {
		background-color: $checkbox-box-background-checked;
	}

	.checkmark:after {
		content: "";
		display: none;
		left: 9px;
		top: 5px;
		width: 5px;
		height: 13px;
		border: solid $checkbox-checkmark-color;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
	input:checked ~ .checkmark:after {
		display: block;
	}

	&.disabled {

		color: $text-color-inactive;

		.checkmark {
			background-color: $checkbox-box-background-disabled;
			border: $checkbox-border;
		}

		input:checked ~ .checkmark {
			background-color: $checkbox-box-background-disabled;
		}

		.checkmark:after {
			border: solid $checkbox-checkmark-color-disabled;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
		}
	}

	@for $i from 1 through 100 {

		$radius: #{$i * 1px};

		&.radius-#{$i} {

			.checkmark {
				border-radius: $radius !important;
			}
		}
	}

	&.checkmark-black {

		.checkmark:after {
			border: solid black;
			border-width: 0 3px 3px 0;
		}
	}

	&.checkmark-white {

		.checkmark:after {
			border: solid white;
			border-width: 0 3px 3px 0;
		}
	}

	&.button {

		border: 1px solid $checkbox-box-background;
		color: $checkbox-box-background;
		border-radius: 50px;
		padding: 10px 20px 10px 15px;
		height: auto;

		.checkmark {

			margin-right: 0px;
			background-color: transparent;

			&:before {
				content: "";
				display: block;
				width: 1px;
				height: 15px;
				border: solid $checkbox-box-background;
				border-width: 0 1px 0 0;
				transform: rotate(0deg);
			}

			&:after {
				content: "";
				display: block;
				margin-left: -8px; margin-top: -2px;
				width: 15px;
				height: 1px;
				border: solid $checkbox-box-background;
				border-width: 0 0 1px 0;
				transform: rotate(0deg);
			}
		}

		input:checked ~ .checkmark {

			background-color: transparent;

			&:before {
				display: none;
			}

			&:after {
				content: "";
				left: 9px;
				top: 5px;
				width: 5px;
				height: 10px;
				border: solid $checkbox-checkmark-color;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
			}
		}

		&.checked {
			background-color: $checkbox-box-background;
			color: rgba(255, 255, 255, 1);
		}

		&.disabled {
			background-color: transparent;
			border: 1px solid rgba(0, 0, 0, 0.2);
			color: rgba(0, 0, 0, 0.2);

			.checkmark {
				&:before {
					border-color: rgba(0, 0, 0, 0.2) !important;
				}
				&:after {
					border-color: rgba(0, 0, 0, 0.2) !important;
				}
			}
		}
	}
}

$alt-checkbox-box-background: rgba(255, 255, 255, 0.3);
$alt-checkbox-box-background-hover: lighten($alt-highlight-color, 10%);
$alt-checkbox-box-background-checked: $alt-highlight-color;
$alt-checkbox-box-background-disabled: rgba(0, 0, 0, 0.2);
$alt-checkbox-checkmark-color: rgba(0, 0, 0, 1);
$alt-checkbox-checkmark-color-disabled: #999;
$alt-checkbox-border: none;

.theme-alt {

	.checkbox {

		color: $alt-text-color;

		&:hover {
			color: $alt-text-color-hover;
		}

		.checkmark {

			background-color: $alt-checkbox-box-background;
			border: $alt-checkbox-border;
		}
		&:hover .checkmark {
			background-color: $alt-checkbox-box-background-hover;
		}

		input:checked ~ .checkmark {
			background-color: $alt-checkbox-box-background-checked;
		}

		.checkmark:after {

			border: solid $alt-checkbox-checkmark-color;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
		}

		&.disabled {

			color: $alt-text-color-inactive;

			.checkmark {
				background-color: $alt-checkbox-box-background-disabled;
				border: $alt-checkbox-border;
			}

			input:checked ~ .checkmark {
				background-color: $alt-checkbox-box-background-disabled;
			}

			.checkmark:after {
				border: solid $alt-checkbox-checkmark-color-disabled;
				border-width: 0 3px 3px 0;
				transform: rotate(45deg);
			}
		}

		&.button {

			border: 1px solid $alt-theme-color;

			.checkmark {

				background-color: transparent;

				&:before {
					content: "";
					display: block;
					width: 1px;
					height: 15px;
					border: solid $alt-theme-color;
					border-width: 0 1px 0 0;
					transform: rotate(0deg);
				}

				&:after {
					content: "";
					display: block;
					margin-left: -8px; margin-top: -2px;
					width: 15px;
					height: 1px;
					border: solid $alt-theme-color;
					border-width: 0 0 1px 0;
					transform: rotate(0deg);
				}
			}

			input:checked ~ .checkmark {

				background-color: transparent;

				&:before {
					display: none;
				}

				&:after {
					content: "";
					left: 9px;
					top: 5px;
					width: 5px;
					height: 10px;
					border: solid $alt-checkbox-checkmark-color;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}

			&.checked {
				background-color: $alt-theme-color;
			}

			&.disabled {

				.checkmark {
					&:before {
						border-color: rgba(255, 255, 255, 0.2) !important;
					}
					&:after {
						border-color: rgba(255, 255, 255, 0.2) !important;
					}
				}
			}
		}
	}
}
</style>
