<template>

	<div class="screen chocolates">

		<div id="animation-chocolate" :style="chocolateAnimationStyles">

			<div id="chocolate-text">
				<div>&nbsp;you&nbsp;</div>
				<div>&nbsp;never&nbsp;</div>
				<div>&nbsp;know&nbsp;</div>
				<div>&nbsp;what&nbsp;</div>
				<div>&nbsp;you&nbsp;</div>
				<div>&nbsp;are&nbsp;</div>
				<div>&nbsp;gonna&nbsp;</div>
				<custom-button @click.native="get">get</custom-button>
			</div>

			<!-- chocolates -->
			<div class="chocolate-wrap box fill">

				<div class="chocolate-box box wide tall" :style="chocolateBoxStyles">

					<div class="chocolate-circle" :class="isRotating ? 'rotate' : ''" :style="rotateStyle">

						<div
							class="chocolate"
							v-for="(item, index) in chocolates"
							:style="{
								backgroundImage: `url(${ item.img })`,
								...chocolateStyles,
								...chocolatePositions[index]
							}"
						></div>

					</div>

					<div
						class="chocolate-pick"
						:style="{
							backgroundImage	: `url(${ this.chocolatePickedImage })`,
							...chocolatePickedStyles
						}"
					></div>

				</div>

			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Chocolate',

	props: ['config'],

	data() {

		return {
			chocolates: [
				{
					img: require('@/assets/img/chocolates/0.svg')
				},
				{
					img: require('@/assets/img/chocolates/1.svg')
				},
				{
					img: require('@/assets/img/chocolates/2.svg')
				},
				{
					img: require('@/assets/img/chocolates/3.svg')
				},
				{
					img: require('@/assets/img/chocolates/4.svg')
				},
				{
					img: require('@/assets/img/chocolates/5.svg')
				},
				{
					img: require('@/assets/img/chocolates/6.svg')
				},
				{
					img: require('@/assets/img/chocolates/7.svg')
				},
				{
					img: require('@/assets/img/chocolates/8.svg')
				},
				{
					img: require('@/assets/img/chocolates/9.svg')
				}
			],
			chocolateAnimationStyles: {
				opacity: 0,
				top: '100%'
			},
			rotateStyle: {
				animationPlayState: 'running'
			},
			chocolatePickedStyles: {
				backgroundImage: `url(${ this.chocolatePicked })`
			},
			chocolateStyles: {
				width: '100px',
				height: '100px'
			},
			chocolatePositions: [
				{},
				{},
				{},
				{},
				{},
				{},
				{},
				{},
				{},
				{},
			],
			chocolateBoxStyles: {},
			rotateChocolate: 0,
			pickChocolate: 0,
			chocolatePickedID: 0,
			randomChocolate: -1,
			lastChocolate: -1,
			isRotating: false,
			resizing: 0
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		},

		chocolatePickedImage() {

			return require('@/assets/img/chocolates/' + this.chocolatePickedID + '.svg')
		}
	},

	mounted() {

		window.addEventListener("resize", this.resize)

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				this.chocolateAnimationStyles = {
					opacity	: 0,
					top		: '100%'
				}

				setTimeout(() => {

					this.rotate()

					this.chocolateAnimationStyles = {
						opacity	: 1,
						top		: '0'
					}
				})

			} else if (step == 2) {

				this.chocolateAnimationStyles = {
					opacity	: 1,
					top		: '0'
				}

				setTimeout(() => {

					this.chocolateAnimationStyles = {
						opacity	: 0,
						top		: '-100%'
					}
				})
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.isRotating = false

				this.chocolateAnimationStyles = {
					opacity	: 1,
					top		: '0'
				}

				clearInterval(this.rotateChocolate)
				this.rotateChocolate  = -1

				setTimeout(() => {

					this.chocolateAnimationStyles = {
						opacity	: 0,
						top		: '100%'
					}
				})

			} else if (step == 2) {

				this.chocolateAnimationStyles = {
					opacity	: 0,
					top		: '-100%'
				}

				setTimeout(() => {

					this.rotate()

					this.chocolateAnimationStyles = {
						opacity	: 1,
						top		: '0'
					}
				})
			}
		},

		get() {

			if (this.rotateChocolate <= 0)
				return

			clearInterval(this.rotateChocolate)
			this.rotateChocolate  = -1

			this.rotateStyle = {
				animationPlayState: 'paused'
			}

			// return 0 if never get before
			if (this.randomChocolate < 0)
				this.randomChocolate = 0
			else
				this.randomChocolate = Math.round(Math.random() * 9)

			if (this.randomChocolate == this.lastChocolate) {
				this.get()
				return
			}

			this.lastChocolate = this.randomChocolate

			this.chocolatePickedID = this.randomChocolate

			setTimeout(() => {

				this.rotateStyle = {
					animationPlayState: 'running'
				}

				this.rotateChocolate = setInterval(() => {

					this.chocolatePickedID = (this.chocolatePickedID + 1) % 10

				}, 200)

			}, 2000)
		},

		rotate() {

			let width = $('#animation-chocolate').children('.chocolate-wrap').width()
			let height = $('#animation-chocolate').children('.chocolate-wrap').height()

			let size = Math.min(width, height)
			let radius = size / 2

			let longSide = Math.max(width, height)
			let radiusMarginX = (longSide - size) / 2

			if (width < height)
				radiusMarginX = 0

			this.chocolateBoxStyles = {
				width	: size + 'px',
				height	: size + 'px'
			}

			this.chocolatePickedStyles = {
				width	: size/3 + 'px',
				height	: size/3 + 'px'
			}

			let windowSize = Math.min($(window).width(), $(window).height())
			let chocolateWidth = windowSize / 10
			chocolateWidth = chocolateWidth < 40 ? 40 : chocolateWidth
			chocolateWidth = chocolateWidth > 120 ? 120 : chocolateWidth

			this.chocolateStyles = {
				width	: chocolateWidth + 'px',
				height	: chocolateWidth + 'px'
			}

			let radiusBuffer = chocolateWidth
			radius -= radiusBuffer

			let chocolatePositions = []

			for (let i = 0; i < 10; i++) {

				let left = radiusMarginX + radiusBuffer + radius + radius * (Math.cos(Math.PI / 5 * i))
				let top = radiusBuffer + radius + radius * (Math.sin(Math.PI / 5 * i))

				chocolatePositions.push({

					left: left + 'px',
					top	: top + 'px'
				})
			}

			this.chocolatePositions = chocolatePositions

			this.isRotating = true

			this.rotateChocolate = setInterval(() => {

				this.chocolatePickedID = (this.chocolatePickedID + 1) % 10

			}, 200)
		},

		resize() {

			clearTimeout(this.resizing)

			this.resizing = setTimeout(() => {

				this.rotate()
				
			}, 100)
		}
	}
}

</script>




<style lang="scss" scoped>

.chocolates {

	background-color: tan;

	#animation-chocolate {

		position: absolute; left: 0; top: 100%;
		width: 100%; height: 100%;
		opacity: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		transition: all 1s ease-out;

		#chocolate-text {

			position: relative;
			margin: 20px; margin-top: 50px; margin-bottom: 10px;
			width: 90%; width: calc(100% - 40px);
			font-size: 3rem; color: #000;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			align-content: center;
			z-index: 2;

			div {
				margin: 0.5rem;
			}

			button {
				margin: 0.5rem;
				font-size: 3rem;
			}
		}

		.chocolate-wrap {

			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.chocolate-box {

				position: relative;

				.chocolate-pick {
					position: absolute; left: 50%; top: 50%;
					transform: translate(-50%, -50%);
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					transition: all 0s linear;
				}

				@keyframes rotateChocolates {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
				.chocolate-circle {

					position: absolute; left: 0; top: 0;
					width: 100%; height: 100%;
					transform-origin: center center;

					&.rotate {
						animation: rotateChocolates 5s linear infinite;
					}

					.chocolate {

						position: absolute;
						width: 50px; height: 50px;
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
}

</style>
