var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-list-wrap",style:(_vm.creditWrapStyle),attrs:{"id":"credit-list-wrap"}},[_c('div',{staticClass:"credit-list",style:(_vm.creditStyle),attrs:{"id":"credit-list"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_c('div',{staticClass:"credit-item major"}),_vm._m(16),_vm._m(17)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Director ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Writers ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Producer ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Executive Producer ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Production Designers ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Art Director ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" User Interface ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" User Experience ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Visual Effects ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Illustrator ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Colorist ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Animation ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Developers ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Browser Optimization ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Mobile Optimization ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" Ideas ")]),_c('div',{staticClass:"credit-item-value"},[_vm._v(" Barry Youlin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item major"},[_c('div',{staticClass:"credit-item-name"},[_vm._v(" R.I.P."),_c('br'),_vm._v(" XL"),_c('br'),_vm._v(" 1950 - 2018 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-item major"},[_c('div',{staticClass:"credit-item-value"},[_vm._v(" Y.N.W.A. ")])])
}]

export { render, staticRenderFns }