<template>

	<div class="bar-layout" :class="classes">

		<div
			class="bar-content"
			:style="{
				left: contentStyle.left,
				width: contentStyle.width
			}"
		>

			<vue-scroll>

				<slot />

			</vue-scroll>

		</div>

		<vue-sidebar
			v-if="displaySidebar"
			:menu="menu"
			@select="onMenuSelect"
		/>

		<vue-topbar
			v-if="displayTopbar"
			:menu="menu"
			@select="onMenuSelect"
		/>

	</div>

</template>

<script>

	export default {

		name: "VueBarLayout",

		props: ['menu', 'breakpoint'],

		data() {
			return {
				screenWidth: $(window).width()
			}
		},

		computed: {

			classes() {
				return `
					${ this.displaySidebar ? 'with-sidebar' : '' }
					${ this.displayTopbar ? 'with-topbar' : '' }
				`
			},

			displayTopbar() {
				return !this.breakpoint || (this.breakpoint && this.screenWidth <= this.breakpoint)
			},

			displaySidebar() {
				return !this.breakpoint || (this.breakpoint && this.screenWidth > this.breakpoint)
			},

			contentStyle() {

				let style = {}

				if (this.displaySidebar) {

					style = {
						left: '250px',
						width: `calc(100% - 250px)`
					}

				} else if (this.displayTopbar) {

					style = {
						left: '0px',
						width: '100%'
					}

				}

				return style
			}
		},

		methods: {

			onMenuSelect(item) {

				this.$emit('select', item)
			},

			resize(e) {

				this.screenWidth = $(window).width()
			}
		},

		created() {

			window.addEventListener("resize", this.resize)
		},

		destroyed() {

			window.removeEventListener("resize", this.resize)
		}
	}

</script>


<style lang="scss" scoped>

	.bar-layout {

		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;
		padding: 0;
		overflow: hidden;

		.bar-content {

			position: absolute;
			left: 0; top: 0;
			width: 100%; height: 100%;
		}

		&.with-topbar {

			.bar-content {

				top: 80px;
			}
		}

		&.with-sidebar {

			.bar-content {

			}
		}
	}

</style>
