<template>

	<div
		class="tooltip-wrap box"
		:class="classNames"
		ref="wrap"
		:style="{
			position: position ? 'absolute' : 'relative',
			left	: position ? position.x + 'px' : '',
			top		: position ? position.y + 'px' : ''
		}">

		<vue-button
			classes="tooltip-button icon"
			:icon="icon ? icon : 'ion-ios-information-outline'"
			@click.native="toggleDisplay"
			v-click-outside="hideDisplay"
			:style="{ ...styles }">
		</vue-button>

		<portal to="top-layer">

			<div
				class="tooltip box"
				:class="{ [showArea]: true }"
				ref="tooltip"
				:style="{
					left: showLeft + 'px',
					top: showTop + 'px',
					width: showWidth ? showWidth + 'px' : 'auto',
					background,
					color,
					display
				}">

				<div
					class="tooltip-arrow"
					:style="{ ...arrowStyle }"
					ref="arrow"
				></div>

				<div class="box wide tooltip-text">{{ text }}</div>

			</div>

		</portal>

	</div>

</template>

<script>

export default {

	name: "VueTooltip",

	props: [
		'classes',
		'styles',
		'icon',
		'position',
		'color',
		'background',
		'status',
		'text',
		'icon'
	],

	data() {
		return {
			display: this.status == true ? 'flex' : 'none',
			showArea: 'left',
			showLeft: 0,
			showTop: 0,
			showWidth: 0
		}
	},

	computed: {

		classNames() {
			return (
				(this.classes ? this.classes : '') + (this.position ? ' pinned' : '')
			)
		},

		arrowStyle() {

			let style = {}

			if (this.background) {

				if (this.showArea == 'left')
					style.borderLeftColor = this.background
				else if (this.showArea == 'right')
					style.borderRightColor = this.background
				else if (this.showArea == 'top')
					style.borderTopColor = this.background
				else if (this.showArea == 'bottom')
					style.borderBottomColor = this.background
			}

			return style
		}
	},

	methods: {

		toggleDisplay() {

			this.display = this.display == 'none' ? 'flex' : 'none'

			this.adjust()
		},

		hideDisplay() {

			this.display = 'none'
		},

		adjust() {

			// need a double $nextTick for Portal-Vue to get refs
			this.$nextTick().then(

				this.$nextTick(() => {

					// auto set the position of the tooltip
					// and prevent it being cut off screen

					$(this.$refs.tooltip).css({
						marginLeft	: '',
						marginRight	: '',
						marginTop	: '',
						marginBottom: '',
						width		: this.width ? this.width + 'px' : 'auto',
						height		: ''
					})

					$(this.$refs.arrow).css({
						marginLeft	: '',
						marginRight	: '',
						marginTop	: '',
						marginBottom: ''
					})

					if (this.display == 'none')
						return

					let winw = $(window).width()
					let winh = $(window).height()

					let left = $(this.$refs.tooltip).offset().left
					let top = $(this.$refs.tooltip).offset().top
					let width = $(this.$refs.tooltip).outerWidth()
					let height = $(this.$refs.tooltip).outerHeight()

					let wrapLeft = $(this.$refs.wrap).offset().left
					let wrapTop = $(this.$refs.wrap).offset().top
					let wrapWidth = $(this.$refs.wrap).outerWidth()
					let wrapHeight = $(this.$refs.wrap).outerHeight()

					let area = 'right'

					if (wrapLeft + wrapWidth / 2 < winw / 2) {

						area = 'right'

					} else {

						area = 'left'
					}

					if (winw < 512) {

						if (wrapTop > winh * 0.5) {

							area = 'top'

						} else {

							area = 'bottom'
						}
					}

					this.showArea = area

					if (area == 'left') {

						this.showLeft = wrapLeft - width - 10
						this.showTop = wrapTop + (wrapHeight / 2) - (height / 2)

					} else if (area == 'right') {

						this.showLeft = wrapLeft + wrapWidth + 10
						this.showTop = wrapTop + (wrapHeight / 2) - (height / 2)

					} else if (area == 'top') {

						this.showLeft = (wrapLeft + wrapWidth / 2) - (width / 2)
						this.showTop = wrapTop - height - 10

					} else if (area == 'bottom') {

						this.showLeft = (wrapLeft + wrapWidth / 2) - (width / 2)
						this.showTop = wrapTop + wrapHeight + 10
					}

					// if (left < 0) {
					//
					// 	if (this.showArea == 'top' || this.showArea == 'bottom') {
					//
					// 		// being cut off on the left of screen
					//
					// 		$(this.$refs.tooltip).css({
					// 			marginLeft: (-left + 10) + 'px'
					// 		})
					//
					// 		$(this.$refs.arrow).css({
					// 			marginLeft: (left - 10) + 'px'
					// 		})
					//
					// 	} else if (this.showArea == 'left') {
					//
					// 		$(this.$refs.tooltip).css({
					// 			width: (width + left - 10) + 'px'
					// 		})
					// 	}
					//
					// }
					//
					// if (left + width > winw) {
					//
					// 	// being cut off on the right of the screen
					//
					// 	let diff = left + width - winw
					//
					// 	if (this.showArea == 'top' || this.showArea == 'bottom') {
					//
					// 		// padding is 30
					//
					// 		$(this.$refs.tooltip).css({
					// 			marginLeft: (-diff - 30 - 5) + 'px'
					// 		})
					//
					// 		$(this.$refs.arrow).css({
					// 			marginLeft: (diff + 30 + 5) + 'px'
					// 		})
					//
					// 	} else if (this.showArea == 'right') {
					//
					// 		$(this.$refs.tooltip).css({
					// 			width: (width - diff - 10) + 'px'
					// 		})
					// 	}
					// }
				})
			)
		}
	},

	watch: {

		status(newVal, oldVal) {

			this.display = newVal ? 'flex' : 'none'

			setTimeout(() => this.adjust())
		}
	},

	mounted() {

		this.adjust()
	}
}
</script>


<style lang="scss" scoped>

$background: lighten($theme-color, 70%);
$arrowSize: 10px;

.tooltip-wrap {

	position: relative;
	width: 30px; height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: none;

	&.pinned {
		position: absolute;
	}

	.tooltip-button {
		width: 30px; height: 30px;
		font-size: 30px; line-height: 30px;
	}
}

.tooltip {

	position: absolute;
	width: auto; max-width: 300px;
	padding: 15px;
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	background-color: $background;
	color: $text-color;
	transition: none;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

	&.center {
		justify-content: center;
	}


	.tooltip-arrow {

		position: absolute;
		transition: none;
	}

	&.left {
		// right: calc(100% + 15px);

		.tooltip-arrow {
			right: -9px; top: 50%;
			transform: translateY(-50%);
			width: 0; height: 0;
			border-top: $arrowSize solid transparent;
			border-bottom: $arrowSize solid transparent;
			border-left: $arrowSize solid $background;
		}
	}

	&.right {
		// left: calc(100% + 15px);

		.tooltip-arrow {
			left: -9px; top: 50%;
			transform: translateY(-50%);
			width: 0; height: 0;
			border-top: $arrowSize solid transparent;
			border-bottom: $arrowSize solid transparent;
			border-right: $arrowSize solid $background;
		}
	}

	&.top {
		// bottom: calc(100% + 15px);

		.tooltip-arrow {
			bottom: -9px; left: 50%;
			transform: translateX(-50%);
			width: 0; height: 0;
			border-left: $arrowSize solid transparent;
			border-right: $arrowSize solid transparent;
			border-top: $arrowSize solid $background;
		}
	}

	&.bottom {
		// top: calc(100% + 15px);

		.tooltip-arrow {
			top: -9px; left: 50%;
			transform: translateX(-50%);
			width: 0; height: 0;
			border-left: $arrowSize solid transparent;
			border-right: $arrowSize solid transparent;
			border-bottom: $arrowSize solid $background;
		}
	}

	.tooltip-icon {
		height: 80px;
		line-height: 80px; font-size: 70px; text-align: center;
		justify-content: center;
		align-items: center;
	}

	.tooltip-text {
		font-size: 18px;
		line-height: 25px;
	}
}

</style>
