<template>

	<div class="breaker" :class="classes"></div>

</template>

<script>

export default {

	name: "FlexBreak",

	props: ["classes"],

	data() {
		return {}
	}
}

</script>

<style lang="scss" scoped>

.breaker {

	width: 100%;
}

</style>
