<template>

	<div class="counter-box" :class="classNames">

		<span class="counter-min">{{ min < 10 ? `0${ min }` : min }}</span>
		<span class="counter-min">:</span>
		<span class="counter-min">{{ sec < 10 ? `0${ sec }` : sec }}</span>
		<span class="counter-min">:</span>
		<span class="counter-min">{{ ms < 10 ? `0${ ms }` : ms }}</span>

	</div>

</template>

<script>

export default {

	name: "VueCounter",

	props: ['action'],

	data() {

		return {
			min: 0,
			sec: 0,
			ms: 0,
			interval: 0,
			status: 'reset'
		}
	},

	computed: {

		classNames() {

		}
	},

	methods: {

		start() {

			this.interval = setInterval(() => {

				this.ms += 1

				if (this.ms >= 10) {

					this.ms = 0
					this.sec += 1

					if (this.sec >= 60) {

						this.min += 1
						this.sec = 0
					}
				}

			}, 100)
		},

		pause() {

			clearInterval(this.interval)
			this.interval = 0

			this.$emit('pause', {
				min: this.min,
				sec: this.sec,
				ms: this.ms
			})
		},

		reset() {

			clearInterval(this.interval)
			this.min = 0
			this.sec = 0
			this.ms = 0
			this.interval = 0
		}
	},

	mounted() {

	},

	watch: {

		action(newVal, oldVal) {

			if (this.status != newVal) {

				if (newVal == 'reset') {

					this.reset()

				} else if (newVal == 'pause') {

					this.pause()

				} else if (newVal == 'start') {

					this.start()

				}

				this.status = newVal
			}
		}
	}
}
</script>

<style lang="scss" scoped>


</style>
