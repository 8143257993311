<template>

	<div
		class="dropdown-wrap"
		:class="classNames"
	>

		<div
			class="dropdown-selected"
			@click.stop="toggleDropdown"
			v-click-outside="collapseDropdown"
		>

			<div class="dropdown-selected-text">
				{{ getText(selected) }}
			</div>

			<div class="dropdown-arrow"></div>

		</div>

		<portal to="top-layer">

			<ul
				v-if="expanded"
				class="dropdown-list"
				:style="{
					left: listRect.left + 'px',
					top: listRect.top + 'px',
					width: listRect.width + 'px'
				}"
			>

				<li v-for="(item, index) in options" @click="selectItem(index)">

					<span>{{ getText(index) }}</span>

				</li>

			</ul>

		</portal>

	</div>

</template>

<script>

export default {

	name: "VueDropdown",

	props: ["classes", "options", "placeholder"],

	data() {
		return {
			expanded: false,
			selected: null,
			rect: {},
			listRect: {
				left: 0,
				top: 0,
				width: 0
			}
		}
	},

	computed: {

		classNames() {
			return (
				this.classes +
				" " +
				(this.expanded ? "expanded" : "") +
				" " +
				(this.label ? "" : "no-label")
			)
		}
	},

	methods: {

		toggleDropdown(e) {

			this.expanded = !this.expanded

			this.handleDropdownDisplay()

			if (!this.expanded)
				return

			this.rect = e.currentTarget.getBoundingClientRect()

			this.calculateListRect()
		},

		collapseDropdown() {

			this.expanded = false

			this.handleDropdownDisplay()
		},

		handleDropdownDisplay() {

			if (this.expanded)
				$('body, .vue-scroll').css('overflow', 'hidden')
			else
				$('body, .vue-scroll').css('overflow', 'scroll')
		},

		getText(i) {
			if (i == null || !this.options[i]) {
				if (this.placeholder) {
					return this.placeholder
				} else {
					return this.getText(0)
				}
			} else if (this.options[i].text) {
				return this.options[i].text
			} else {
				return this.options[i]
			}
		},

		selectItem(i) {
			this.selected = i
			this.collapseDropdown()
		},

		calculateListRect() {

			let left = this.rect.left
			let top = this.rect.top + this.rect.height + 5
			let width = this.rect.width

			let listHeight = this.options.length * 50
			listHeight = listHeight < 325 ? listHeight : 325

			if (top + listHeight > window.innerHeight) {
				top = this.rect.top - listHeight - 5
			}

			this.listRect.left = left
			this.listRect.top = top
			this.listRect.width = width
		}
	}
}
</script>


<style lang="scss" scoped>

* {
	box-sizing: border-box;
	user-select: none;
}

$dropdown-text-color: rgba(0, 0, 0, 0.7);
$dropdown-text-color-hover: rgba(0, 0, 0, 1);
$dropdown-border-width: 1px;
$dropdown-border-color: rgba(0, 0, 0, 0.4);
$dropdown-border-color-hover: rgba(0, 0, 0, 1);
$dropdown-border: $dropdown-border-width solid $dropdown-border-color;
$dropdown-background: rgba(255, 255, 255, 0.5);
$dropdown-background-hover: rgba(255, 255, 255, 0.8);
$dropdown-list-background: #eee;
$dropdown-list-background-hover: rgba(255, 255, 255, 1);
$dropdown-list-border: 1px solid #000;

.dropdown-wrap {

	position: relative;
	width: 100%;

	.dropdown-selected {

		position: relative;
		width: 100%;
		height: 50px;
		border-radius: 10px;
		line-height: 50px;
		font-size: $text-font-size;
		color: $dropdown-text-color;
		border: $dropdown-border;
		cursor: pointer;
		padding-left: 10px;
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		background-color: $dropdown-background;
		transition: all 200ms linear;

		.dropdown-selected-text {

			width: calc(100% - 50px);
		}

		.dropdown-arrow {

			position: absolute;
			right: 10px;
			top: 23px;
			width: 16px;
			height: 10px;
			transition: all 200ms linear;

			&:before {

				position: absolute; left: 0px;
				width: 2px;
				height: 10px;
				background-color: $dropdown-text-color;
				content: " ";
				transform: rotate(-50deg);
				transform-origin: 0 0;
			}

			&:after {

				position: absolute;
				left: 14px;
				width: 2px;
				height: 10px;
				background-color: $dropdown-text-color;
				content: " ";
				transform: rotate(50deg);
				transform-origin: right top;
			}
		}

		&:hover {

			background-color: $dropdown-background-hover;
			color: $dropdown-text-color-hover;
			border-color: $dropdown-border-color-hover;

			.dropdown-arrow {

				&:before {
					background-color: $dropdown-text-color-hover;
				}

				&:after {
					background-color: $dropdown-text-color-hover;
				}
			}
		}
	}

	&.lite {

		.dropdown-selected {

			border-radius: 0;
			background-color: transparent;
			border: none;
			border-bottom: $dropdown-border-width solid $dropdown-border-color;
		}
	}

	&.expanded {

		.dropdown-selected {

			.dropdown-arrow {
				transform: rotate(180deg) translate(0px, 6px);
			}
		}
	}


	&.center {

		label {
			text-align: center;
		}

		.dropdown-selected {

			text-align: center;

			.dropdown-selected-text {

				margin-left: 40px;
				width: calc(100% - 100px);
			}
		}

		ul {
			li {
				span {
					text-align: center;
				}
			}
		}
	}
}

ul.dropdown-list {

	position: fixed; left: 0px; top: 0px;
	width: 300px;
	height: auto;
	max-height: 325px;
	padding: 0;
	margin: 0;
	border-radius: 10px;
	border: $dropdown-list-border;
	display: block;
	overflow: scroll;
	transition: none;

	li {
		width: 100%;
		height: 50px;
		line-height: 50px;
		background-color: $dropdown-list-background;
		border-bottom: $dropdown-list-border;
		border-top: none;
		list-style: none;
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			background-color: $dropdown-list-background-hover;
		}

		span {
			margin-left: 10px;
			width: calc(100% - 20px);
			display: block;
		}
	}
}

$alt-dropdown-text-color: rgba(255, 255, 255, 0.7);
$alt-dropdown-text-color-hover: rgba(255, 255, 255, 1);
$alt-dropdown-border-width: 1px;
$alt-dropdown-border-color: rgba(255, 255, 255, 0.7);
$alt-dropdown-border-color-hover: rgba(255, 255, 255, 1);
$alt-dropdown-border: $alt-dropdown-border-width solid $alt-dropdown-border-color;
$alt-dropdown-background: rgba(0, 0, 0, 0.2);
$alt-dropdown-background-hover: rgba(0, 0, 0, 0.4);
$alt-dropdown-list-background: #333;
$alt-dropdown-list-background-hover: #222;
$alt-dropdown-list-border: 1px solid #fff;

.theme-alt {

	.dropdown-wrap {

		.dropdown-label {
			color: $alt-label-color;
		}

		.dropdown-selected {

			color: $alt-dropdown-text-color;
			border: $alt-dropdown-border;
			background-color: $alt-dropdown-background;

			.dropdown-arrow {

				&:before {

					background-color: $alt-dropdown-text-color;
				}

				&:after {

					background-color: $alt-dropdown-text-color;
				}
			}

			&:hover {

				background-color: $alt-dropdown-background-hover;
				color: $alt-dropdown-text-color-hover;
				border-color: $alt-dropdown-border-color-hover;

				.dropdown-arrow {

					&:before {
						background-color: $alt-dropdown-text-color-hover;
					}

					&:after {
						background-color: $alt-dropdown-text-color-hover;
					}
				}
			}
		}

		&.lite {

			.dropdown-selected {

				background-color: transparent;
				border: none;
				border-bottom: $alt-dropdown-border-width solid $alt-dropdown-border-color;
			}
		}
	}

	ul.dropdown-list {

		border: $alt-dropdown-list-border;

		li {

			background-color: $alt-dropdown-list-background;
			border-bottom: $alt-dropdown-list-border;
			color: $alt-dropdown-text-color;

			&:hover {
				background-color: $alt-dropdown-list-background-hover;
			}
		}
	}
}

</style>
