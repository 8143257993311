<template>

<div class="input-wrap" :class="classNames">

	<div
		class="input-box"
	>

		<div
			v-if="placeholder"
			class="input-placeholder"
		>
			{{ placeholder }}
		</div>
		
		<input
			:value="val"
      		@input="onInput"
			:type="type"
			@focus="onFocus"
			@blur="onBlur"
			@keyup="onKeyUp"
			@keyDown="onKeyDown"
			@keydown.enter="$emit('submit')"
		>

		<div
			v-if="icon && icon.length > 0"
			:class="'input-icon ' + icon"
		>
		</div>

		<div
			v-if="button && button.length > 0"
			:class="'input-button ' + button"
			@click="clickButton"
		>
		</div>

	</div>

</div>

</template>

<script>

var _ = require('lodash')

export default {

	name: 'VueInput',

	props: [
		'value',
		'placeholder',
		'classes',
		'type',
		'icon',
		'button',
		'required',
		'requirement',
		'focus',
		'blur',
		'keyup',
		'keydown'
	],

	data() {
		return {
			val: '',
			valid: true,
			validation: this.requirement ? this.requirement : []
		};
	},

	computed: {

		isEmpty() {

			return !this.val || this.val.toString().trim().length == 0 ? true : false
		},

		classNames() {
			return (
				this.classes +
				(this.isEmpty ? ' empty' : '') +
				(this.icon ? ' icon' : '') +
				(this.valid ? '' : ' warning') +
				(this.placeholder ? '' : ' no-placeholder')
			);
		}
	},

	watch: {

		value(newVal, oldVal) {

			this.val = newVal
		}
	},

	methods: {

		onFocus(e) {

			this.$emit('focus', e)
		},

		onBlur(e) {

			this.$emit('blur', e)

			if (this.blur)
				this.blur()

			this.validate()
		},

		onKeyUp(e) {

			this.$emit('keyup', e)
		},

		onKeyDown(e) {

			this.$emit('keydown', e)
		},

		onInput(e) {

			this.val = e.target.value

			this.validate()

			this.$emit('input', e.target.value)
		},

		clickButton() {

			this.$emit('submit')
		},

		validate() {

			if (this.validation.length == 0) {

				this.valid = true

				return true
			}

			for (let i in this.validation) {

				let f = this.validation[i]

				if (!f(this.val)) {

					this.valid = false

					return false
				}
			}

			this.valid = true

			return true
		}
	},

	mounted() {

		if (this.value)
			this.val = this.value

		if (this.required)
			this.validation.push( (x) => x && x.trim().length > 0 )
	}
};

</script>

<style lang="scss" scoped>

$input-border-width: 1px;
$input-bg-color: rgba(0, 0, 0, 0.07);

.input-wrap {

	position: relative;
	width: 100%;
	height: 40px;
	overflow: hidden;

	.input-box {

		position: relative;
		width: 100%;
		height: 40px;
		background-color: $input-bg-color;
		border-radius: 10px;

		.input-placeholder {

			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: left;
			font-size: $placeholder-font-size;
			color: $placeholder-color;
			opacity: 0;
			transition: opacity 200ms linear;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 10px;
			display: block;
		}

		input {

			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: left;
			font-size: 20px;
			outline: none;
			border: none;
			background: transparent;
			color: $text-color;
			padding: 0 10px;
		}

		.input-icon {

			position: absolute;
			left: 0; top: 0;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 25px;
			color: $text-color;

			ion-icon {

				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.input-button {

			position: absolute;
			right: 0; top: 0;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 25px;
			color: $text-color;
			cursor: pointer;

			&:before {
				line-height: 40px;
			}
		}
	}

	&.no-placeholder {
		.input-box {
			margin-top: 0;
		}
	}

	&.empty {

		.input-placeholder {
			opacity: 1;
		}

		.input-box {

		}
	}

	&.icon {

		.input-placeholder {
			margin-left: 40px;
			width: calc(100% - 70px);
		}

		input {
			left: 40px;
			width: calc(100% - 70px);
		}

		&.center {

			.input-placeholder {

				width: calc(100% - 70px);
			}

			input {

				width: calc(100% - 80px);
			}
		}
	}

	&.center {

		.input-placeholder {
			text-align: center;
		}

		input {
			text-align: center;
		}
	}

	&.warning {

		.input-placeholder {

			color: $danger-color;
		}

		.input-box {

			background-color: lighten($danger-color, 50%);

			input {

				color: $danger-color;
			}

			.input-icon {

				color: $danger-color;
			}
		}
	}

	$total: 40;
	$n: 5;
	$unit: 5px;

	@for $i from 0 through $total {

		&.height-#{$i*$n} {

			height: $i * $unit !important;

			.input-box {

				height: $i * $unit !important;

				.input-placeholder {

					height: $i * $unit !important;
					line-height: $i * $unit !important;
				}

				input {
					height: $i * $unit !important;
					line-height: $i * $unit !important;
				}

				.input-icon {
					height: $i * $unit !important;
					line-height: $i * $unit !important;
				}

				.input-button {
					height: $i * $unit !important;
					line-height: $i * $unit !important;

					&:before {
						line-height: $i * $unit !important;
					}
				}
			}
		}
	}
}

$alt-input-border-width: 1px;
$alt-input-bg-color: rgba(255, 255, 255, 0.1);

.theme-alt {

	.input-wrap {

		.input-box {

			background-color: $alt-input-bg-color;

			.input-placeholder {

				color: $alt-placeholder-color;
			}

			.input-icon {

				color: $alt-text-color;
			}

			input {

				color: $alt-text-color;
			}

			.input-button {

				color: $alt-text-color;
			}
		}

		&.warning {

			.input-box {

				background-color: lighten($alt-danger-color, 50%);

				.input-placeholder {

					color: $alt-danger-color;
				}

				input {

					color: $alt-danger-color;
				}

				.input-icon {

					color: $alt-danger-color;
				}
			}
		}
	}
}

</style>
