<template>

	<div id="styleguide" :class="theme">

		<div id="menu-button-box"></div>

		<vue-bar-layout
			breakpoint="768"
			@select="onMenuSelect"
			:menu="[
				{
					text: 'Alert',
					/* icon: 'ion-android-checkbox-outline-blank', */
					hash: 'alert'
				},
				{
					text: 'Box Layout',
					/* icon: 'ion-android-checkbox-outline-blank', */
					hash: 'box-layout'
				},
				{
					text: 'Button',
					/* icon: 'ion-navicon', */
					hash: 'button'
				},
				{
					text: 'Checkbox',
					/* icon: 'ion-ios-checkmark-outline', */
					hash: 'checkbox'
				},
				{
					text: 'Color Picker',
					/* icon: 'ion-ios-color-filter-outline', */
					hash: 'color-picker'
				},
				{
					text: 'Datepicker',
					/* icon: 'ion-android-arrow-dropdown', */
					hash: 'datepicker'
				},
				{
					text: 'Dropdown',
					/* icon: 'ion-android-arrow-dropdown', */
					hash: 'dropdown'
				},
				{
					text: 'Input',
					/* icon: 'ion-ios-compose-outline', */
					hash: 'input'
				},
				{
					text: 'List',
					/* icon: 'ion-ios-list-outline', */
					hash: 'list'
				},
				{
					text: 'Panel',
					/* icon: 'ion-android-phone-portrait', */
					hash: 'panel'
				},
				{
					text: 'Slider',
					/* icon: 'ion-ios-settings', */
					hash: 'slider'
				},
				{
					text: 'Tab',
					/* icon: 'ion-ios-browsers-outline', */
					hash: 'tab'
				},
				{
					text: 'Tag',
					/* icon: 'ion-ios-pricetag-outline', */
					hash: 'tag'
				},
				{
					text: 'Tag Search',
					/* icon: 'ion-ios-pricetag-outline', */
					hash: 'tag-search'
				},
				{
					text: 'Textarea',
					/* icon: 'ion-ios-compose-outline', */
					hash: 'textarea'
				},
				{
					text: 'Toggle',
					/* icon: 'ion-toggle', */
					hash: 'toggle'
				},
				{
					text: 'Tooltip',
					/* icon: 'ion-ios-information-outline', */
					hash: 'tooltip'
				},
				{
					text: 'Wizard Stepper',
					/* icon: 'ion-ios-play-outline', */
					hash: 'wizard-stepper'
				}
			]"
		>

			<div class="box wide center main">

				<label class="font-50 width-pct-100 mt-30 mb-30">Style Guide</label>

				<div id="theme-toggle" class="box">

					<vue-toggle
						size="30"
						@switch-on="switchThemeAlternative"
						@switch-off="switchThemeDefault"
					/>

				</div>

				<div id="alert" class="box style wide center component">

					<label class="component-title">Alert</label>

					<div class="box style wide center max-width-400">

						<div class="box type">
							<vue-button classes="round" @click="showAlert">Show Alert</vue-button>
							<vue-alert :show="alert" title="Caution!" message="You are online." @ok="hideAlert" />
						</div>

					</div>

				</div>

				<div id="box-layout" class="box style wide center component">

					<label class="component-title">Box Layout</label>

					<div class="box style wide evenly">

						<div class="box height-100"></div>
						<div class="box height-100 ml-20 mr-20"></div>
						<div class="box height-100"></div>

					</div>

					<div class="box style wide">

						<div class="box width-100 height-100"></div>
						<div class="box fill height-100 ml-20 mr-20"></div>

					</div>

					<div class="box style wide">

						<div class="box width-100 height-100"></div>
						<div class="box fill height-100 ml-20 mr-20"></div>
						<div class="box width-100 height-100"></div>

					</div>

					<div class="box style wide evenly">

						<div class="box height-300 vertical evenly padding-20">

							<div class="box tall"></div>
							<div class="box tall mt-10 mb-10"></div>
							<div class="box tall"></div>

						</div>

						<div class="box height-300 vertical padding-20 ml-20 mr-20">

							<div class="box tall height-50"></div>
							<div class="box tall fill mt-10"></div>

						</div>

						<div class="box height-300 vertical padding-20">

							<div class="box tall height-50"></div>
							<div class="box tall fill mt-10 mb-10"></div>
							<div class="box tall height-50"></div>

						</div>

					</div>

				</div>


				<div id="button" class="box style wide center component">

					<label class="component-title">Button</label>

					<div class="box style wide center max-width-400">

						<div class="box type">
							<vue-button classes="round">Round</vue-button>
						</div>

						<div class="box type">
							<vue-button classes="round outline">Round Outline</vue-button>
						</div>

					</div>

					<div class="box style wide center max-width-400">

						<div class="box type">
							<vue-button size="40" classes="round">Size 40</vue-button>
						</div>

						<div class="box type">
							<vue-button size="80" classes="round radius-20">Size 80 Round</vue-button>
						</div>

					</div>

					<div class="box style wide center max-width-400">

						<div class="box">
							<vue-button icon="ion-checkmark" classes="round">Confirm</vue-button>
						</div>

						<div class="box ml-10">
							<vue-button classes="outline round" icon="ion-checkmark">Confirm</vue-button>
						</div>

					</div>

					<div class="box style wide center max-width-400">

						<div class="box type">
							<vue-button classes="circle" icon="ion-ios-plus-empty" size="40"></vue-button>
						</div>

						<div class="box type">
							<vue-button classes="circle outline" icon="ion-ios-plus-empty" size="40"></vue-button>
						</div>

						<div class="box type">
							<vue-button classes="circle" icon="ion-ios-plus-empty" size="60"></vue-button>
						</div>

						<div class="box type">
							<vue-button classes="circle outline" icon="ion-ios-plus-empty" size="80"></vue-button>
						</div>

					</div>

					<div class="box style wide center max-width-400">

						<div class="box type">
							<vue-button classes="icon" icon="ion-ios-star-outline" size="40"></vue-button>
						</div>

						<div class="box type">
							<vue-button classes="icon" icon="ion-ios-star-outline" size="60"></vue-button>
						</div>

						<div class="box type">
							<vue-button classes="icon" icon="ion-ios-star-outline" size="80"></vue-button>
						</div>

					</div>

					<div class="box style wide center max-width-400">

						<div class="box">
							<vue-button classes="round danger">Delete</vue-button>
						</div>

						<div class="box ml-10">
							<vue-button classes="round danger outline">Delete</vue-button>
						</div>

					</div>

				</div>


				<div id="checkbox" class="box style wide center component">

					<label class="component-title">Checkbox</label>

					<div class="box style wide center max-width-400">

						<vue-checkbox classes="radius-6">Agree</vue-checkbox>

					</div>

					<div class="box style wide center max-width-400">

						<vue-checkbox classes="radius-6" checked>Agree</vue-checkbox>

					</div>

					<div class="box style wide center max-width-400">

						<vue-checkbox classes="radius-6" disabled>Agree</vue-checkbox>

					</div>

					<div class="box style wide center max-width-400">

						<vue-checkbox classes="button">Choice</vue-checkbox>

					</div>

					<div class="box style wide center max-width-400">

						<vue-checkbox classes="button" checked>Choice</vue-checkbox>

					</div><div class="box style wide center max-width-400">

						<vue-checkbox classes="button" disabled>Choice</vue-checkbox>

					</div>

				</div>


				<div id="color-picker" class="box style wide center component">

					<label class="component-title">Color Picker</label>

					<div class="box style wide center max-width-400">

						<vue-color color="#e02a52" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-color :random="true" />

					</div>

				</div>


				<div id="datepicker" class="box style wide center component">

					<label class="component-title">Datepicker</label>

					<div class="box style wide center max-width-400">

						<vue-datepicker
						/>

					</div>

				</div>

				<div id="dropdown" class="box style wide center component">

					<label class="component-title">Dropdown</label>

					<div class="box style wide center max-width-400">

						<vue-dropdown
							:options="[
								'Amsterdam',
								'Copenhagen',
								'Kyoto',
								'Liverpool',
								'London',
								'Edinburgh',
								'Osaka',
								'Oslo',
								'Paris'
							]"
						/>

					</div>

					<div class="box style wide center max-width-400">

						<vue-dropdown
							classes="center"
							:options="[
								'Amsterdam',
								'Copenhagen',
								'Kyoto',
								'Liverpool',
								'London',
								'Edinburgh',
								'Osaka',
								'Oslo',
								'Paris'
							]"
						/>

					</div>

					<div class="box style wide center max-width-400">

						<vue-dropdown
							classes="lite"
							label="City"
							:options="[
								'Amsterdam',
								'Copenhagen',
								'Kyoto',
								'Liverpool',
								'London',
								'Edinburgh',
								'Osaka',
								'Oslo',
								'Paris'
							]"
						/>

					</div>

				</div>


				<div id="input" class="box style wide center component">

					<label class="component-title">Input</label>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Email" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Email" classes="center" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Email (Required)" :required="true" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-input
							placeholder="Phone Number (10 digits)"
							:requirement="[
								(x) => !isNaN(x) && x.length == 10
							]"
						/>

					</div>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Email" icon="ion-ios-email-outline" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Email" classes="center" icon="ion-ios-email-outline" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Email" classes="center" button="ion-ios-search-strong" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-input placeholder="Customized size" classes="height-80" button="ion-ios-search-strong" />

					</div>

				</div>


				<div id="list" class="box style wide center component">

					<label class="component-title">List</label>

					<div class="box style wide center max-width-400">

						<vue-list :list="['Apple', 'Banana', 'Orange']" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-list
							:list="[
								{ label: 'Name', text: 'Apple' },
								{ label: 'Type', text: 'Fruit' },
								{ label: 'Color', text: 'Red' }
							]"
							:styles="{ borderColor: 'rgba(0, 0, 0, 0.2)' }"
						/>

					</div>

					<div class="box style wide center max-width-400">

						<vue-list
							:list="[
								{ label: 'Name', text: 'Apple Inc.' },
								{ label: 'Intro', text: 'Apple Inc. is an American multinational technology company headquartered in Cupertino, California, that designs, develops, and sells consumer electronics, computer software, and online services.' },
								{ label: 'Headquater', text: 'USA' }
							]"
						/>

					</div>

					<div class="box style wide center max-width-400">

						<vue-list
							overflow="visible"
							:list="[
								{ label: 'Name', text: 'Apple Inc.' },
								{ label: 'Intro', text: 'Apple Inc. is an American multinational technology company headquartered in Cupertino, California, that designs, develops, and sells consumer electronics, computer software, and online services.' },
								{ label: 'Headquater', text: 'USA' }
							]"
						/>

					</div>

				</div>


				<div id="panel" class="box style wide center component">

					<label class="component-title">Panel</label>

					<div class="box style wide center max-width-400">

						<vue-panel
							title="Pending Tasks"
							:actions="[{ name: 'Refresh', action: 'refresh' }]"
							@refresh="action"
						>
							No pending task found.
						</vue-panel>

					</div>

					<div class="box style wide center max-width-400">

						<vue-panel
							title="Details"
							:actions="[{ name: 'Edit', action: 'edit' }]"
							@edit="action"
							:options="['close']"
						>

							<vue-list
								:list="[
									{ label: 'Name', text: 'Apple' },
									{ label: 'Type', text: 'Fruit' },
									{ label: 'Color', text: 'Red' }
								]"
							/>

						</vue-panel>

					</div>

				</div>


				<div id="slider" class="box style wide center component">

					<label class="component-title">Slider</label>

					<div class="box style wide center max-width-400">

						<vue-slider></vue-slider>

					</div>

					<div class="box style wide center max-width-400">

						<vue-slider
							:showData="true"
							:min="50"
							:max="300"
							symbolBefore="$"
						></vue-slider>

					</div>

					<div class="box style wide center max-width-400">

						<vue-slider
							:showData="true"
							:min="50"
							:max="300"
							symbolBefore="$"
							:showRange="true"
						></vue-slider>

					</div>

					<div class="box style wide center max-width-400">

						<vue-slider
							:showPercentage="true"
							:min="2"
							:max="8"
						></vue-slider>

					</div>

				</div>

				<div id="tag" class="box style wide center component">

					<label class="component-title">Tag</label>

					<div class="box style wide center max-width-400">

						<vue-tag
							:readonly="true"
							:tags="['flat', 'white']"
						/>

					</div>

					<div class="box style wide center max-width-400">

						<vue-tag :tags="['flat', 'white']" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-tag :tags="['double milk', 'double sugar']" color="#000000" />

					</div>

				</div>

				<div id="tag-search" class="box style wide center component">

					<label class="component-title">Tag Search</label>

					<div class="box style wide center max-width-400">

						<vue-tag-search
							:tags="tags"
							@search="tagSearch"
							@reset="tagReset"
							@removeTag="tagRemove"
						/>

					</div>

				</div>


				<div id="textarea" class="box style wide center component">

					<label class="component-title">Textarea</label>

					<div class="box style wide center max-width-400">

						<vue-textarea placeholder="Say something..." />

					</div>

				</div>


				<div id="toggle" class="box style wide center component">

					<label class="component-title">Toggle</label>

					<div class="box style wide center max-width-400">

						<vue-toggle size="30" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-toggle size="40" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-toggle />

					</div>

				</div>


				<div id="tooltip" class="box style wide center component">

					<label class="component-title">Tooltip</label>

					<div class="box style wide center max-width-400">

						<vue-tooltip text="This is supposed to be something useful but I have nothing to say" />

					</div>

					<div class="box style wide center max-width-400">

						<vue-tooltip icon="ion-social-apple" text="Tooltip with custom icon" />

					</div>

				</div>

				<div id="tab" class="box style wide center component">

					<label class="component-title">Tab</label>

					<div class="box style wide center">

						<vue-tab :tabs="['Home', 'Dashboard', 'Settings']" />

					</div>

				</div>


				<div id="wizard-stepper" class="box style wide center component">

					<label class="component-title">Wizard Stepper</label>

					<div class="box style wide center">

						<vue-wizard-stepper
							current="2"
							:steps="[
								{ text: 'Personal Info'},
								{ text: 'Company Info'},
								{ text: 'Review'}
							]"
						/>

					</div>

				</div>

			</div>

		</vue-bar-layout>

	</div>

</template>



<script>

    export default {

        name: 'Styleguide',

        data() {
            return {
				theme: '',
				alert: false,
				tags: [
					{ name: 'Test' },
					{ name: 'Demo' },
				]
            }
        },

        methods: {

			switchThemeAlternative() {

				this.theme = 'theme-alt'
			},

			switchThemeDefault() {

				this.theme = ''
			},

			onMenuSelect(item) {

				if (item.hash) {
					$('.vue-scroll').scrollTop($(`#${ item.hash }`).offset().top - 40 + $('.vue-scroll').scrollTop())
				}
			},

			action() {
				alert('Action')
			},

			showAlert() {

				this.alert = true
			},

			hideAlert() {

				this.alert = false
			},

			tagSearch(tags) {


			},

			tagReset() {

				this.tags = []
			},

			tagRemove(tag) {

				this.tags = this.tags.filter(i => i.name.toLowerCase() != tag.name.toLowerCase())
			},
        },

        mounted() {

        }
    }

</script>



<style lang="scss" scoped>

#styleguide {

	position: fixed; left: 0; top: 0;
	width: 100%; height: 100%;
	overflow: hidden;
	background-color: #fff;

	.component-title {
		font-size: 40px;
		width: 100%;
		margin-bottom: 50px; margin-top: 50px;
	}
}

.box.type {
	margin: 5px;
}

.box.style {

	margin: 20px;
	min-height: 160px;
	background-color: rgba(20, 96, 135, 0.1);
	padding: 30px;
	border-radius: 30px;

	&.main {
		padding-bottom: 300px;
	}

	&.component {

		padding-top: 0px;
		padding-bottom: 80px;
	}
}


#box-layout {

	.box.style {

		margin: 5px;
		min-height: 0px;
		padding: 20px;
		border-radius: 20px;
		background-color: #cee8f5;

		.box {
			border-radius: 20px;
			background-color: rgba(21, 122, 173, 0.2);
		}
	}
}

.theme-alt {

	background-color: #555;

	div:not(.box.type):not(.box.component) {
		background-color: #555;
	}

	label {

		color: white;
	}

	.box.style {

		color: $alt-text-color;
		background-color: rgba(255, 255, 255, 0.1);

		label {

			color: white;
		}
	}

	#box-layout {

		.box.style {

			background-color: rgba(34, 168, 212, 0.2);

			.box {
				background-color: rgba(34, 168, 212, 0.3);
			}
		}
	}
}


#theme-toggle {

	position: absolute; left: 20px; top: 40px;
}

</style>
