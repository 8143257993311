<template>

	<div class="datepicker" v-click-outside="hide">

		<vue-input :value="displayDate" classes="center" @focus="showDatepicker" />

		<portal to="top-layer">

			<div
				v-if="show"
				class="datepicker-popup box wrap"
				:style="{ left: position.left, top: position.top }"
			>

				<div class="datepicker-header box wide v-mid mb-20">
					<div class="box center month-arrow ion-chevron-left" @click="prevWeek" v-show="!yearView"></div>
					<div
						class="box month-year center height-20"
						:class="{ wide: yearView }"
						@click="toggleDisplay"
					>
							{{ monthNameShort[view.month - 1] }} {{ view.year }}&nbsp;<span class="ml-5" :class="{ 'ion-chevron-up': yearView, 'ion-chevron-down': !yearView }"></span>
					</div>
					<div class="box center month-arrow ion-chevron-right" @click="nextWeek" v-show="!yearView"></div>
				</div>

				<div v-show="!yearView" class="box wide">

					<div class="datepicker-header box wide">
						<div class="datepicker-weekday-header box center">S</div>
						<div class="datepicker-weekday-header box center">M</div>
						<div class="datepicker-weekday-header box center">T</div>
						<div class="datepicker-weekday-header box center">W</div>
						<div class="datepicker-weekday-header box center">T</div>
						<div class="datepicker-weekday-header box center">F</div>
						<div class="datepicker-weekday-header box center">S</div>
					</div>

					<div class="datepicker-main box wide">

						<div
							v-for="monthDay in paddedMonthDays"
							class="box center datepicker-month-day"
							:class="{
								'view-month': monthDay.year == view.year && monthDay.month == view.month,
								'current-day': monthDay.year == year && monthDay.month == month && monthDay.day == day
							}"
							@click="selectDate(monthDay)"
						>
							{{ monthDay.year == view.year && monthDay.month == view.month ? monthDay.day : '' }}
						</div>

					</div>

				</div>

				<div v-show="yearView" class="box wide year-month-select">

					<div class="year-select box" @scroll="onYearScroll" ref="years">
						<div
							v-for="y in years"
							:id="`year-${ y }`"
							:ref="y == yearPick ? 'yearSelected' : ''"
							class="year box wide"
							:class="{
								selected: y == yearPick,
								top: y == yearPick && yearScrollPastSelection,
								bottom: y == yearPick && !yearScrollPastSelection
							}"
							@click="pickYear(y)"
						>{{ y }}</div>
					</div>

					<div class="month-select box fill wrap">
						<div
							v-for="(month, index) in monthNameShort"
							class="month box center"
							:class="monthClass[index]"
							@click="pickMonth(index + 1)"
						>
							{{ month }}
						</div>
					</div>

				</div>

			</div>

		</portal>

	</div>

</template>

<script>

export default {

	name: "VueDatepicker",

	props: ['value'],

	data() {

		return {
			show: false,
			position: { left: 0, top: 0 },
			monthName: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			monthNameShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			date: new Date(),
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			day: new Date().getDate(),
			view: {
				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
				day: new Date().getDate()
			},
			yearView: false,
			years: [],
			yearPick: new Date().getFullYear(),
			yearScrollPastSelection: 0,
			monthClass: []
		}
	},

	computed: {

		displayDate() {

			let month = this.month < 10 ? `0${ this.month }` : this.month
			let day = this.day < 10 ? `0${ this.day }` : this.day

			return `${ this.year }-${ month }-${ day }`
		},

		monthDays() {

			let days = []

			let totalMonthDays = new Date(this.view.year, this.view.month, 0).getDate()

			for (let i = 1; i <= totalMonthDays; i++) {

				let day = {
					year	: this.view.year,
					month	: this.view.month,
					day		: i,
					weekDay	: new Date(this.view.year, this.view.month, i).getDay()
				}

				days.push(day)
			}

			return days
		},

		paddedMonthDays() {

			let lastMonthYear = this.view.year
			let lastMonth = this.view.month - 1 < 1 ? 12 : this.view.month - 1

			if (lastMonth == 12)
				lastMonthYear--

			let totalLastMonthDays = new Date(lastMonthYear, lastMonth, 0).getDate()

			let paddedBefore = []

			for (let i = this.monthDays[0].weekDay; i > 0; i--) {

				let day = {
					year	: lastMonthYear,
					month	: lastMonth,
					day		: totalLastMonthDays,
					weekDay	: i - 1
				}

				totalLastMonthDays--

				paddedBefore.unshift(day)
			}

			let nextMonthYear = this.view.year
			let nextMonth = this.view.month + 1 > 12 ? 1 : this.view.month + 1

			if (nextMonth == 1)
				nextMonthYear++

			let paddedAfter = []
			let nextMonthDay = 1

			for (let i = this.monthDays[this.monthDays.length - 1].weekDay; i < 6; i++) {

				let day = {
					year	: nextMonthYear,
					month	: nextMonth,
					day		: nextMonthDay++,
					weekDay	: i + 1
				}

				paddedAfter.push(day)
			}

			return paddedBefore.concat(this.monthDays).concat(paddedAfter)
		}
	},

	methods: {

		nextWeek() {

			let month = ++this.view.month

			if (month > 12) {
				month = 1
				this.view.year++
			}

			this.view.month = month
		},

		prevWeek() {

			let month = --this.view.month

			if (month < 1) {
				month = 12
				this.view.year--
			}

			this.view.month = month
		},

		showDatepicker(e) {

			let rect = e.currentTarget.getBoundingClientRect()

			this.position.left = rect.left
			this.position.top = rect.top + rect.height + 5

			if (this.position.top + 365 > window.innerHeight)
				this.position.top = window.innerHeight - 365

			if (this.position.left + 330 > window.innerWidth)
				this.position.left = window.innerWidth - 330

			this.position.left += 'px'
			this.position.top += 'px'

			this.show = true

			this.view = {
				year: this.year,
				month: this.month,
				day: this.day
			}

			this.yearView = false
		},

		hide(e) {

			if ($(e.target).closest('.datepicker-popup').length > 0)
				return

			if (this.show)
				this.show = false

			this.view = {
				year: this.year,
				month: this.month,
				day: this.day
			}
		},

		selectDate(day) {

			this.show = false

			this.year = day.year
			this.month = day.month
			this.day = day.day
		},

		toggleDisplay() {

			this.yearView = !this.yearView

			if (!this.yearView)
				return

			this.yearPick = this.view.year

			this.yearScrollTop = null

			setTimeout(() => document.getElementById(`year-${ this.year - 2 }`).scrollIntoView())
		},

		pickYear(y) {

			if (this.yearPick == y)
				return

			this.yearPick = y

			for (let i = 0; i < 12; i++)
				this.$set(this.monthClass, i, 'hidden')

			let i = 0

			let animation = setInterval(() => {
				this.$set(this.monthClass, i, '')
				i++
			}, 50)

			setTimeout(() => clearInterval(animation), 600)
		},

		pickMonth(m) {

			this.view.year = this.yearPick
			this.view.month = m

			this.yearView = false
		},

		onYearScroll(e) {

			if (this.$refs.yearSelected[0].getBoundingClientRect().top - this.$refs.years.getBoundingClientRect().top == 0)
				return

			if (this.$refs.years.scrollTop > this.$refs.yearSelected[0].getBoundingClientRect().top - this.$refs.years.getBoundingClientRect().top + this.$refs.years.scrollTop)
				this.yearScrollPastSelection = 1
			else
				this.yearScrollPastSelection = 0
		}
	},

	mounted() {

		if (this.value) {

			this.date = new Date(this.value)

			this.year = this.date.getFullYear()
			this.month = this.date.getMonth() + 1
			this.day = this.date.getDate()
		}

		for (let i = 1900; i < this.year + 100; i++)
			this.years.push(i)
	},

	watch: {

		value(newVal, oldVal) {

			if (newVal) {

				this.date = new Date(newVal)

				this.year = this.date.getFullYear()
				this.month = this.date.getMonth() + 1
				this.day = this.date.getDate()
				this.date = new Date(this.year, this.month - 1, this.day)
			}
		}
	}
}

</script>

<style lang="scss" scoped>

$selectedColorBG: $theme-color;
$selectedColorFG: #fff;

.datepicker {
	width: 300px; height: 40px;
}

.datepicker-popup {

	position: fixed;
	width: 320px;
	padding: 20px;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

	.datepicker-weekday-header {

		width: 40px; height: 40px;
		font-weight: 600; color: #888;
	}

	.datepicker-month-day {

		width: 40px; height: 40px;
		background-color: white;
		color: black;
		border-radius: 8px;

		&.view-month {

			background-color: white;
			color: black;
			cursor: pointer;

			&:hover {
				background-color: $theme-color;
				color: white;
			}

			&.current-day {
				background-color: $selectedColorBG;
				color: $selectedColorFG;
			}
		}
	}

	.month-year {
		width: calc(100% - 60px);
		cursor: pointer;
	}

	.month-arrow {
		width: 30px; height: 20px;
		cursor: pointer;
	}

	.year-month-select {

		height: 200px;

		.year-select {

			overflow: auto;
			width: 75px; height: 100%;
			margin-right: 5px;

			.year {
				height: 30px;
				line-height: 30px;
				padding-left: 15px;
				border-radius: 5px;
				cursor: pointer;
				z-index: 1;

				&.selected {
					background-color: $selectedColorBG;
					color: $selectedColorFG;
					position: sticky;
					z-index: 2;

					&.top {
						top: 0;
					}

					&.bottom {
						bottom: 0;
					}
				}

				&:hover {
					background-color: $theme-color;
					color: white;
				}
			}
		}

		.month-select {

			.month {

				margin: 1px;
				width: 64px; height: 50px;
				line-height: 50px; text-align: center;
				border-radius: 8px;
				background-color: #f7f7f7;
				cursor: pointer;

				&.hidden {
					opacity: 0;
				}

				&.selected {
					background-color: $selectedColorBG;
					color: $selectedColorFG;
				}

				&:hover {
					background-color: $theme-color;
					color: white;
				}
			}
		}
	}

}

</style>
