<template>

	<div class="screen thoughts" >

		<div id="animation-thoughts" class="animation">

			<div id="thoughts-head" class="flip head" :style="headStyle">

				<div class="face">

					<div class="front">

						<transition name="quote">
							<div id="quote-1" class="quote" :style="quoteStyle" v-if="randomQuote1">
								{{ randomQuote1 }}
							</div>
						</transition>

						<transition name="quote">
							<div id="quote-2" class="quote" :style="quoteStyle" v-if="randomQuote2">
								{{ randomQuote2 }}
							</div>
						</transition>

						<div class="shsf-head" :style="{ backgroundImage: `url(${ headImg })`}"></div>

						<div class="shsf-gears head-stuff" :style="gearsStyle">
							<!-- <div class="shsf-gear-1 shsf-gear ion-gear-a"></div>
							<div class="shsf-gear-2 shsf-gear ion-ios-gear"></div>
							<div class="shsf-gear-3 shsf-gear ion-ios-cog"></div> -->
							<div class="shsf-gear-1 shsf-gear" :style="{ backgroundImage: `url(${ gear1Img })`, ...gearStyle }"></div>
							<div class="shsf-gear-2 shsf-gear" :style="{ backgroundImage: `url(${ gear2Img })`, ...gearStyle }"></div>
							<div class="shsf-gear-3 shsf-gear" :style="{ backgroundImage: `url(${ gear3Img })`, ...gearStyle }"></div>
						</div>

						<div class="shsf-head-patch" :style="{ backgroundImage: `url(${ headPatchImg })`}"></div>

						<div class="shsf-eye head-stuff" :style="eyeStyle">
							<div class="shsf-eye-iris" :style="irisStyle"></div>
						</div>

					</div>

					<div class="back"></div>

				</div>

			</div>

			<div id="thoughts-toggle" class="toggle-switch head" :style="toggleStyle" @mouseup="toggle">

				<div class="mid">

					<label class="rocker">
						<input id="thoughts-toggle-checkbox" type="checkbox">
						<span class="switch-left">On</span>
						<span class="switch-right">Off</span>
					</label>

				</div>

			</div>

			<div id="shsf" class="flip text" :style="shsfStyle">

				<div class="face">

					<div class="front">

						<div class="text-rotate-1">
							Stay hungry
						</div>

						<div class="text-rotate-2">
							Stay foolish
						</div>

					</div>

					<div class="back"></div>

				</div>

			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Screen',

	props: ['config'],

	data() {

		return {
			headImg: require('@/assets/img/head.svg'),
			headPatchImg: require('@/assets/img/head-patch.svg'),
			gear1Img: require('@/assets/img/gear-1.svg'),
			gear2Img: require('@/assets/img/gear-2.svg'),
			gear3Img: require('@/assets/img/gear-3.svg'),
			headStyle: {},
			quoteStyle: {},
			gearsStyle: {},
			gearStyle: {},
			eyeStyle: {},
			irisStyle: {},
			toggleStyle: {},
			toggleStatus: false,
			animateHead: false,
			prepareQuote: 0,
			randomQuoteIndex: 0,
			randomQuote1: '',
			randomQuote2: '',
			quotes: [
				'Remember that not getting what you want is sometimes a wonderful stroke of luck.',
				'I wish there is way to know you are in the good old times before you actually left it.',
				'Life is 10% what happens to you and 90% how you react to it.',
				'It does not matter how slowly you go as long as you do not stop.',
				'You never realize what you have until it\'s gone. Toilet paper is a good example.',
				'Problems are not stop signs, they are guidelines.',
				'Dear Math, please grow up and solve your own problems, I\'m tired of solving them for you.',
				'Life is about making an impact, not making an income.',
				'When a man opens a car door for his wife, it\'s either a new car or a new wife.',
				'Life isn\'t about getting and having, it\'s about giving and being.',
				'Strive not to be a success, but rather to be of value.',
				'You miss 100% of the shots you don’t take.',
				'When nothing goes right, go left.',
				'Remember that not getting what you want is sometimes a wonderful stroke of luck.',
				'Every time I thought I was being rejected from something good, I was actually being redirected to something better.',
				'I\'ve failed over and over and over again in my life. And that is why I succeed.',
				'To steal ideas from one person is plagiarism; to steal from many is research.',
				'The most difficult thing is the decision to act, the rest is merely tenacity.',
				'Life is what happens to you while you’re busy making other plans.',
				'Bugs come from open Windows.',
				'Your value doesn’t decrease based on someone’s inability to see your worth.',
				'You often feel tired, not because you’ve done too much, but because you’ve done too little of what sparks a light in you.',
				'Every child is an artist. The problem is how to remain an artist once he grows up.',
				'People will forget what you said, people will forget what you did, but people will never forget how you made them feel.',
				'Either you run the day, or the day runs you.',
				'Whether you think you can or you think you can’t, you’re right.',
				'The only real mistake is the one from which we learn nothing.',
				'Kindness is more important than wisdom, and the recognition of this is the beginning of wisdom.',
				'Oxygen could be toxic and it takes about 80 years on average to kill a person.',
				'The two most important days in your life are the day you are born and the day you find out why.',
				'People often say that motivation doesn’t last. Well, neither does bathing.  That’s why we recommend it daily.',
				'There is only one way to avoid criticism: do nothing, say nothing, and be nothing.',
				'After Monday and Tuesday, even the calendar says WTF.',
				'I wish there is way to know you are in the good old times before you actually left it.',
				'The way you tell your story to yourself matters.',
				'Life is not measured by the number of breaths we take, but by the moments that take our breath away.',
				'A person who never made a mistake never tried anything new.',
				'The person who says it cannot be done should not interrupt the person who is doing it.',
				'Build your own dreams, or someone else will hire you to build theirs. ',
				'Never argue with an idiot. They will drag you down to their level and beat you with experience.',
				'If you think you are too small to make a difference, try sleeping with a mosquito.'
			],
			shsfStyle: {}
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				// this.quoteStyle = { opacity: 0 }
				this.gearsStyle = { opacity: 0 }
				this.eyeStyle = { opacity: 0 }

				let winh = $(window).height()
				let top = winh / 4 * 3

				this.headStyle = {

					transform: 'translateX(-50%) translateY(' + (-top) + 'px) scale(0.1)'
				}

				setTimeout(() => {

					// this.quoteStyle = { opacity: 1 }
					this.gearsStyle = { opacity: 1 }
					this.eyeStyle = { opacity: 1 }

					this.toggleStyle = {
						top: '100%'
					}

					setTimeout(() => {

						let winh = $(window).height()
						let top = winh / 4 * 3

						this.toggleStyle = {
							top			: top + 'px',
							transform	: 'scale(0.6) translateX(-80%)'
						}
					})

				}, 2000)

			} else if (step == 2) {

				this.stopHead()

				this.quoteStyle = { opacity: 0 }
				this.gearsStyle = { opacity: 0 }
				this.eyeStyle = { opacity: 0 }

				setTimeout(() => {

					let winh = $(window).height()

					this.headStyle = {

						transform: 'translateX(-50%) translateY(' + (-winh-200) + 'px) scale(0.01)'
					}

					this.toggleStyle = {

						transform	: 'scale(0.01) translateX(-125%)',
						top			: '-50px'
					}

					setTimeout(() => {

						this.headStyle = {
							...this.headStyle,
							opacity: 0
						}

						this.toggleStyle = {
							...this.toggleStyle,
							opacity: 0
						}

						let winh = $(window).height()
						let top = winh / 2

						this.shsfStyle = {
							opacity		: 1,
							transform	: 'translateX(-50%) scale(0.3)',
							top			: top + 'px'
						}

					}, 2000)

				}, 1000)

			} else if (step == 3) {

				this.shsfStyle = {
					opacity		: 0,
					transform	: 'translateX(-50%) scale(0.01)',
					top			: '0px'
				}
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.stopHead()

				this.quoteStyle = { opacity: 0 }
				this.gearsStyle = { opacity: 0 }
				this.eyeStyle = { opacity: 0 }

				this.toggleStyle = {
					top			: '100%',
					transform	: 'scale(5) translateX(-125%)'
				}

				setTimeout(() => {

					this.headStyle = {

						transform: 'translateX(-50%) translateY(0px) scale(1)'
					}

				}, 2000)

			} else if (step == 2) {

				this.shsfStyle = {
					opacity		: 1,
					transform	: 'translateX(-50%) scale(1)',
					top			: '100%'
				}

				let winh = $(window).height()

				this.headStyle = {
					...this.headStyle,
					opacity: 0,
					transform: 'translateX(-50%) translateY(' + (-winh-200) + 'px) scale(0.01)',
					transition: 'all 0s linear'
				}

				this.toggleStyle = {
					...this.toggleStyle,
					opacity: 0,
					transform	: 'scale(0.01) translateX(-125%)',
					top			: '-50px',
					transition: 'all 0s linear'
				}

				// this.quoteStyle = { opacity: 0 }
				this.gearsStyle = { opacity: 0 }
				this.eyeStyle = { opacity: 0 }

				setTimeout(() => {

					let winh = $(window).height()
					let top = winh / 4 * 3

					this.toggleStyle = {
						top			: top + 'px',
						transform	: 'scale(0.6) translateX(-80%)',
						transition: 'all 2s linear'
					}
				})

				setTimeout(() => {

					let winh = $(window).height()
					let top = winh / 4 * 3

					this.headStyle = {

						transform: 'translateX(-50%) translateY(' + (-top) + 'px) scale(0.1)',
						transition: 'all 2s linear'
					}

					// this.quoteStyle = { opacity: 1 }
					this.gearsStyle = { opacity: 1 }
					this.eyeStyle = { opacity: 1 }

				}, 2000)

			} else if (step == 3) {

				this.shsfStyle = {
					opacity		: 0,
					transform	: 'translateX(-50%) scale(0.01)',
					top			: '0px'
				}

				setTimeout(() => {

					let winh = $(window).height()
					let top = winh / 2

					this.shsfStyle = {
						opacity		: 1,
						transform	: 'translateX(-50%) scale(0.3)',
						top			: top + 'px'
					}
				})

			}
		},

		toggle() {

			this.toggleStatus = !this.toggleStatus

			if (this.toggleStatus)
				this.startHead()
			else
				this.stopHead()
		},

		startHead() {

			this.animateHead = true

			this.gearStyle = {
				animationPlayState: 'running'
			}

			this.irisStyle = {
				animationPlayState: 'running'
			}

			this.getQuote()
		},

		stopHead() {

			clearTimeout(this.prepareQuote)

			this.animateHead = false

			this.gearStyle = {
				animationPlayState: 'paused'
			}

			this.irisStyle = {
				animationPlayState: 'paused'
			}
		},

		getQuote() {

			let q = Math.floor(Math.random() * this.quotes.length)

			if (q == this.randomQuoteIndex) {
				this.getQuote()
				return
			}

			this.randomQuoteIndex = q

			if (this.randomQuote1) {

				this.randomQuote1 = ''

				this.randomQuote2 = this.quotes[this.randomQuoteIndex]

			} else {

				this.randomQuote2 = ''

				this.randomQuote1 = this.quotes[this.randomQuoteIndex]
			}


			this.prepareQuote = setTimeout(() => this.getQuote(), 3000)
		}
	}
}

</script>




<style lang="scss" scoped>

#animation-thoughts {

	position: absolute; left: 0; top: 0%;
	width: 100%; height: 100%;
	background-color: #708e9e;
	transition: none;

	.flip.head {

		position: absolute; top: 100%; left: 50%;
		width: 3200px; height: 2200px;
		transform: translateX(-50%);
		transform-origin: center top;
		transition: all 2s ease-out;
		z-index: 2;

		.face {

			height: 2200px;

			.front {

				width: 3200px; height: 3200px;
				transform:rotateX(-10deg);
				background-color: rgba(255, 255, 255, 0);
				padding-top: 150px;


				.head-stuff {
					opacity: 0;
					transition: opacity 1s linear;
				}

				.quote {
					position: absolute; left: 0; bottom: 100%;
					width: 100%; height: auto;
					font-size: 150px; color: white;
					line-height: 160px;
					text-align: center;
				}

				.quote-enter-active {
					transition: all 1s ease;
				}
				.quote-leave-active {
					transition: all 1s ease;
				}
				.quote-enter, .quote-leave-to {
					transform: translateY(300px);
					opacity: 0;
				}

				.shsf-head {
					position: absolute; left: 0; top: 1200px;
					width: 100%; height: 2000px;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
				}

				.shsf-head-patch {
					position: absolute; left: 0; top: 1200px;
					width: 100%; height: 2000px;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
				}

				.shsf-eye {

					position: absolute; left: 1000px; top: 1500px;
					width: 300px; height: 300px;
					border-radius: 150px;
					background-color: #fff;

					.shsf-eye-iris {
						position: absolute; left: 0px; top: 0px;
						width: 150px; height: 150px;
						border-radius: 100px;
						background-color: #000;
						transform-origin: top left;
						animation: moveEye 1s infinite;
						animation-play-state: paused;
					}
				}

				.shsf-gears {
					position: absolute; left: 0; top: 0;
					width: 100%; height: 1200px;

					.shsf-gear {
						position: absolute;
						color: aqua;
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
					}

					.shsf-gear-1 {
						left: 1600px; top: 1000px;
						width: 500px; height: 500px;
						animation: moveGear 2s infinite;
						animation-play-state: paused;
					}

					.shsf-gear-2 {
						left: 1050px; top: 640px;
						width: 600px; height: 600px;
						animation: moveGearReverse 2s infinite;
						animation-play-state: paused;
					}

					.shsf-gear-3 {
						left: 1600px; top: 200px;
						width: 700px; height: 700px;
						animation: moveGear 2s infinite;
						animation-play-state: paused;
					}
				}
			}
		}
	}

	#thoughts-toggle {

		position: absolute; left: 50%; top: 100%;
		transform: translateX(-125%) scale(5);
		transform-origin: center top;
		transition: all 2s linear;
		z-index: 1;
	}

	#shsf {

		position: absolute; left: 50%; top: 100%;
		width: 3200px; height: 3200px;
		opacity: 0;
		transform: translateX(-50%);
		transform-origin: center top;
		transition: transform 2s ease-out, top 1s ease-out, opacity 1s ease-out;

		.face {

			height: 3200px;

			.front {

				width: 3200px; height: 1000px;
				text-align: center;
				background-color: rgba(255, 255, 255, 0);
				padding-top: 150px;

				.text-rotate-1 {

					width: 100%;
					font-size: 200px; color: white; line-height: 250px;
					padding-right: 400px;
					transform: rotateX(30deg) rotateY(-30deg);
				}

				.text-rotate-2 {

					width: 100%;
					font-size: 200px; color: white; line-height: 250px;
					padding-left: 400px;
					transform: rotateX(30deg) rotateY(30deg);
				}
			}
		}
	}
}





@keyframes moveGear {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes moveGearReverse {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@keyframes moveEye {
	0% {
		transform: translate(0px, 75px);
	}
	12% {
		transform: translate(26px, 26px);
	}
	25% {
		transform: translate(75px, 0px);
	}
	37% {
		transform: translate(124px, 26px);
	}
	50% {
		transform: translate(150px, 75px);
	}
	62% {
		transform: translate(124px, 124px);
	}
	75% {
		transform: translate(75px, 150px);
	}
	87% {
		transform: translate(26px, 124px);
	}
	100% {
		transform: translate(0px, 75px);
	}
}





.mid {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top:1em;
}


/* Switch starts here */
.rocker {
  display: inline-block;
  position: relative;
  /*
  SIZE OF SWITCH
  ==============
  All sizes are in em - therefore
  changing the font-size here
  will change the size of the switch.
  See .rocker-small below as example.
  */
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #888;
  width: 7em;
  height: 4em;
  overflow: hidden;
  border-bottom: 0.5em solid #eee;
}

.rocker-small {
  font-size: 0.75em; /* Sizes the switch */
  margin: 1em;
}

.rocker::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999;
  border: 0.5em solid #eee;
  border-bottom: 0;
}

.rocker input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-left,
.switch-right {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 3em;
  transition: 0.2s;
}

.switch-left {
  height: 2.4em;
  width: 2.75em;
  left: 0.85em;
  bottom: 0.4em;
  background-color: #ddd;
  transform: rotate(15deg) skewX(15deg);
}

.switch-right {
  right: 0.5em;
  bottom: 0;
  background-color: #bd5757;
  color: #fff;
}

.switch-left::before,
.switch-right::before {
  content: "";
  position: absolute;
  width: 0.4em;
  height: 2.45em;
  bottom: -0.45em;
  background-color: #ccc;
  transform: skewY(-65deg);
}

.switch-left::before {
  left: -0.4em;
}

.switch-right::before {
  right: -0.375em;
  background-color: transparent;
  transform: skewY(65deg);
}

input:checked + .switch-left {
  background-color: #0084d0;
  color: #fff;
  bottom: 0px;
  left: 0.5em;
  height: 2.5em;
  width: 3em;
  transform: rotate(0deg) skewX(0deg);
}

input:checked + .switch-left::before {
  background-color: transparent;
  width: 3.0833em;
}

input:checked + .switch-left + .switch-right {
  background-color: #ddd;
  color: #888;
  bottom: 0.4em;
  right: 0.8em;
  height: 2.4em;
  width: 2.75em;
  transform: rotate(-15deg) skewX(-15deg);
}

input:checked + .switch-left + .switch-right::before {
  background-color: #ccc;
}

/* Keyboard Users */
input:focus + .switch-left {
  color: #333;
}

input:checked:focus + .switch-left {
  color: #fff;
}

input:focus + .switch-left + .switch-right {
  color: #fff;
}

input:checked:focus + .switch-left + .switch-right {
  color: #333;
}



</style>
