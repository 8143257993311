<template>

	<portal to="top-layer">

		<div v-show="show" class="overlay"></div>

		<div v-show="show" class="box alert" :class="classes">

			<div v-if="title" class="box wide center alert-title mb-20">{{ title }}</div>

			<div v-if="message" class="box wide center alert-message">{{ message }}</div>

			<div class="box wide center mt-50">
				<vue-button @click="ok">OK</vue-button>
			</div>

		</div>

	</portal>

</template>

<script>

export default {

	name: 'VueAlert',

	props: ['show', 'classes', 'title', 'message'],

	data() {
		return {}
	},

	methods: {

		ok() {

			this.$emit('ok')
		}
	}
}

</script>

<style lang="scss">

.overlay {
	position: fixed;
	left: 0; top: 0;
	width: 100%; height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
}

.alert {

	position: fixed; left: 50%; top: 50%;
	transform: translate(-50%, -50%);
	padding: 30px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	width: auto; max-width: 80%;

	.alert-title {

		font-weight: bold;
	}
}

</style>
