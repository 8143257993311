<template>

	<div
		class="tag-wrap"
		:class="classNames"
	>

		<div class="tag-list">

			<div
				class="tag"
				v-for="(item, index) in list"
			>

				<span>{{ item }}</span>

				<div
					v-if="!readonly"
					class="tag-delete ion-ios-close-empty"
					@click="deleteTag(index)"
				>

				</div>

			</div>

		</div>

		<div
			v-if="!readonly"
			class="tag-add"
			:class="{ add: showAdd }"
			v-click-outside="hideAddNewTag"
		>

			<div
				class="tag-add-input-box"
				:class="{ active: showAdd }"
			>

				<input
					ref="input"
					v-model="input"
					@keyup="onKeyUp"
				/>

			</div>

			<button
				v-if="!limited"
				class="tag-add-button"
				:class="{ [buttonClass]: true }"
				@click="addNewTag"
			>

			</button>

		</div>

	</div>

</template>


<script>

import palette from '@/utils/palette.js'

export default {

	name: "VueTag",

	props: ['classes', 'tags', 'readonly', 'limited'],

	data() {
		return {
			showAdd: false,
			input: '',
			list: [],
			buttonClass: 'ion-ios-plus-empty'
		}
	},

	computed: {

		classNames() {
			return `${ this.classes } ${ this.readonly ? '' : 'editable' }`
		},

		colors() {

			if (!this.color) {

				return null

			} else {

				return {
					color	: this.color,
					shade	: palette.autoShade(this.color, 10),
					text	: palette.autoShade(this.color, 255)
				}
			}
		}
	},

	methods: {

		deleteTag(index) {

			let tag = this.list[index]
			this.$emit('delete', { tag })

			this.list.splice(index, 1)
		},

		onKeyUp(e) {

			if (e.keyCode == 13) {

				// enter
				this.addNewTag()

			} else if (e.keyCode == 27) {

				// esc
				this.hideAddNewTag()
			}
		},

		addNewTag() {

			if (!this.showAdd) {

				this.showAddNewTag()

				setTimeout(() => $(this.$refs.input).focus())

			} else {

				let tag = this.input.trim()

				if (tag.length > 0) {

					this.list.push(tag)

					this.$emit('add', { tag })
				}

				this.hideAddNewTag()
			}
		},

		showAddNewTag() {

			this.showAdd = true
			this.buttonClass = 'ion-ios-checkmark-empty'
		},

		hideAddNewTag() {

			this.input = ''
			this.showAdd = false
			this.buttonClass = 'ion-ios-plus-empty'
		}
	},

	watch: {

		tags(newVal, oldVal) {

			this.list = [...newVal]
		}
	},

	mounted() {

		if (this.tags)
			this.list = [...this.tags]
	}
}
</script>


<style lang="scss" scoped>

$tag-height: 40px;
$tag-text-color: #fff;
$tag-background: $theme-color;
$tag-border-radius: 20px;


.tag-wrap {

	width: auto; max-width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	.tag-list {

		display: flex;
		flex-wrap: wrap;
		max-width: 100%;

		.tag {

			margin-right: 4px; margin-bottom: 4px;
			height: $tag-height; max-width: 100%;
			padding-left: 20px; padding-right: 10px;
			line-height: $tag-height; font-size: 20px;
			color: $tag-text-color;
			background-color: $tag-background;
			border-radius: $tag-border-radius;
			display: flex;

			span {
				// max-width: calc(100% - 30px);
				max-width: 100%;
				margin-right: 10px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.tag-delete {

				position: relative;
				min-width: 30px; height: $tag-height;
				line-height: $tag-height; text-align: center; font-size: 28px;
				border-top-right-radius: $tag-border-radius; border-bottom-right-radius: $tag-border-radius;
				display: flex;
				opacity: 0.5;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&:hover {

					opacity: 1;
				}
			}
		}
	}

	.tag-add {

		display: flex;

		.tag-add-input-box {

			position: relative;
			width: 0px; height: $tag-height;
			background-color: $tag-background;
			border-radius: $tag-border-radius;
			z-index: 1;

			input {

				position: absolute; left: 0; top: 0;
				width: 100%; height: 100%;
				line-height: $tag-height; font-size: 20px;
				padding-left: 20px; padding-right: 10px;
				background-color: transparent;
				border: none;
				outline: none;
				color: $tag-text-color;
			}

			&.active {

				width: 100px;
			}
		}

		button {

			position: relative;
			width: 40px; height: $tag-height;
			line-height: $tag-height;
			color: $tag-text-color;
			background-color: $tag-background;
			border-radius: $tag-border-radius;
			font-size: 28px;
			cursor: pointer;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {

				background-color: lighten($tag-background, 10%);
			}
		}
	}

	.tag-add.add {

		.tag-add-input-box {
			border-top-right-radius: 0; border-bottom-right-radius: 0;
		}

		button {
			border-top-left-radius: 0; border-bottom-left-radius: 0;
		}
	}
}

.tag-wrap.editable {

	.tag {

		padding-right: 5px;
	}
}

$alt-tag-height: 40px;
$alt-tag-text-color: #000;
$alt-tag-background: $highlight-color;
$alt-tag-delete-color: #000;
$alt-tag-delete-color-hover: #000;
$alt-tag-delete-background: $alt-tag-background;
$alt-tag-delete-background-hover: darken($alt-tag-background, 5%);
$alt-tag-add-color: lighten($alt-highlight-color, 10%);
$alt-tag-add-color-hover: lighten($alt-highlight-color, 20%);
$alt-tag-add-background: darken($highlight-color, 10%);
$alt-tag-add-background-hover: darken($highlight-color, 20%);

.theme-alt {

	.tag-wrap {

		.tag-list {

			.tag {

				color: $alt-tag-text-color;
				background-color: $alt-highlight-color;
			}
		}

		.tag-add {


			.tag-add-input-box {
				background-color: $alt-highlight-color;
			}

			input {

				color: $alt-tag-text-color;
			}

			button {

				background-color: $alt-highlight-color;
				color: $alt-tag-text-color;

				&:hover {

					background-color: lighten($alt-highlight-color, 10%);
				}
			}
		}
	}
}
</style>
