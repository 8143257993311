<template>

	<div :class="classNames" :style="{ height: height + 'px' }">

		<slot></slot>

	</div>

</template>




<script>

export default {

	name: 'Screen',

	props: ['classes'],

	data() {

		return {
			height: 0,
		}
	},

	computed: {

		classNames() {

			return 'screen ' +
			(this.classes ? this.classes : '')
		}
	},

	methods: {

		resize() {

			let height = $(window).height() - 100

			this.height = height > 720 ? 720 : height
		}
	},

	mounted() {

		this.resize()
	},
}

</script>




<style lang="scss" scoped>

.screen {
	position: relative;
	width: 360px; height: 640px;
	border-radius: 40px;
	padding: 20px;
	background-image: linear-gradient(120deg,#fff 45%,#f5f5f5);
	border: 1px solid hsla(240,6%,83%,.63);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

</style>
