<template>

	<div id="landing" class="screen">

		<div class="welcome-text">
			wake up
		</div>

		<div id="particles"></div>

	</div>

</template>




<script>

export default {

	name: 'Landing',

	data() {

		return {

		}
	},

	mounted() {

		setTimeout(() => this.showParticles())
	},

	methods: {

		showParticles() {

			particlesJS("particles", {
			  "particles": {
			    "number": {
			      "value": 40,
			      "density": {
			        "enable": true,
			        "value_area": 800
			      }
			    },
			    "color": {
			      "value": "#ffffff"
			    },
			    "shape": {
			      "type": "circle",
			      "stroke": {
			        "width": 0,
			        "color": "#000000"
			      },
			      "polygon": {
			        "nb_sides": 5
			      },
			      "image": {
			        "src": "img/github.svg",
			        "width": 100,
			        "height": 100
			      }
			    },
			    "opacity": {
			      "value": 0.5,
			      "random": false,
			      "anim": {
			        "enable": false,
			        "speed": 1,
			        "opacity_min": 0.1,
			        "sync": false
			      }
			    },
			    "size": {
			      "value": 3,
			      "random": true,
			      "anim": {
			        "enable": false,
			        "speed": 40,
			        "size_min": 0.1,
			        "sync": false
			      }
			    },
			    "line_linked": {
			      "enable": true,
			      "distance": 150,
			      "color": "#ffffff",
			      "opacity": 0.4,
			      "width": 1
			    },
			    "move": {
			      "enable": true,
			      "speed": 6,
			      "direction": "none",
			      "random": false,
			      "straight": false,
			      "out_mode": "out",
			      "bounce": false,
			      "attract": {
			        "enable": false,
			        "rotateX": 600,
			        "rotateY": 1200
			      }
			    }
			  },
			  "interactivity": {
			    "detect_on": "canvas",
			    "events": {
			      "onhover": {
			        "enable": true,
			        "mode": "grab"
			      },
			      "onclick": {
			        "enable": true,
			        "mode": "push"
			      },
			      "resize": true
			    },
			    "modes": {
			      "grab": {
			        "distance": 140,
			        "line_linked": {
			          "opacity": 1
			        }
			      },
			      "bubble": {
			        "distance": 400,
			        "size": 40,
			        "duration": 2,
			        "opacity": 8,
			        "speed": 3
			      },
			      "repulse": {
			        "distance": 200,
			        "duration": 0.4
			      },
			      "push": {
			        "particles_nb": 4
			      },
			      "remove": {
			        "particles_nb": 2
			      }
			    }
			  },
			  "retina_detect": true
		  })
		}
	}
}

</script>




<style lang="scss" scoped>

@keyframes flicker {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.1;
	}
	100% {
		opacity: 1;
	}
}

#landing {

	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.welcome-text {
		font-size: 8rem; line-height: 8rem; color: #fff;
		width: 100%; height: auto;
		display: flex;
		justify-content: center;
		animation: flicker 2s infinite;
	}

	#particles {

		position: fixed; left: 0%; top: 0%;
		width: 100%; height: 100%;

		canvas {
			display: block;
			vertical-align: bottom;
		}
	}
}

</style>
