<template>

    <div class="loader-container" :class="{ visible: loading, block: block }">

		<div v-if="block" class="loader-icon ion-ios-loop"></div>

		<div v-if="!block" class="loader-bar">

			<div
				class="loader-bar-progress"
				:style="{
					backgroundColor	: color ? color : '',
					width			: progress + '%' 
				}"
			></div>

		</div>

	</div>

</template>



<script>

    export default {

        name: 'VueLoader',

        props: ['loading', 'block', 'progress', 'color']
    }

</script>



<style lang="scss" scoped>

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader-container {

	position: fixed; left: 0; top: 0;
	width: 100%; height: 3px;
	background-color: rgba(0, 0, 0, 0);
	display: none;
	transition: none;
	z-index: 99;

	&.block {
		width: 100%; height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
	}

	&.visible {
		display: block;
	}

	.loader-icon {
		position: fixed;
		left: 50%; top: 50%; margin-left: -50px; margin-top: -50px;
		width: 120px; height: 120px;
		font-size: 120px; line-height: 120px;
		text-align: center; color: rgba(255, 255, 255, 0.8);
		transform-origin: center center;
		animation: loading 1000ms infinite;
	}

	.loader-bar {

		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;

		.loader-bar-progress {
			position: absolute;
			left: 0; top: 0;
			width: 0; height: 100%;
			background-color: aqua;
			transition: width 200ms linear;
		}
	}
}


</style>
