<template>

<div
	class="tag-search-wrap"
	:class="classNames"
	:style="{
		backgroundColor: styles && styles.backgroundColor ? styles.backgroundColor : ''
	}"
>

	<div class="tag-search-box">

		<div class="tag-search-main">

			<div
				v-for="tag in tags"
				class="tag-search-tag"
				:style="{
					backgroundColor: styles && styles.tagColor ? styles.tagColor : '',
					color: styles && styles.tagTextColor ? styles.tagTextColor : ''
				}"
			>
				<div>{{ tag.name }}</div>
				<div class="tag-delete bi-x-lg" @click="removeTag(tag)"></div>
			</div>

			<div
				class="tag-search-input-box"
				:style="{
					color: styles && styles.textColor ? styles.textColor : ''
				}"
			>

				<div v-show="!isTyping" class="tag-search-input-placeholder">{{  placeholder ? placeholder : 'Search' }}</div>

				<div
					class="tag-search-input"
					ref="input"
					contenteditable="true"
					@keydown="onKeyDown"
					@keyup="onKeyUp"
					@focus="onFocus"
				></div>

			</div>

		</div>

		<div class="tag-search-action">

			<div
				class="tag-search-button bi-search"
				:style="{
					color: styles && styles.buttonTextColor ? styles.buttonTextColor : '',
					backgroundColor: styles && styles.buttonColor ? styles.buttonColor : ''
				}"
				@click="search"
			></div>

			<div
				v-show="!isEmpty"
				class="tag-search-reset bi-ban"
				:style="{
					color: styles && styles.textColor ? styles.textColor : '',
				}"
				@click="reset"
			></div>

		</div>

	</div>

</div>

</template>

<script>

var _ = require('lodash')

export default {

	name: 'VueTagSearch',

	props: [
		'value',
		'styles',
		'input',
		'tags',
		'placeholder',
		'suggestions',
	],

	data() {
		return {
			typed: '',
			showPlaceholder: true,
		}
	},

	computed: {

		isTyping() {
			return this.typed.trim().length == 0 ? false : true
		},

		isEmpty() {
			return this.tags.length == 0
		},

		classNames() {
			return (
				(this.classes ? this.classes : '')
			);
		}
	},

	methods: {

		resize() {

			this.height = $(this.$refs.div).height() < 50 ? 50 : $(this.$refs.div).height() + 20
		},

		onKeyDown(e) {

			if (e.keyCode == 13) {
				e.preventDefault()
				this.search()
				return
			}

			if (this.typed.length > 0)
				this.showPlaceholder = false

			this.$emit('keydown', e)
		},

		onKeyUp(e) {

			this.typed = e.target.textContent.trim()

			if (this.typed.length == 0) {
				this.showPlaceholder = true
			}

			this.$emit('keyup', e, this.typed)
		},

		onFocus() {
			this.$emit('focus', this.typed)
		},

		removeTag(tag) {

			this.$emit('removeTag', tag)
		},

		reset() {
			this.$emit('reset')
		},

		search() {

			if (this.typed &&
				this.typed.trim().length > 0 &&
				this.tags.filter(i => i.name.toLowerCase() == this.typed.toLowerCase()).length == 0
			)
				this.tags.push({ name: this.typed })

			this.$refs.input.innerHTML = ''
			this.typed = ''

			this.$emit('search', { tags: this.tags })
		}
	},

	watch: {

		value(newVal, oldVal) {

			if (newVal == this.typed)
				return

			this.typed = newVal

			$(this.$refs.input).html(newVal)
		}
	},

	mounted() {


	}
};

</script>

<style lang="scss" scoped>

$tag-search-bg-color: rgba(0, 0, 0, 0.07);

.tag-search-wrap {

	position: relative;
	width: 100%;
	height: auto;
	border-radius: 10px;
	overflow: hidden;

	.tag-search-box {

		position: relative;
		background-color: $tag-search-bg-color;
		user-select: text;
		width: 100%;
		min-height: 40px;
		height: auto;
		color: $text-color; text-align: left; font-size: 20px;
		padding: 5px 5px 0px 5px;
		outline: none;
		overflow: hidden;
		display: flex;
		justify-content: space-between;

		.tag-search-main {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			width: calc(100% - 80px);

			.tag-search-tag {

				margin: 0 5px 5px 0;
				height: 30px;
				line-height: 30px;
				padding: 0 5px 0 8px;
				border-radius: 8px;
				font-size: 16px;
				background-color: rgba(255, 255, 255, 0.5);
				white-space: nowrap;
				display: flex;

				.tag-delete {
					margin-left: 5px;
					line-height: 30px; font-size: 15px;
					opacity: 0.6;
					cursor: pointer;
				}
			}

			.tag-search-input-box {

				position: relative;
				margin: 0 5px 5px 0;
				outline: none;
				flex: 1 0 80px;
				border-radius: 5px;
				max-width: 100%; height: 30px;

				.tag-search-input {
					position: relative;
					width: 100%; height: 30px;
					line-height: 30px;
					padding: 0 5px;
					border-radius: 5px;
					font-size: 16px;
					outline: none;
					white-space: nowrap;
					z-index: 2;
				}

				.tag-search-input-placeholder {
					position: absolute; left: 5px; top: 0;
					width: 100%; height: 100%;
					line-height: 30px;
					transition: none;
					opacity: 0.5;
					z-index: 1;
				}
			}
		}

		.tag-search-action {

			display: flex;
			flex-wrap: nowrap;
			flex: 0 0 79px;
			flex-direction: row-reverse;

			.tag-search-reset {

				flex: 0 0 30px;
				margin: 0 5px 0 0;
				height: 30px;
				font-size: 20px; line-height: 32px; text-align: center;
				cursor: pointer;
			}

			.tag-search-button {
				flex: 0 0 40px;
				height: 30px;
				font-size: 16px; line-height: 30px; text-align: center;
				border-radius: 8px;
				background-color: rgba(255, 255, 255, 0.5);
				cursor: pointer;
			}
		}
	}
}

$alt-tag-search-bg-color: rgba(255, 255, 255, 0.1);

.theme-alt {

	.tag-search-wrap {

		.tag-search-box {

			background-color: $alt-tag-search-bg-color;

			.tag-search-main {

				.tag-search-tag {

					background-color: rgba(0,0,0,0.2);
					color: #fff;
				}

				.tag-search-input-box {

					.tag-search-input {
					}

					.tag-search-input-placeholder {
						color: #fff;
					}
				}
			}

			.tag-search-action {


				.tag-search-reset {
					color: rgba(255,255,255,0.5);
				}

				.tag-search-button {
					color: #fff;
					background-color: rgba(0,0,0,0.2);
				}
			}
		}
	}
}

</style>
