<template>

	<div
		:class="classes"
		v-click-outside="onClickOutside"
	>

		<vue-menu-icon @click.native.stop="toggleMenu" :status="!displayMenu" />

		<div
			class="topbar-menu box wide"
		>

			<vue-menu
				:menu="menu"
				:display="displayMenu"
				@select="onSelect"
			/>

		</div>

	</div>

</template>

<script>

export default {

	name: "VueTopbar",

	props: ['menu', 'display'],

	data() {
		return {
			displayMenu: false
		}
	},

	computed: {

		classes() {
			return `
				topbar box v-mid
			`
		}
	},

	methods: {

		toggleMenu() {
			this.displayMenu = !this.displayMenu
		},

		onClickOutside() {
			this.displayMenu = false
		},

		onSelect(item) {
			this.displayMenu = false
			this.$emit('select', item)
		}
	},

	mounted() {

	}
}
</script>


<style lang="scss" scoped>

$topbar-background: #eee;
$topbar-menu-background: #eee;
$topbar-border-bottom: 1px solid darken($topbar-background, 10%);

.topbar {

	position: fixed;
	left: 0; top: 0;
	width: 100%; height: 60px;
	background-color: $topbar-background;
	border-bottom: $topbar-border-bottom;

	.topbar-menu {

		position: absolute;
		left: 0; top: 100%;
		width: 100%;
		padding: 0 10px;
		background-color: $topbar-menu-background;
	}
}

$alt-topbar-background: #555;
$alt-topbar-menu-background: #555;
$alt-topbar-border-bottom: 1px solid darken($alt-topbar-background, 10%);

.theme-alt {

	.topbar {

		background-color: $alt-topbar-background;
		border-bottom: $alt-topbar-border-bottom;

		.topbar-menu {

			background-color: $alt-topbar-menu-background;
		}
	}
}

</style>
