<template>

	<button :class="classNames" @click="onClick">

		<slot></slot>

	</button>

</template>




<script>

export default {

	name: 'CustomButton',

	props: ['classes'],

	data() {

		return {
			clicked: false
		}
	},

	computed: {

		classNames() {

			return 'button ' +
			(this.classes ? this.classes : '') +
			(this.clicked ? ' active' : '')
		}
	},

	methods: {

		onClick() {

			if (this.clicked)
				return

			this.$emit('click')

			this.clicked = true

			setTimeout(() => this.clicked = false, 150)
		}
	}
}

</script>




<style lang="scss" scoped>

button {

	position: relative;
	padding: 10px; padding-left: 25px; padding-right: 25px;
	color: black;
	font-size: 30px;
	outline: none;
	border: none;
	background-color: gold;
	border-radius: 10px;
	box-shadow: 0px 10px PaleGoldenrod;
	cursor: pointer;
	transition: all 100ms linear;
	display: inline-block;

	&:active {
		margin-top: 15px !important;
		box-shadow: 0px 3px PaleGoldenrod;
	}

	&.active {
		margin-top: 15px !important;
		box-shadow: 0px 3px PaleGoldenrod;
	}

	&.secondary {

		background-color: MediumPurple;
		box-shadow: 0px 10px DarkSlateBlue;
		color: #fff;

		&:hover {
			background-color: BlueViolet;
			box-shadow: 0px 10px Indigo;
		}

		&:active {
			margin-top: 15px !important;
			box-shadow: 0px 3px Indigo;
		}
	}
}

</style>
