<template>

	<div class="box wide">

		<div class="box wide checkbox-item" v-for="city in filtered">

			<vue-checkbox
				classes="radius-6"
				@toggle="onToggleCheckbox($event, city.name)"
				:checked="selected.includes(city.name)"
				:ref="city.name"
			>
				{{ city.name }}
			</vue-checkbox>

		</div>

	</div>

</template>




<script>

import cities from './cities.js'

export default {

	name: 'CityList',

	components: {

	},

	props: ['selected', 'letter', 'filter'],

	data() {

		return {
			cities,
			filtered: cities
		}
	},

	computed: {

	},

	methods: {

		onToggleCheckbox(status, city) {

			this.$emit('toggle', { status, city })
		}
	},

	watch: {

		letter(newVal, oldVal) {

			let ref = ''

			Object.keys(this.$refs).forEach(item => {

				if (!ref && item.substr(0, 1).toLowerCase() == newVal.toLowerCase())
					ref = item
			})

			if (ref)
				this.$refs[ref][0].$el.scrollIntoView()

		},

		filter(newVal, oldVal) {

			if (newVal === '')
				this.filtered = this.cities
			
			this.filtered = this.cities.filter(city => city.name.toLowerCase().includes(newVal.toLowerCase()))
		}
	},

	mounted() {

		if (this.filter)
			this.filtered = this.cities.filter(city => city.name.toLowerCase().includes(this.filter.toLowerCase()))
		else
			this.filtered = this.cities
	}
}

</script>




<style lang="scss" scoped>

.checkbox-item {
	margin-bottom: 10px;
}

</style>
