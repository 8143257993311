var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrap",staticClass:"tooltip-wrap box",class:_vm.classNames,style:({
		position: _vm.position ? 'absolute' : 'relative',
		left	: _vm.position ? _vm.position.x + 'px' : '',
		top		: _vm.position ? _vm.position.y + 'px' : ''
	})},[_c('vue-button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDisplay),expression:"hideDisplay"}],style:({ ..._vm.styles }),attrs:{"classes":"tooltip-button icon","icon":_vm.icon ? _vm.icon : 'ion-ios-information-outline'},nativeOn:{"click":function($event){return _vm.toggleDisplay.apply(null, arguments)}}}),_c('portal',{attrs:{"to":"top-layer"}},[_c('div',{ref:"tooltip",staticClass:"tooltip box",class:{ [_vm.showArea]: true },style:({
				left: _vm.showLeft + 'px',
				top: _vm.showTop + 'px',
				width: _vm.showWidth ? _vm.showWidth + 'px' : 'auto',
				background: _vm.background,
				color: _vm.color,
				display: _vm.display
			})},[_c('div',{ref:"arrow",staticClass:"tooltip-arrow",style:({ ..._vm.arrowStyle })}),_c('div',{staticClass:"box wide tooltip-text"},[_vm._v(_vm._s(_vm.text))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }