<template>

	<screen>

		<div class="box wide screen-box">

			<Cover v-show="!status" @start="start" name="Design 3" :record="record" :isBest="isBest" />

			<div v-if="status" class="box stretch screen-header">
				<Header
					@restart="restart"
					@finish="finish"
					@remove="onToggleCheckbox"
					:targets="targets"
					:selected="selected"
					:completed="completed"
					:counter="counter"
				 />
			</div>

            <div v-if="status" class="box stretch mt-10">
                <vue-input 
					placeholder="Search" 
					:value="filter" 
					@keyup="onSearch" 
					:button="filter ? 'ion-ios-close' : ''" 
					@submit="clearFilter" 
				/>
            </div>

			<div v-if="status" class="box mt-20 screen-main-list">

				<CityList :selected="selected" :filter="filter" @toggle="onToggleCheckbox" />

			</div>

		</div>

	</screen>

</template>




<script>

import Screen from './Screen'
import SearchBox from './SearchBox'
import CityList from './CityList'
import Header from './Header'
import Cover from './Cover'

export default {

	name: 'Design3',

	components: {
		Screen,
		SearchBox,
		Header,
		Cover,
		CityList
	},

	props: ['targets', 'isBest'],

	data() {

		return {
			status: false,
			counter: '',
			selected: [],
			completed: false,
			record: null,
            filter: ''
		}
	},

	computed: {

		check() {

			if (this.selected.length != this.targets.length)
				return false

			let error = null

			this.targets.forEach(target => {

				if (this.selected.filter(item => target == item).length == 0)
					error = true
			})

			return !error
		}
	},

	methods: {

		start() {

			this.status = true
			this.counter = 'reset'
			setTimeout(() => this.counter = 'start')

			this.filter = ''
		},

		onToggleCheckbox(params) {

			if (params.status && !this.selected.includes(params.city))
				this.$set(this.selected, this.selected.length, params.city)
			else if (!params.status && this.selected.includes(params.city))
				this.selected = this.selected.filter(item => item != params.city)

			if (this.check) {

				this.counter = 'pause'

				this.completed = true
			}
		},

		restart() {

			this.status = false
			this.selected = []
			this.completed = false
		},

		finish(e) {

			if (!this.record || e.min < this.record.min || e.sec < this.record.sec || e.ms < this.record.ms)
				this.record = e

				this.$emit('finish', { ...this.record, name: 'Design3' })
		},

        onSearch(e) {

            this.filter = e.target.value.trim()
        },

		clearFilter() {

			this.filter = ''
		}
	},

}

</script>




<style lang="scss" scoped>

.screen-box {
	height: 100%;
	flex-direction: column;
	flex-wrap: nowrap;
}


.screen-main-list {
	height: calc(100% - 100px);
	overflow: scroll;
	border-radius: 20px;
	padding: 0 20px 20px 20px;
	flex: 1;
}


</style>
