<template>

	<button :class="classNames" @click="onClick">

		<div v-if="icon && icon.length > 0" :class="'button-icon ' + icon"></div>

		<div class="button-text">

			<slot></slot>

		</div>

	</button>

</template>

<script>

export default {

	name: "VueButton",

	props: ["classes", "icon", "size"],

	data() {
		return {}
	},

	computed: {

		classNames() {
			return (
				this.classes + ' ' + (this.size ? 'size-' + this.size : '')
			)
		}
	},

	methods: {

		onClick() {
			this.$emit('click')
		}
	}
}

</script>

<style lang="scss" scoped>

$button-background: $theme-color;
$button-background-hover: lighten($button-background, 15%);
$button-border: 1px solid $button-background;
$button-border-hover: 1px solid $button-background-hover;
$button-border-radius: 10px;
$button-text-color: rgba(255, 255, 255, 1);
$button-text-color-hover: rgba(255, 255, 255, 1);
$button-danger-background: $danger-color;
$button-danger-background-hover: lighten($danger-color, 15%);
$button-danger-border: 1px solid $danger-color;
$button-danger-text-color: rgba(255, 255, 255, 1);

button {

	width: auto;
	color: rgba(0, 0, 0, 1);
	background-color: $button-background;
	border: $button-border;
	padding: 15px;
	color: $button-text-color;
	text-align: center;
	font-size: $text-font-size;
	justify-content: center;
	outline: none;
	cursor: pointer;
	transition: all 200ms linear;
	user-select: none;
	display: flex;
	align-items: center;
	border-radius: $button-border-radius;

	.button-icon {
		margin-right: 7px;
		display: flex;
		align-items: center;
	}

	&:hover {
		background-color: $button-background-hover;
		color: $button-text-color-hover;
		border: $button-border-hover;
	}

	&.outline {

		background-color: transparent;
		color: $button-background;

		&:hover {
			background-color: $button-background-hover;
			color: $button-text-color;
		}
	}

	&.danger {

		background-color: $button-danger-background;
		color: $button-danger-text-color;
		border: $button-danger-border;

		&:hover {

			background-color: $button-danger-background-hover;
		}

		&.outline {

			background-color: transparent;
			color: $button-danger-background;

			&:hover {
				background-color: $button-danger-background;
				color: $button-danger-text-color;
			}
		}
	}

	&.circle {
		width: 50px; height: 50px;
		padding: 0;
		border-radius: 50px;
		font-size: 30px;
		text-align: center;
		justify-content: center;
		align-items: center;

		.button-icon {
			margin-right: 0;
		}
	}

	&.link {

		padding: 0;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 16px;
		border: none;
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}

	&.icon {

		background-color: transparent;
		font-size: 30px; text-align: center;
		padding: 0px;
		border: none;

		.button-icon {
			margin-right: 0;
			min-width: 30px;
			text-align: center;
			display: flex;
			justify-content: center;
			border: none;
			color: $button-background;
		}

		.button-text {
			display: none;
		}

		&:hover {
			.button-icon {
				color: $button-background-hover;
			}
		}
	}

	@for $i from 1 through 10 {

		$size: #{$i * 10px};

		&.size-#{$i * 10} {

			height: $size;

			&.round {
				border-radius: $size;
			}

			&.circle {
				width: $size; height: $size;
			}

			&.icon {

				height: auto;
				font-size: $size;

				.button-icon {
					font-size: $size;
				}
			}
		}
	}

	@for $i from 1 through 100 {

		$radius: #{$i * 1px};

		&.radius-#{$i} {

			border-radius: $radius !important;
		}
	}
}

$alt-button-background: $alt-highlight-color;
$alt-button-background-hover: lighten($alt-highlight-color, 20%);
$alt-button-border: 1px solid $alt-highlight-color;
$alt-button-border-hover: 1px solid lighten($alt-highlight-color, 20%);
$alt-button-border-radius: 10px;
$alt-button-text-color: rgba(0, 0, 0, 0.6);
$alt-button-text-color-hover: rgba(0, 0, 0, 1);
$alt-button-danger-background: $alt-danger-color;
$alt-button-danger-background-hover: $alt-danger-color-hover;
$alt-button-danger-border: 1px solid $alt-danger-color;
$alt-button-danger-text-color: #fff;

.theme-alt {

	button {

		background-color: $alt-button-background;
		border: $alt-button-border;
		color: $alt-button-text-color;
		font-size: $alt-text-font-size;

		&:hover {
			background-color: $alt-button-background-hover;
			color: $alt-button-text-color-hover;
		}

		&.outline {

			background-color: transparent;
			color: $alt-highlight-color;

			&:hover {
				background-color: $alt-button-background;
				color: $alt-button-text-color-hover;
			}
		}

		&.danger {

			background-color: $alt-button-danger-background;
			color: $alt-button-danger-text-color;
			border: $alt-button-danger-border;

			&:hover {

				background-color: $alt-button-danger-background-hover;
			}

			&.outline {

				background-color: transparent;
				color: $alt-button-danger-background;

				&:hover {
					background-color: $alt-button-danger-background;
					color: $alt-button-danger-text-color;
				}
			}
		}

		&.icon {

			background-color: transparent;
			border: none;

			.button-icon {
				color: $alt-highlight-color;
			}
		}
	}
}
</style>
