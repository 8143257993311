<template>

	<div class="container">

		<div class="case" id="soap" :style="{ backgroundImage: `url(${ soap })`}"></div>

	</div>

</template>

<script>

export default {

	name: 'CaseStudy',

	data() {

		return {
			soap: require('@/assets/case-study/soap.svg')
		}
	},

	methods: {

	}
}

</script>




<style lang="scss" scoped>

.container {

	position:absolute; left:0px; top:0px;
	width:100%; height:100%;

	.case {
		width: 100%; height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	#soap {
		background-color: #1da2db;
	}
}

</style>
