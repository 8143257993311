<template>

	<div class="box stretch wide v-mid pl-40 pr-40">
		
		<div v-if="bestTry" class="box best-try mb-10" :class="isBest ? 'best' : ''">{{ bestTry }}</div>

		<div class="box wide name">
			<span>{{ name.substr(0, 6) }}</span>
			<div class="name-number">{{  name.substr(7) }}</div>
		</div>

		<div class="box wide instruction">
			A counter will appear after clicking Start
		</div>

		<div class="box wide instruction">
			You can cancel anytime and restart again
		</div>

		<div class="box wide right mt-20">
			<vue-button classes="radius-50 width-100" @click="start">Start</vue-button>
		</div>
		
	</div>

</template>




<script>

export default {

	name: 'Cover',

	components: {
		
	},

	props: ['name', 'record', 'isBest'],

	data() {

		return {
			
		}
	},

	computed: {
		
		bestTry() {

			if (!this.record)
				return null

			let min = this.record.min < 10 ? '0' + this.record.min : this.record.min
			let sec = this.record.sec < 10 ? '0' + this.record.sec : this.record.sec
			let ms = this.record.ms < 10 ? '0' + this.record.ms : this.record.ms
			
			return `${ min }:${ sec }:${ ms }`
		}
	},

	methods: {

		start() {
			
			this.$emit('start')
		}
	}
}

</script>




<style lang="scss" scoped>

.name {
	font-size: 32px; line-height: 36px;
	margin: 0 0 50px 0;
}

.name-number {
	margin-left: 20px;
	font-size: 80px;
	color: rgba(0, 0, 0, 0.2);
}

.instruction {
	margin: 0 0 50px 0;
	border-left: 2px solid $theme-color;
	text-align: left;
	padding-left: 10px;
	font-size: 16px; line-height: 20px;
}

.best-try {
	min-width: 50px; height: 26px;
	padding-left: 10px; padding-right: 15px;
	border-radius: 15px;
	font-size: 18px; line-height: 26px;
	background-color: #cfe4ff;
	color: #245a9c;
}

.best-try.best {
	background-color: rgba(99, 222, 24, 0.3);
	color: #376e27;
}

</style>
