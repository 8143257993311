<template>

	<div class="screen day">

		<div
			id="animation-day"
			class="animation"
			:style="animationStyles"
		></div>

		<div class="animation-text box tall center">

			<div class="box wide center">
				I'm a
				<span id="ui">UI</span>
				<span id="ux-u">U</span><span id="ux-x">X</span>
				designer at
				<span id="apple"></span>
				by day
			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Day',

	props: ['config'],

	data() {

		return {
			transformSize: 0,
			transition: 'all 1900ms linear, border-color 1s linear'
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		},

		animationStyles() {

			return {
				borderLeftWidth: this.transformSize + 'px',
				borderRightWidth: this.transformSize + 'px',
				borderBottomWidth: this.transformSize + 'px',
				transform: "rotate(" + this.transformSize + "deg)",
				transition: this.transition
			}
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				let winw = $(window).width()
				let winh = $(window).height()

				let size =  winw < winh ? winw : winh

				this.transformSize = size

			} else if (step == 2) {

				let winw = $(window).width()
				let winh = $(window).height()

				let size =  winw < winh ? winw : winh

				this.transformSize = size * 1.5
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.transformSize = 0

			} else if (step == 2) {

				this.transition = 'none'

				let winw = $(window).width()
				let winh = $(window).height()

				let size =  winw < winh ? winw : winh

				this.transformSize = size * 1.5

				setTimeout(() => {

					this.transition = 'all 1900ms linear, border-color 1s linear'

					this.transformSize = size
				})
			}
		}
	}
}

</script>




<style lang="scss" scoped>

@keyframes x {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.day {

	background-color: DeepSkyBlue;

	#animation-day {
		position: absolute;
		width: 0; height: 0;
		border-left: 0px solid transparent;
		border-right: 0px solid transparent;
		border-bottom: 0px solid LightSkyBlue;
		transition: all 1900ms linear, border-color 1s linear;
		display: block;
	}

	#ui {
		margin-left: 0.5rem; margin-right: 0.5rem;
		font-weight: bold;
		background: linear-gradient(167deg, rgba(243,255,0,1) 0%, rgba(142,218,0,1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	#ux-u {
		font-weight: bold;
		background: linear-gradient(167deg, rgba(175,255,144,1) 0%, rgba(70,237,208,1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	#ux-x {
		margin-right: 0.5rem;
		font-weight: bold;
		animation: x 2s infinite;
		background: linear-gradient(167deg, rgba(175,255,144,1) 0%, rgba(70,237,208,1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	#apple {
		margin-left: 1rem; margin-right: 1rem;
		font-size: 5rem;
	}
}

</style>
