<template>

	<div id="home">

		<div id="stage">

			<transition v-if="current < 8">
				<landing />
			</transition>

			<transition>
				<component v-bind:is="animation" :config="config" v-if="count % 2 == 0" />
			</transition>

			<transition>
				<component v-bind:is="animation" :config="config" v-if="count % 2 == 1" />
			</transition>

			<div id="scroll-down-indicators" v-if="current < 8">

				<transition>

					<div class="scroll-down-indicator" v-show="scrollIndicator == 0">

						<div id="arrow-scroll-down-indicator" class="scroll-indicator ion-ios-arrow-down"></div>

					</div>

				</transition>

				<transition>

					<div class="scroll-down-indicator" v-show="scrollIndicator == 1">

						<div id="mouse-scroll-down-indicator" class="scroll-indicator">
							<div id="mouse-scroll-down-indicator-wheel"></div>
						</div>

					</div>

				</transition>

				<transition>

					<div class="scroll-down-indicator" v-show="scrollIndicator == 2">

						<div
							id="keyboard-scroll-down-indicator"
							class="scroll-indicator"
							:style="{ backgroundImage: `url(${ keyboardIcon })` }"
						>
							<div id="keyboard-scroll-down-indicator-key"></div>
						</div>

					</div>

				</transition>

			</div>

			<div id="scroll-down-button" @click="scrollDownTouch" v-touch:start="scrollDownTouch">
				<div id="scroll-down-button-effect" :style="scrollDownButtonStyle"></div>
			</div>

		</div>

		<div id="progress-bar">
			<div
				v-for="section in sections"
				class="progress-bar-section"
				:class="{ active: section.name == currentSection }"
				@click="jumpToAnimation(section)"
			></div>
		</div>

	</div>

</template>



<script>


    export default {

        name: 'Home',

        data() {
            return {
				scrollIndicator: 0,
				keyboardIcon: require('@/assets/img/arrow-keys.svg'),
				scrollDownButtonStyle: {},
				blocked: false,
				jumped: false,
				scroll: 0,
				lastScroll: -1,
				direction: 1,
				lastDirection: 1,
				buffer: 200,
				current: -1,
				animation: null,
				count: 0,
				animationTimeout: 0,
				resizing: 0,

				// steps with next: true will auto play next animation in the end when scroll down
				order: [
					{
						name		: 'blink',
						duration	: 1000,
						step		: 1
					},
					{
						name		: 'blink',
						duration	: 2000,
						step		: 2
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 3
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 4
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 5
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 6
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 7
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 8
					},
					{
						name		: 'blink',
						duration	: 1000,
						step		: 9,
						next		: true
					},
					{
						name		: 'day',
						duration	: 2000,
						step		: 1
					},
					{
						name		: 'day',
						duration	: 1000,
						step		: 2,
						next		: true
					},
					{
						name		: 'night',
						duration	: 2000,
						step		: 1
					},
					{
						name		: 'night',
						duration	: 2000,
						step		: 2,
						next		: true
					},
					{
						name		: 'lfc',
						duration	: 3700,
						step		: 1
					},
					{
						name		: 'lfc',
						duration	: 2000,
						step		: 2
					},
					{
						name		: 'lfc',
						duration	: 2000,
						step		: 3,
						next		: true
					},
					{
						name		: 'probably',
						duration	: 1000,
						step		: 1
					},
					{
						name		: 'probably',
						duration	: 1000,
						step		: 2
					},
					{
						name		: 'probably',
						duration	: 1000,
						step		: 3
					},
					{
						name		: 'probably',
						duration	: 1000,
						step		: 4,
						next		: true
					},
					{
						name		: 'chocolate',
						duration	: 2000,
						step		: 1
					},
					{
						name		: 'chocolate',
						duration	: 1000,
						step		: 2,
						next		: true
					},
					{
						name		: 'bow',
						duration	: 2000,
						step		: 1
					},
					{
						name		: 'bow',
						duration	: 5000,
						step		: 2
					},
					{
						name		: 'bow',
						duration	: 2000,
						step		: 3,
						next		: true
					},
					{
						name		: 'thoughts',
						duration	: 4000,
						step		: 1
					},
					{
						name		: 'thoughts',
						duration	: 2000,
						step		: 2
					},
					{
						name		: 'thoughts',
						duration	: 1000,
						step		: 3,
						next		: true
					},
					{
						name		: 'bye',
						duration	: 2000,
						step		: 1
					},
					{
						name		: 'bye',
						duration	: 2000,
						step		: 2
					},
					{
						name		: 'bye',
						duration	: 3000,
						step		: 3
					},
					{
						name		: 'bye',
						duration	: 8000,
						step		: 4
					}
				]
            }
        },

		computed: {

			config() {

				if (this.current < 0 || this.current >= this.order.length)
					return {}

				let playing = this.order[this.current]

				let obj = {
					step		: playing.step ? playing.step : 1,
					duration	: playing.duration,
					reverse		: this.direction == -1 ? true : false,
					next		: playing.next ? true : false
				}

				return obj
			},

			sections() {

				let sections = []

				this.order.forEach((item, index) => {

					if (sections.filter(section => section.name == item.name).length == 0)
						sections.push({ name: item.name, index })
				})

				return sections
			},

			currentSection() {

				return this.current == -1 || this.current >= this.order.length ? '' : this.order[this.current].name
			}
		},

        methods: {

			scrollDownTouch() {

				window.scrollTo(0, window.scrollY + 1)

				this.scrollDownButtonStyle = {
					width: '0px',
					height: '0px',
					opacity: 1,
					transition: 'none'
				}

				setTimeout(() => {

					this.scrollDownButtonStyle = {
						width: '200px',
						height: '200px',
						opacity: 0,
						transition: 'all 200ms linear'
					}
				})

				setTimeout(() => {

					this.scrollDownButtonStyle = {
						width: '0px',
						height: '0px',
						opacity: 1,
						transition: 'none'
					}

				}, 250)
			},

			handleScroll() {
				this.scroll = window.scrollY
			},

			animate() {

				// 1. do nothing if blocked is true or scroll position does not change
				// 2. if scroll down auto move to next animation if reach the end of current animation(next: true)
				// 3. if scroll up auto move to previous animation if reverse the first of current animation(step == 1)
				// 4. animation to be played is determined by this.current(current index) + this.direction(1 or -1)
				// 5. but if this.jumped is true, play the exact animation it jumped to instead of + this.direction

				if (this.blocked || (this.scroll > 0 && this.scroll == this.lastScroll))
					return

				// can't use this.scroll because it's still changing
				// use the first value when user starts scrolling
				let scroll = this.scroll

				if (scroll == 1) {
					// see the reason below to set scroll position to 1
					return
				}

				this.blocked = true

				this.lastDirection = this.direction

				if (scroll > this.lastScroll)
					this.direction = 1
				else if (scroll < this.lastScroll || scroll <= 0)
					this.direction = -1

				this.lastScroll = scroll

				if (this.current == 0 && this.direction == -1 && this.lastDirection == -1) {

					//console.log('reached top\n\n')
					this.blocked = false

					return
				}

				// keep scrolling down or up
				// increase or decrease current animation No.
				// do not change animation No. if reverse direction
				let i = this.current

				if (this.direction == this.lastDirection) {

					if (!this.jumped)
						i += this.direction
				}

				this.current = i

				if (this.order[this.current] == null) {

					//console.log('THE END\n\n')

					this.current = this.order.length - 1

					this.blocked = false

					return
				}

				let animationName = this.order[this.current].name
				let duration = this.order[this.current].duration

				if (this.direction > 0)
					this.run(animationName)
				else
					this.reverseRun(animationName)

				// console.log(this.direction > 0 ? 'scroll DOWN' : 'scroll UP', this.scroll)

				clearTimeout(this.animationTimeout)

				this.animationTimeout = setTimeout(() => {

					// console.log('finish\n\n')

					if (this.jumped) {

						this.blocked = false
						this.jumped = false

						return
					}

					if (this.current > 0 && this.direction < 0 && this.scroll <= 0) {

						// in case scroll position is at top but there are still animations to be reversed
						// move scroll position to 1 so user can still scroll
						window.scrollTo(0, 1)

					} else if (this.config.step == 1 && this.direction < 0 && this.current > 0) {

						// after reversing the 1st step animation,
						// auto jump to the end of previous animation
						window.scrollTo(0, scroll - 1)

					} else if (this.direction > 0 && this.config.next) {

						// auto scroll to next animation
						window.scrollTo(0, scroll + 1)

					} else {

						window.scrollTo(0, scroll)
					}

					this.blocked = false

				}, duration + this.buffer)
			},

			run(animationName) {

				// console.log('>> ' + animationName.toUpperCase() + ' step ' + this.config.step)

				this.animation = animationName
			},

			reverseRun(animationName) {

				// console.log('<< ' + animationName.toUpperCase() + ' step ' + this.config.step)

				this.animation = animationName
			},

			jumpToAnimation(animation) {

				this.jumped = true
				this.blocked = false

				this.current = animation.index

				window.scrollTo(0, this.scroll + 1)

				// console.log('jump to ' + this.order[this.current].name.toUpperCase() + ' step ' + this.order[this.current].step + '\n\n')
			},

			resize() {

				clearTimeout(this.resizing)

				this.resizing = setTimeout(() => {

					//  12px         ?
					// ------ = ------------
					// 1000px   window width

					let fontSize = Math.round(12 * window.innerWidth / 1000)
					fontSize = fontSize < 8 ? 8 : fontSize
					fontSize = fontSize > 22 ? 22 : fontSize

					document.documentElement.style.fontSize = fontSize + 'px'

				}, 100)
			}
        },

		mounted() {

			this.resize()
			window.addEventListener("resize", this.resize)

			this.scroll = 5000
			window.scrollTo(0, 5000)

			setTimeout(() => window.addEventListener('scroll', this.handleScroll), 1000)

			setInterval(() => this.scrollIndicator = (this.scrollIndicator + 1) % 3, 2000)
		},

		watch: {

			scroll: function() {

				this.animate()
			},

			animation: function() {

				this.count++
			}
		}
    }
</script>



<style lang="scss">

#home {

	height: 35000px;

	#stage {
		position: fixed; left: 0; top: 0;
		width: 100%; height: 100%;
	}
}

@keyframes arrowScrollDownIndicator {
	0% {
		top: 0px;
		opacity: 1;
	}
	100% {
		top: 80px;
		opacity: 0.2;
	}
}

@keyframes mouseScrollDownIndicator {
	0% {
		top: 5px;
	}
	100% {
		top: 20px;
	}
}

@keyframes keyScrollDownIndicator {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes displayArrowScrollDownIndicator {
	0% {

	}
	100% {

	}
}


#scroll-down-indicators {

	position: fixed; bottom: 0px; left: 50%;
	transform: translateX(-50%);
	width: 400px; height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;

	.scroll-down-indicator {

		position: absolute; margin: 10px;
		width: 80px; height: 80px;

		#arrow-scroll-down-indicator {

			position: absolute; left: 50%; top: 0;
			width: 50px; height: 50px;
			transform: translateX(-50%);
			font-size: 70px; text-align: center; color: white;
			animation: arrowScrollDownIndicator 1s infinite;
		}

		#mouse-scroll-down-indicator {

			position: absolute; left: 50%; top: 0;
			transform: translateX(-50%);
			width: 50px; height: 80px;
			border-radius: 50px;
			border: 1px solid #fff;
			color: #fff;

			#mouse-scroll-down-indicator-wheel {

				position: absolute; left: 50%; top: 5px;
				width: 2px; height: 15px;
				background-color: #fff;
				animation: mouseScrollDownIndicator 1s infinite;
			}
		}


		#keyboard-scroll-down-indicator {

			position: absolute; left: 50%; top: 0;
			transform: translateX(-50%);
			width: 90px; height: 80px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;

			#keyboard-scroll-down-indicator-key {

				position: absolute; left: 30px; top: 40px;
				width: 30px; height: 30px;
				background-color: rgba(255, 255, 255, 0.5);
				animation: keyScrollDownIndicator 800ms infinite;
			}
		}
	}
}

#scroll-down-button {

	position: fixed; bottom: 0px; left: 50%;
	transform: translateX(-50%);
	width: 100px; height: 120px;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 10;

	#scroll-down-button-effect {
		position: fixed; top: 50%; left: 50%;
		transform: translate(-50%, -50%);
		width: 0px; height: 0px;
		border-radius: 200px;
		background-color: rgba(255, 255, 255, 0.7);
		opacity: 1;
		transition: all 200ms linear;
	}
}

#progress-bar {
	position: fixed; left: 5px; top: 50%;
	transform: translateY(-50%);
	width: auto; height: auto;
	background-color: rgba(255, 255, 255, 0);
	padding: 10px;
	border-radius: 20px;
	z-index: 9;

	.progress-bar-section {
		position: relative; margin-bottom: 8px;
		width: 8px; height: 8px;
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 0.3);
		cursor: pointer;
		transition: all 300ms cubic-bezier(0.17, 0.89, 0.42, 1.65);

		&:hover {
			background-color: rgba(255, 255, 255, 0.6);
		}

		&:last-child {
			margin-bottom: 0px;
		}

		&.active {
			background-color: rgba(255, 255, 255, 0.9);

			&:hover {
				background-color: rgba(255, 255, 255, 0.9);
			}
		}
	}

	&:hover {

		.progress-bar-section {

			width: 15px; height: 15px;
			border-radius: 15px;
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

.animation-text {

	width: 70%;
	font-size: 3rem;
	color: #000; line-height: 4rem;

	&.light {
		color: #fff;
	}
}

.font-small {
	font-size: 1rem;
	line-height: 1rem;
}

.font-fade {
	opacity: 0.6;
}

</style>
