<template>



	<div id="credit-list-wrap" class="credit-list-wrap" :style="creditWrapStyle">

		<div id="credit-list" class="credit-list" :style="creditStyle">

			<div class="credit-item">
				<div class="credit-item-name">
					Director
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Writers
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Producer
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Executive Producer
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Production Designers
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Art Director
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					User Interface
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					User Experience
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Visual Effects
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Illustrator
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Colorist
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Animation
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Developers
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Browser Optimization
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Mobile Optimization
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item">
				<div class="credit-item-name">
					Ideas
				</div>
				<div class="credit-item-value">
					Barry Youlin
				</div>
			</div>

			<div class="credit-item major">
			</div>

			<div class="credit-item major">
				<div class="credit-item-name">
					R.I.P.<br>
					XL<br>
					1950 - 2018
				</div>
			</div>

			<div class="credit-item major">
				<div class="credit-item-value">
					Y.N.W.A.
				</div>
			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Screen',

	props: ['config'],

	data() {

		return {
			creditStyle: {},
			creditWrapStyle: {}
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				this.creditStyle = {
					transform	: 'translateY(0px)',
					transition	: 'transform 2s linear'
				}

				setTimeout(() => {
					this.creditStyle = {
						transform	: 'translateY(-300px)',
						transition	: 'transform 2s linear'
					}
				}, 100)

			} else if (step == 2) {

				this.creditStyle = {
					transform	: 'translateY(-2000px)',
					transition	: 'transform 12s ease-out'
				}

				setTimeout(() => {

					this.creditWrapStyle = {
						transform: 'rotateX(70deg)'
					}

				}, 7000)
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.creditStyle = {
					transform	: 'translateY(0px)',
					transition	: 'transform 2s linear'
				}

			} else if (step == 2) {

				this.creditWrapStyle = {
					transform: 'rotateX(45deg)'
				}

				setTimeout(() => {

					this.creditStyle = {
						transform: 'translateY(-300px)',
						transition	: 'transform 12s ease-out'
					}

				}, 1000)
			}
		}
	}
}

</script>




<style lang="scss" scoped>

.credit-list-wrap {

	position: absolute; left: 0; top: 0%;
	width: 100%; height: 100%;
	transform: rotateX(45deg);
	transition: transform 1s linear;

	.credit-list {

		position: absolute; left: 0; top: 100%;
		width: 100%; height: auto;
		transition: transform 1s linear;
		transform: translateY(0px);

		.credit-item {

			position: relative; left: 0; margin-top: 15px; margin-bottom: 15px;
			width: 100%; height: auto;
			text-align: center; line-height: 25px;
			display: inline-block;

			&.major {
				margin-bottom: 50px; margin-top: 50px;
			}

			.credit-item-name {

				position: relative; left: 0;
				width: 100%; height: auto;
				font-size: 20px; color: Lime; line-height: 25px;
				display: inline-block;
			}

			.credit-item-value {

				position: relative; left: 0;
				width: 100%; height: auto;
				font-size: 20px; color: #fff; line-height: 25px;
				display: inline-block;
			}
		}
	}
}

</style>
