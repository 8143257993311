<template>

	<div class="box wide center">

		<div id="menu-button-box"></div>

		<div id="project-content" class="box">

			<div id="project-name" class="box wide">{{ projects[projectName].name }}</div>

			<div
				v-for="previewImage in projects[projectName].preview"
				class="project-preview"
			>
				<img :src="previewImage" />
			</div>

			<!-- <div id="project-about" class="box wide mt-30">

				<div class="box project-about">

					<div class="box center project-about-icon ion-ios-person-outline"></div>

					<div class="box fill">

						<div class="box wide project-about-title">My Role</div>

						<div class="box wide project-about-details">UI/UX Designer</div>

					</div>

				</div>

			</div> -->

		</div>

	</div>

</template>

<script>

export default {

	name: 'Project',

	props: ['name'],

	data() {
		return {
			projects: {
				agger: {
					name: 'The Agger Foundation',
					preview: [
						require('@/assets/projects/agger/preview-0.png'),
						require('@/assets/projects/agger/preview-1.png'),
						require('@/assets/projects/agger/preview-2.png'),
						require('@/assets/projects/agger/preview-3.png')
					]
				},
				softcampaign: {
					name: 'Soft Campaign',
					preview: [
						require('@/assets/projects/softcampaign/preview-1.png'),
						require('@/assets/projects/softcampaign/dashboard.png'),
						require('@/assets/projects/softcampaign/preview-2.png'),
						require('@/assets/projects/softcampaign/preview-3.png'),
						require('@/assets/projects/softcampaign/preview-4.png'),
						require('@/assets/projects/softcampaign/preview-5.png')
					]
				},
				dashboard: {
					name: 'Data Dashboard',
					preview: [
						require('@/assets/projects/dashboard/1.png'),
						require('@/assets/projects/dashboard/2.png'),
						require('@/assets/projects/dashboard/3.png'),
						require('@/assets/projects/dashboard/4.png'),
						require('@/assets/projects/dashboard/5.png'),
						require('@/assets/projects/dashboard/6.png'),
						require('@/assets/projects/dashboard/7.png'),
						require('@/assets/projects/dashboard/8.png'),
						require('@/assets/projects/dashboard/9.png')
					]
				},
			}
		}
	},

	computed: {

		projectName() {

			return this.$route.params.project
		}
	}
}

</script>




<style lang="scss" scoped>

#project-content {

	width: 100%; max-width: 1200px;

	#project-name {
		margin-top: 50px;
		font-size: 100px; font-weight: bold;
		z-index: 2;
	}

	.project-preview {

		margin-bottom: 10px;
		border-radius: 20px;
		overflow: hidden;

		&:nth-child(2) {
			margin-top: -30px;
		}

		width: 100%; height: auto;
		background-size: contain;
		background-position: left top;
		background-repeat: no-repeat;

		img {
			width: 100%; height: auto; max-width: 1200px;
		}
	}

	.project-about {

		width: 300px;

		.project-about-icon {
			width: 50px; height: 50px;
			font-size: 40px; color: DeepSkyBlue;
		}

		.project-about-title {
			height: 50px;
			font-size: 26px; font-weight: 600; line-height: 50px;
		}

		.project-about-details {
			font-size: 18px; color: #999;
		}
	}
}

</style>
