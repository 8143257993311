<template>

	<div id="app">

		<router-view class="router-view"/>

		<transition>

			<div id="menu" class="box wide center" v-show="showMenu">

				<div id="menu-box" class="box wide tall">

					<div id="menu-photo-box" class="box top ml-10 mr-10 pt-50">

						<div class="menu-photo-wrap box margin-10" @click="go('Home')">
							<div class="menu-photo" :class="{ hidden: !showSundae }" :style="{ backgroundImage: `url( ${ sundaePhoto } )` }"></div>
							<div class="menu-photo" :class="{ hidden: showSundae }" :style="{ backgroundImage: `url( ${ profilePhoto } )` }"></div>
						</div>

						<div class="menu-contact-email box wide center margin-10 ion-ios-email-outline">byoulin@gmail.com</div>

					</div>

					<div id="menu-list" class="box fill top ml-10 mr-10">

						<div class="box wide">

							<div class="box vertical menu-item" @click="go('Home')">

								<div class="box wide">
									<div class="box menu-item-tag design">Home</div>
									<div class="box wide menu-item-title">Homepage</div>
								</div>

								<div class="box wide fill">
									<div
										id="design-home-image"
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ homeIcon } )`}"
									></div>
								</div>

							</div>

							<div class="box vertical menu-item" @click="go('Styleguide')">

								<div class="box wide">
									<div class="box menu-item-tag design">Design</div>
									<div class="box wide menu-item-title">Style Guide</div>
									<div class="box wide menu-item-subtitle">Common UI components</div>
								</div>

								<div class="box wide fill">
									<div
										id="design-components-image"
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ componentIcon } )`}"
									></div>
								</div>

							</div>

							<div class="box vertical menu-item" @click="go('CaseStudySelectFromALongList')">

								<div class="box wide">
									<div class="box menu-item-tag design">Design</div>
									<div class="box wide menu-item-title">Case Study</div>
									<div class="box wide menu-item-subtitle"></div>
								</div>

								<div class="box wide fill">
									<div
										id="design-case-study-image"
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ caseStudyIcon } )`}"
									></div>
								</div>

							</div>

							<div class="box vertical menu-item" @click="go('AnimationOnScroll')">

								<div class="box wide">
									<div class="box menu-item-tag design">Design</div>
									<div class="box wide menu-item-title">Animation on Scroll</div>
									<div class="box wide menu-item-subtitle">Scrolling effect demo</div>
								</div>

								<div class="box wide fill">
									<div
										id="design-animation-on-scroll-image"
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ scrollIcon } )`}"
									></div>
								</div>

							</div>

							<div class="box vertical menu-item dark" @click="go('Projects')">

								<div class="box wide">
									<div class="box menu-item-tag design">Design</div>
									<div class="box wide menu-item-title">Projects</div>
									<div class="box wide menu-item-subtitle"></div>
								</div>

								<div class="box wide fill">
									<div
										id="design-apps-websites"
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ aggerPhoto } )`}"
									></div>
								</div>

							</div>

							<div class="box vertical menu-item" @click="go('Graphics')">

								<div class="box wide">
									<div class="box menu-item-tag graphics">Graphics</div>
									<div class="box wide menu-item-title">Gallery</div>
									<div class="box wide menu-item-subtitle">Simple vector graphics</div>
								</div>

								<div class="box wide fill">
									<div
										id="graphics-gallery"
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ conanImage } )`}"
									></div>
								</div>

							</div>

							<div class="box vertical menu-item dark" @click="go('LogoEmotionalUniverse')">

								<div class="box wide">
									<div class="box menu-item-tag logo">Logo</div>
									<div class="box wide menu-item-title">Apple Club Logo</div>
									<div class="box wide menu-item-subtitle">Emotional Universe club</div>
								</div>

								<div class="box wide fill">
									<div
										class="box wide tall menu-item-main"
										:style="{ backgroundImage: `url( ${ universeLogo } )`}"
									></div>
								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</transition>

		<div id="menu-icon" :class="menuClass" @click="toggleMenu">
			<div class="mobile-menu-icon-line" id="mobile-menu-icon-line-1"></div>
			<div class="mobile-menu-icon-line" id="mobile-menu-icon-line-2"></div>
			<div class="mobile-menu-icon-line" id="mobile-menu-icon-line-3"></div>
		</div>

		<portal-target name="top-layer" multiple />

	</div>

</template>

<script>

export default {

	name: 'App',

	data() {

		return {
			showMenu: false,
			showSundae: false,
			showSwitch: 0,
			profilePhoto: require('@/assets/img/profile/youlin.jpg'),
			youlinPhoto: require('@/assets/img/profile/youlin.jpg'),
			sundaePhoto: require('@/assets/img/profile/sundae.jpg'),
			aggerPhoto: require('@/assets/img/agger.png'),
			conanImage: require('@/assets/img/conan.png'),
			universeLogo: require('@/assets/img/logo/emotional-universe.png'),
			homeIcon: require('@/assets/img/icon/home.svg'),
			componentIcon: require('@/assets/img/icon/component.svg'),
			caseStudyIcon: require('@/assets/img/icon/case-study.svg'),
			scrollIcon: require('@/assets/img/icon/scroll.svg'),
			design: [
				{
					name: 'Portfolio',
					route: 'Portfolio'
				},
				{
					name: 'Style Guide',
					route: 'Styleguide'
				},
				{
					name: 'Animation on Scroll',
					route: 'AnimationOnScroll'
				}
			],
			cases: [
				{
					name: 'Soap',
					route: 'CaseStudy'
				}
			]
		}
	},

	computed: {

		menuClass() {

			return (this.showMenu ? 'active' : '')
		}
	},

	methods: {

		toggleMenu() {

			this.showMenu = !this.showMenu

			if (this.showSwitch != 0)
				clearInterval(this.showSwitch)

			this.showSwitch = setInterval(() => this.showSundae = !this.showSundae, 3000)
		},

		go(routeName) {

			this.showMenu = false

			if (routeName == this.$route.name)
				return

			this.$router.push({

				name: routeName
			})

			window.scrollTo(0, 0)
		}
	}
}

</script>




<style lang="scss">

$mobile-width: 800px;
$animation-duration: 200ms;

html, body {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
}

* {
	box-sizing: border-box;
}

div, span {
	user-select: none;
	transition: all $animation-duration linear;
}

button {
	outline: none;
	border: none;
}


.screen {
	position: absolute; left: 0; top: 0;
	width: 100%; height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	text-align: center;
}


.box {

	position: relative;
	width: auto;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;

	&.wide {
		flex: 1 1 100%;
	}

	&.tall {
		align-self: stretch;
	}

	&.fill {
		flex: 1;
	}

	&.middle {
		justify-content: center;
	}

	&.stretch {
		align-self: stretch;
	}

	&.center {
		justify-content: center;
		align-items: center;
		align-content: center;
		text-align: center;
	}

	&.left {
		justify-content: flex-start;
		text-align: left;
	}

	&.right {
		justify-content: flex-end;
		text-align: right;
	}

	&.evenly {

		justify-content: space-evenly;

		&>.box {
			flex: 1;
		}
	}

	&.top {
		align-items: flex-start;
		align-content: flex-start;
	}

	&.bottom {
		align-items: flex-end;
		align-content: flex-end;
	}

	&.v-mid {
		align-items: center;
		align-content: center;
	}

	&.ellipsis {

		span {

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.nowrap {
		flex-wrap: nowrap;
	}

	&.m-wide {
		@media screen and (max-width: $mobile-width) {
			width: 100% !important;
			flex: auto !important;
		}
	}

	&.vertical {

		flex-direction: column;

		&>.box.wide {

			align-self: stretch;
			flex: none;
		}

		&>.box.fill {
			flex: 1;
		}

		&>.box.stretch {
			flex: 0 0 100%;
		}

		&.evenly {

			justify-content: space-evenly;

			&>.box {
				flex: 1;
			}
		}
	}
}



.br {
	position: relative;
	width: 100%;
}




/*

mt-5, mt-10, ... mt-300
pt-5, pt-10, ... pt-300

*/

$total: 60;
$n: 5;
$unit: 5px;

@for $i from 1 through $total {

	.margin-#{$i*$n} {
		margin: $i * $unit;
	}

	.mt-#{$i*$n} {
		margin-top: $i * $unit;
	}

	.mb-#{$i*$n} {
		margin-bottom: $i * $unit;
	}

	.ml-#{$i*$n} {
		margin-left: $i * $unit;
	}

	.mr-#{$i*$n} {
		margin-right: $i * $unit;
	}

	.padding-#{$i*$n} {
		padding: $i * $unit;
	}

	.pt-#{$i*$n} {
		padding-top: $i * $unit;
	}

	.pb-#{$i*$n} {
		padding-bottom: $i * $unit;
	}

	.pl-#{$i*$n} {
		padding-left: $i * $unit;
	}

	.pr-#{$i*$n} {
		padding-right: $i * $unit;
	}
}



/*

height-0, height-10, height-20, ... height-2000

*/

$total: 200;
$n: 10;
$unit: 10px;

@for $i from 0 through $total {

	.height-#{$i*$n} {
		height: $i * $unit !important;
	}

	.width-#{$i*$n} {
		width: $i * $unit !important;
	}

	.max-width-#{$i*$n} {
		width: 100%;
		max-width: $i * $unit !important;
	}

	.min-height-#{$i*$n} {
		min-height: $i * $unit !important;
	}

	.min-width-#{$i*$n} {
		min-width: $i * $unit !important;
	}

	.basis-#{$i*$n} {
		flex-basis: $i * $unit !important;
	}
}




/*

width-10, width-20, ... width-100

*/

$total: 10;
$n: 10;
$unit: 10%;

@for $i from 1 through $total {

	.width-pct-#{$i*$n} {
		width: $i * $unit !important;
	}
}




/*

font-1, font-2, ... font-50

*/

$total: 50;
$n: 1;
$unit: 1px;

@for $i from 1 through $total {

	.font-#{$i*$n} {
		font-size: $i * $unit;
	}
}



/*

grow-1, grow-2, ...
shrink-1, shrink-2, ...

*/

$total: 10;
$n: 1;

@for $i from 1 through $total {

	.grow-#{$i*$n} {
		flex-grow: $i;
	}

    .shrink-#{$i*$n} {
		flex-shrink: $i;
	}
}





#cache {
	position: absolute; left: 0; top: 0;
	width: 1px; height: 1px;
	overflow: hidden;
	z-index: 99;
}



.v-enter-active, .v-leave-active {
	transition: opacity 400ms linear;
}

.v-enter, .v-leave-to {
	opacity: 0;
}


.flip {

	width:200px; height:200px;
	background-color:transparent;
	display:inline-block;
	vertical-align:top;
	perspective:1000px;

	// &:hover .face, &.hover .face {
	// 	transform:rotateY(180deg);
	// }

	.face {
		position:relative;
		width:100%; height:100%;
		transition:0.6s;
		transform-style:preserve-3d;

		.front, .back {
			position:absolute; top:0; left:0;
			width:100%; height:100%;
			backface-visibility:hidden;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}
		/* front pane, placed above back */
		.front {
			z-index: 2;
			/* for firefox 31 */
			transform:rotateY(0deg);
			background-color: transparent;
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
		}
		/* back, initially hidden pane */
		.back {
			transform:rotateY(180deg);
			background-color: transparent;
			color:#000000; line-height:200px; font-size:12px;
			vertical-align:middle;
		}
			.back-text {
				width:180px;
				padding:10px;
				line-height:15px; text-align:center;
				vertical-align:middle;
				display:inline-block;
			}
	}
}

#menu-button-box {
	position: fixed; top: 20px; right: 14px;
	width: 60px; height: 42px;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 0px;
	z-index: 2;
}

#menu-icon {

	position: fixed; right: 20px; top: 20px;
	width: 50px; height: 50px;
	line-height: 50px; font-size: 40px; text-align: center; color: rgba(255, 255, 255, 1);
	text-shadow: 1px 1px #000;
	cursor: pointer;
	z-index: 11;

	.mobile-menu-icon-line {
		position: absolute; left: 50%;
		width: 40px; height: 2px;
		background-color: rgba(255, 255, 255, 1);
		transform: translateX(-50%);
		transition: transform 400ms cubic-bezier(0.17, 0.89, 0.42, 1.65);
		cursor: pointer;
	}

	#mobile-menu-icon-line-1 {
		top: 10px;
	}

	#mobile-menu-icon-line-2 {
		top: 20px;
	}

	#mobile-menu-icon-line-3 {
		top: 30px;
	}

	&.active {

		.mobile-menu-icon-line {
			background-color: rgba(0, 0, 0, 0.5);
		}

		#mobile-menu-icon-line-1 {
			transform: translateX(-50%) translateY(10px) rotate(45deg);
		}

		#mobile-menu-icon-line-2 {
			transform: translateX(-50%) scale(0.1);
		}

		#mobile-menu-icon-line-3 {
			transform: translateX(-50%) translateY(-10px) rotate(-45deg);
		}
	}
}


#menu-home {
	position: fixed; top: 80px; right: 25px;
	width: 40px; height: 40px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	opacity: 0.6;
	cursor: pointer;
	z-index: 2;
}


#menu {

	position: fixed; left: 0; top: 0;
	width: 100%; height: 100%;
	background-color: rgba(255, 255, 255, 0.95);
	overflow: scroll;
	z-index: 2;

	// .box { background-color: rgba(0, 0, 0, 0.2); }

	#menu-box {

		max-width: 1200px; max-height: 100%;

		#menu-photo-box {

			width: 200px;

			.menu-photo-wrap {
				position: relative;
				border-radius: 70px;
				width: 200px; height: 200px;
				overflow: hidden;

				.menu-photo {
					position: absolute; left: 0; top: 0;
					width: 100%; height: 100%;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
					transition: opacity 1200ms linear;
					opacity: 1;
					cursor: pointer;

					&.hidden {
						opacity: 0;
					}
				}
			}

			.menu-contact-email {

				user-select: all;

				&:before {
					margin-right: 5px;
					font-size: 24px;
				}
			}
		}

		@media screen and (max-width: 920px) {
			#menu-photo-box {
				width: 100%;
				justify-content: center;
			}
		}

		@media screen and (max-width: 590px) {

			#menu-photo-box {

				padding-left: 50px; padding-top: 20px; padding-right: 50px;

				.menu-photo-wrap {

					border-radius: 50px;
					width: 150px; height: 150px;
				}
			}

			#menu-list {
				padding-top: 10px !important;
			}
		}

		@media screen and (max-width: 480px) {

			#menu-photo-box {
				height: 220px;
			}
		}

		#menu-list {

			padding: 50px 60px 100px 60px;

			.menu-item {

				align-content: flex-start;
				margin: 10px;
				width: 260px;
				aspect-ratio: 3/4;
				background-color: #fff;
				background-image: linear-gradient(120deg, #fff 45%, #f5f5f5);
				border: 1px solid hsla(240deg, 6%, 83%, 0.63);
				border-radius: 20px;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				transition: all 400ms cubic-bezier(0.17, 0.89, 0.42, 1.65);
				overflow: hidden;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);
				}

				.menu-item-tag {

					font-size: 14px; color: white;
					padding: 5px 10px;
					border-radius: 20px;
					background: linear-gradient(167deg, rgba(100,230,136,1) 0%, rgba(29,195,224,1) 100%);
					margin: 20px 20px 15px 20px;

					&.design {
						background: linear-gradient(167deg, rgba(100,230,136,1) 0%, rgba(29,195,224,1) 100%);
					}

					&.graphics {
						background: linear-gradient(167deg, rgba(255,233,129,1) 0%, rgba(255,103,19,1) 100%);
					}

					&.logo {
						background: linear-gradient(167deg, rgba(50,173,244,1) 0%, rgba(140,129,255,1) 100%);
					}
				}

				.menu-item-title {
					font-size: 22px; text-align: left;
					margin-bottom: 5px;
					padding: 0 20px;
				}

				.menu-item-subtitle {
					font-size: 16px; text-align: left; color: rgba(0, 0, 0, 0.5);
					padding: 0 20px;
				}

				.menu-item-main {
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

			@media screen and (max-width: 920px) {
				.menu-item {
					width: 220px;
				}
			}

			@media screen and (max-width: 860px) {
				.menu-item {
					width: 200px;
				}
			}

			@media screen and (max-width: 800px) {

				.menu-item {
					width: calc(50% - 22px);
				}
			}

			@media screen and (max-width: 540px) {

				.menu-item {
					width: 100%;
				}
			}

			.menu-item.dark {

				background-color: #000;
				background-image: none;
				border: 1px solid #000;

				.menu-item-title {
					color: white;
				}

				.menu-item-subtitle {
					color: rgba(255, 255, 255, 0.5);
				}
			}

			#design-home-image {
				background-position: 10% 100%;
				background-size: 120%;
			}

			#design-components-image {
				background-position: 100% 0%;
				background-size: 160%;
			}

			#design-case-study-image {
				background-position: -10% -30%;
				background-size: 220%;
			}

			#design-apps-websites {
				background-position: center bottom;
				background-size: 140%;
			}

			#design-animation-on-scroll-image {
				background-size: 60%;
			}

			#graphics-gallery {
				background-position: center 30px;
			}
		}
	}
}

</style>
