<template>

	<div class="menu-icon box center" :class="active ? 'active': ''" @click="toggleMenu">
		<div class="menu-icon-line" id="menu-icon-line-1"></div>
		<div class="menu-icon-line" id="menu-icon-line-2"></div>
		<div class="menu-icon-line" id="menu-icon-line-3"></div>
	</div>

</template>

<script>

export default {

	name: "VueMenuIcon",

	props: ['status'],

	data() {
		return {
			active: false
		}
	},

	methods: {

		toggleMenu() {
			this.active = !this.active
			this.$emit('toggle', { value: this.active })
		}
	},

	watch: {
		status(oldVal, newVal) {
			this.active = newVal
		}
	}
}

</script>

<style lang="scss" scoped>

$menu-icon-color: $highlight-color;
$menu-icon-color-hover: lighten($highlight-color, 10%);

.menu-icon {

	position: relative;
	width: 50px; height: 40px;
	display: block;
	cursor: pointer;
	z-index: 10;

	.menu-icon-line {
		position: absolute; left: 50%;
		width: 30px; height: 2px;
		background-color: $menu-icon-color;
		transform: translateX(-50%);
		transition: transform 300ms linear, background-color 200ms linear;
		cursor: pointer;
	}

	#menu-icon-line-1 {
		top: 10px;
	}

	#menu-icon-line-2 {
		top: 18px;
	}

	#menu-icon-line-3 {
		top: 26px;
	}

	&.active {

		#menu-icon-line-1 {
			width: 25px;
			transform: translateX(-50%) translateY(2px) rotate(-30deg);
		}

		#menu-icon-line-2 {
			transform: translateX(-50%) scale(0.1);
		}

		#menu-icon-line-3 {
			width: 25px;
			transform: translateX(-50%) translateY(-2px) rotate(30deg);
		}
	}

	&:hover {

		.menu-icon-line {
			background-color: $menu-icon-color-hover;
		}
	}
}

$alt-menu-icon-color: $alt-highlight-color;
$alt-menu-icon-color-hover: darken($alt-highlight-color, 10%);

.theme-alt {

	.menu-icon {

		.menu-icon-line {

			background-color: $alt-menu-icon-color;
		}

		&:hover {

			.menu-icon-line {
				
				background-color: $alt-menu-icon-color-hover;
			}
		}
	}
}
</style>
