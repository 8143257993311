<template>

	<div
		class="label"
		:class="classNames"
	>

		<label
			:style="{
				fontSize: size ? size + 'px' : '24px'
			}"
		>
			{{ text }}

		</label>

	</div>

</template>

<script>

export default {

	name: "VueLabel",

	props: ['classes', 'text', 'size', 'wide'],

	data() {
		return {

		}
	},

	computed: {

		classNames() {
			return (
				this.classes
			)
		}
	},

	methods: {


	},

	mounted() {


	}
}
</script>


<style lang="scss" scoped>

.label {

	display: flex;

	&.wide {
		width: 100%;
	}

	&.center {
		justify-content: center;
	}
}

</style>
