<template>

	<div :class="classNames" class="pl-10 pr-10">

		<vue-input placeholder="Search" classes="cell" />

	</div>

</template>




<script>

export default {

	name: 'SearchBox',

	props: ['classes'],

	data() {

		return {
		}
	},

	computed: {

		classNames() {

			return 'search-box ' +
			(this.classes ? this.classes : '')
		}
	},

	methods: {


	}
}

</script>




<style lang="scss">

.search-box {
	position: relative;
	width: 100%; height: 40px;
	border-radius: 10px;
	background-color: #fff;

	.input-wrap {
		.input-box {
			border: none !important;
		}
	}
}

</style>
