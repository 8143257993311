
export function capitalize() {
    return this.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
}


export function random(length) {

	var text = ""

	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

	for (var i = 0; i < length; i++)
		text += possible.charAt(Math.floor(Math.random() * possible.length))

	return text
}


export function camelToKebab(string) {
	return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}


export function pascalToKebab(string) {
	return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
		.replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
		.toLowerCase()
}
