import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Styleguide from '@/views/design/Styleguide'
import AnimationOnScroll from '@/views/design/AnimationOnScroll'
import CaseStudy from '@/views/case-study/CaseStudy'
import CaseStudySelectFromALongList from '@/views/case-study/SelectFromALongList'
import Graphics from '@/views/graphics/Graphics'
import Projects from '@/views/projects/Projects'
import Project from '@/views/projects/Project'
import LogoEmotionalUniverse from '@/views/logo/EmotionalUniverse'

Vue.use(Router)



export default new Router({

    // mode: 'history',

    routes: [
        {
            path: '/',
            redirect: '/home'
        },
		{
            path: '/home',
            name: 'Home',
            component: Home
        },
		{
            path: '/projects',
            name: 'Projects',
            component: Projects
        },
		{
            path: '/projects/:project',
            name: 'Project',
            component: Project
        },
		{
            path: '/design/styleguide',
            name: 'Styleguide',
            component: Styleguide
        },
		{
            path: '/design/animation-on-scroll',
            name: 'AnimationOnScroll',
            component: AnimationOnScroll
        },
		{
            path: '/case-study',
            name: 'CaseStudy',
            component: CaseStudy
        },
		{
			path: '/case-study/select-from-a-long-list',
            name: 'CaseStudySelectFromALongList',
            component: CaseStudySelectFromALongList
		},
		{
            path: '/graphics',
            name: 'Graphics',
            component: Graphics
        },
		{
            path: '/logo/emotional-universe',
            name: 'LogoEmotionalUniverse',
            component: LogoEmotionalUniverse
        }
    ]
})
