var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen chocolates"},[_c('div',{style:(_vm.chocolateAnimationStyles),attrs:{"id":"animation-chocolate"}},[_c('div',{attrs:{"id":"chocolate-text"}},[_c('div',[_vm._v(" you ")]),_c('div',[_vm._v(" never ")]),_c('div',[_vm._v(" know ")]),_c('div',[_vm._v(" what ")]),_c('div',[_vm._v(" you ")]),_c('div',[_vm._v(" are ")]),_c('div',[_vm._v(" gonna ")]),_c('custom-button',{nativeOn:{"click":function($event){return _vm.get.apply(null, arguments)}}},[_vm._v("get")])],1),_c('div',{staticClass:"chocolate-wrap box fill"},[_c('div',{staticClass:"chocolate-box box wide tall",style:(_vm.chocolateBoxStyles)},[_c('div',{staticClass:"chocolate-circle",class:_vm.isRotating ? 'rotate' : '',style:(_vm.rotateStyle)},_vm._l((_vm.chocolates),function(item,index){return _c('div',{staticClass:"chocolate",style:({
							backgroundImage: `url(${ item.img })`,
							..._vm.chocolateStyles,
							..._vm.chocolatePositions[index]
						})})}),0),_c('div',{staticClass:"chocolate-pick",style:({
						backgroundImage	: `url(${ this.chocolatePickedImage })`,
						..._vm.chocolatePickedStyles
					})})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }