<template>

<div id="graphics">

	<div id="bg">

		<div
			v-for="item in bgs"
			:id="'bg-' + item.id"
			class="bg"
			:style="{ backgroundImage: `url(${ item.img })` }"
		>
		</div>

	</div>


	<div id="gallery">

		<div v-for="(item, index) in gallery" :id="index" class="gallery contain">
			<img loading="lazy" :src="item">
		</div>

	</div>

</div>

</template>

<script>

export default {

	name: 'Graphics',

	data() {

		return {

			bgs: [
				{
					id: "wonder-logo",
					img: require('@/assets/graphics/bg-wonder-logo.png')
				},
				{
					id: "haier-bros-ucl-2017",
					img: require('@/assets/graphics/bg-haier-bros-ucl-2017.jpg')
				},
				{
					id: "welcome-spring",
					img: require('@/assets/graphics/bg-welcome-spring.png')
				},
				{
					id: "trump",
					img: require('@/assets/graphics/bg-trump.png')
				},
				{
					id: "boeing-787",
					img: require('@/assets/graphics/bg-boeing-787.png')
				}
			],

			bg: {},

			gallery: {

				'wonder-logo': require('@/assets/graphics/wonder-logo.png'),
				'fathers-day': require('@/assets/graphics/fathers-day.jpg'),
				'iceland-flag': require('@/assets/graphics/iceland-flag.png'),
				'haier-bros-ucl-2017': require('@/assets/graphics/haier-bros-ucl-2017.png'),
				'lilyx': require('@/assets/graphics/lilyx.png'),
				'lilyx6': require('@/assets/graphics/lilyx6.png'),
				'colleen': require('@/assets/graphics/colleen.png'),
				'odette': require('@/assets/graphics/odette.png'),
				'welcome-spring': require('@/assets/graphics/welcome-spring.png'),
				'merante': require('@/assets/graphics/merante.png'),
				'colleen-profile': require('@/assets/graphics/colleen-profile.png'),
				'bernie': require('@/assets/graphics/bernie.png'),
				'trump': require('@/assets/graphics/trump.png'),
				'conan': require('@/assets/graphics/conan.png'),
				'boeing-787': require('@/assets/graphics/787.png'),
				'softcampaign-logo': require('@/assets/graphics/softcampaign-logo.png'),
				'klopp': require('@/assets/graphics/klopp.png'),
				'funny-soccer': require('@/assets/graphics/funny-soccer.png'),
				'juice-bottle': require('@/assets/graphics/juice-bottle.png'),
				'tissue': require('@/assets/graphics/tissue.png'),
				'jinan': require('@/assets/graphics/jinan.png'),
				'lfc-long': require('@/assets/graphics/lfc-long.jpg'),
				'lloyd': require('@/assets/graphics/lloyd.png'),
				'suarez': require('@/assets/graphics/suarez.png'),
				'xabi': require('@/assets/graphics/alonso.png')
			}
		}
	},

	mounted() {

		$(window).scroll(() => {

			this.scrolling()

			clearTimeout($.data(this, 'scrollTimer'));

		    $.data(this, 'scrollTimer', setTimeout(() => {

				// scrolling ends!

		    	this.scrolling()

		    }, 50))
		});
	},

	methods: {

		scrolling() {

			var scroll = $(window).scrollTop()

			var winh = $(window).height()
			var half = winh / 2

			// update every background's position
			for (var i in this.bgs) {

				var id = this.bgs[i].id

				if ($("#" + id).offset()) {

					this.bg[id] = {
						"top"	: $("#" + id).offset().top - scroll,
						"bottom": $("#" + id).offset().top + $("#" + id).height() - scroll,
						"line"	: $("#" + id).next().offset().top - scroll + 200
					}
				}
			}

			// only show backgrounds going in or out
			for (var id in this.bg) {

				if (this.bg[id]["top"] <= winh && this.bg[id]["bottom"] >= 0) {
					$("#bg-" + id).show()
				} else {
					$("#bg-" + id).hide()
				}

				if (this.bg[id]["line"] <= winh) {

					$("#bg-" + id).css({
						"height": this.bg[id]["line"] + "px"
					})

				} else {

					$("#bg-" + id).css({
						"height": winh + "px"
					})
				}
			}
		}
	}
}

</script>




<style lang="scss" scoped>

#bg {
	position:fixed; left:0px; top:0px;
	width:100%; height:100%;
	z-index:1;
}
	.bg {
		position:absolute; left:0px; top:0px;
		width:100%; height:100%;
	}
	#bg-wonder-logo {
		background-color: #382f5e;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		z-index: 102;
	}
	#bg-haier-bros-ucl-2017 {
		background-color: #382f5e;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		z-index: 101;
	}
	#bg-welcome-spring {
		background-color: #7AE6D4;
		background-repeat: no-repeat;
		background-size: 200px 200px;
		background-position: center center;
		z-index: 100;
	}
	#bg-trump {
		background-color:#83af93;
		background-repeat:repeat;
		background-size:200px 129px;
		z-index: 99;
	}
	#bg-boeing-787 {
		background-color: #22ACFF;
		background-repeat: no-repeat;
		background-position: left center;
		background-size: contain;
		z-index: 98;
	}



#gallery {
	position:absolute; left:0px; top:0px;
	width:100%; height:100%;
	z-index:2;
}
	.gallery {
		position:relative; left:0px; top:0px;
		width:100%; height:auto; min-height: 300px;
		background-position:center center;
		background-size:cover;
		background-repeat:no-repeat;
	}
	.gallery.contain {
		background-size:contain;
	}
		.gallery img {
			margin-left: auto; margin-right: auto;
			width: auto; max-width: 100%; height: auto; max-height:600px;
			display: block;
		}
		@media (max-width: 800px) {
			.gallery img {
				width: 100%; height: auto;
			}
		}



	#lilyx {
		background-color: #c2b49a;
	}
	#lilyx6 {
		background-color: #31dfa0;
	}

	#colleen-profile {
		background-color: #ffcf8f;
	}

	#colleen {
		background-color: #177391;
	}

	#wonder-logo {
		background-color: transparent;
	}

	#fathers-day {
		background-color: #000;
	}

	#iceland-flag {
		background-color: #eee;
	}

	#welcome-spring {
		background-color: transparent;
	}

	#odette {
		background-color:#FFFFFF;
	}

	#merante {
		background-color: #eeeeee;
	}

	#bernie {
		background-color:#38C3FF;
	}

	#trump {
		background-color: transparent;
	}
	#conan {
		background-color:#FAA300;
	}

	#boeing-787 {
		background-color: transparent;
	}

	#softcampaign-logo {
		background-color: #ffd200;
	}

	#klopp {
		background-color:#CB0000;
	}

	#funny-soccer {
		background-color:#78D9FF;
	}

	#jinan {
		background-color:#A3E5FF;
	}

	#lfc-long {
		background-color:#38733E;
	}

	#lloyd {
		background-color:#806AB8;
	}

	#suarez {
		background-color:#2B7223;
	}

	#xabi {
		background-color:#46A39A;
	}

	#grs-office {
		background-color:#968B64;
	}

	#sultan {
		background-color:#175627;
	}

	#juice-bottle {
		background-color: #b3e300;
	}

	#tissue {
		background-color: #1bccfa;
	}


</style>
