<template>

	<div class="screen probably">

		<div id="animation-life" v-if="showLife">

			<div id="animation-life-sky" :style="skyStyles"></div>

			<div id="animation-life-ground" :style="groundStyles"></div>

			<div id="animation-life-bench" :style="benchStyles"></div>

			<transition>

				<div id="animation-life-text" class="animation-text box center" v-if="showText">
					Life is like a box of chocolates
				</div>

			</transition>

		</div>

		<div id="probably" :style="probablyStyles">

			<div class="flip">

				<div class="face" :style="faceStyles">

					<div class="front" :style="{ backgroundImage: `url(${ probablyImg })` }"></div>

					<div class="back" :style="{ backgroundImage: `url(${ probablyImg })` }">
						<div class="back-text"></div>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Probably',

	props: ['config'],

	data() {

		return {
			probablyImg: require('@/assets/img/probably.svg'),
			probablyStyles: {
				width: '100px',
				height: '100px',
				opacity: 0
			},
			faceStyles: {
				marginTop: '0px',
				transform: 'rotateX(0deg) translateZ(0)'
			},
			showLife: false,
			skyStyles: {
				height: '0%',
				opacity: 0
			},
			groundStyles: {
				top: '100%',
				opacity: 0
			},
			benchImg: require('@/assets/img/bench.svg'),
			benchStyles: {
				marginTop: '5px',
				opacity	: 0
			},
			showText: false
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				// enlarge Probably

				let winw = $(window).width()
				let winh = $(window).height()
				let size = winw < winh ? winw : winh
				size *= 0.6

				this.probablyStyles = {
					width: size + 'px',
					height: size + 'px',
					opacity: 1
				}

			} else if (step == 2) {

				// transform Probably
				// slide in sky & ground

				this.faceStyles = {
					marginTop: '80px',
					transform: 'rotateX(420deg) translateZ(0)'
				}

				this.showLife = true

				setTimeout(() => {

					this.skyStyles = {
						height: '50%',
						opacity: 1
					}

					this.groundStyles = {
						top: '50%',
						opacity: 1
					}
				})

			} else if (step == 3) {

				// reveal bench
				// show text

				this.benchStyles = {
					backgroundImage: `url(${ this.benchImg })`,
					marginTop: '-55px',
					opacity	: 1
				}

				setTimeout(() => this.showText = true, 1000)

			} else if (step == 4) {

				// move bench
				// hide text
				// slide out sky & ground
				// restore Probably

				this.showText = false

				this.benchStyles = {
					backgroundImage: `url(${ this.benchImg })`,
					marginTop: '-55px',
					opacity	: 0,
					transform: 'translate(500%, -500%) rotate(500deg)'
				}

				setTimeout(() => {

					this.skyStyles = {
						height: '0%',
						opacity: 0
					}

					this.groundStyles = {
						top: '100%',
						opacity: 0
					}

					this.faceStyles = {
						marginTop: '0px',
						transform: 'rotateX(0deg) translateZ(0)'
					}

				}, 500)
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.probablyStyles = {
					width: '100px',
					height: '100px',
					opacity: 0
				}

			} else if (step == 2) {

				let winw = $(window).width()
				let winh = $(window).height()
				let size = winw < winh ? winw : winh
				size *= 0.6

				this.probablyStyles = {
					width: size + 'px',
					height: size + 'px',
					opacity: 1
				}

				this.faceStyles = {
					marginTop: '0px',
					transform: 'rotateX(0deg) translateZ(0)'
				}

				this.skyStyles = {
					height: '0%',
					opacity: 0
				}

				this.groundStyles = {
					top: '100%',
					opacity: 0
				}


			} else if (step == 3) {

				this.showText = false

				setTimeout(() => {

					this.benchStyles = {
						backgroundImage: `url(${ this.benchImg })`,
						marginTop: '5px',
						opacity	: 0
					}

				}, 500)

			} else if (step == 4) {

				this.showLife = true

				this.benchStyles = {
					backgroundImage: `url(${ this.benchImg })`,
					marginTop: '-55px',
					opacity	: 0,
					transform: 'translate(500%, -500%) rotate(500deg)'
				}

				let winw = $(window).width()
				let winh = $(window).height()
				let size = winw < winh ? winw : winh
				size *= 0.6

				this.probablyStyles = {
					width: size + 'px',
					height: size + 'px',
					opacity: 1
				}

				this.faceStyles = {
					marginTop: '0px',
					transform: 'rotateX(0deg) translateZ(0)'
				}
				
				setTimeout(() => {

					this.showText = true

					this.skyStyles = {
						height: '50%',
						opacity: 1
					}

					this.groundStyles = {
						top: '50%',
						opacity: 1
					}

					this.faceStyles = {
						marginTop: '80px',
						transform: 'rotateX(420deg) translateZ(0)'
					}

					setTimeout(() => {

						this.benchStyles = {
							backgroundImage: `url(${ this.benchImg })`,
							marginTop: '-55px',
							opacity	: 1,
							transform: 'translate(-50%, 0%) rotate(0deg)'
						}

					}, 1000)
				})

			}
		}
	}
}

</script>




<style lang="scss" scoped>

.probably {

	background-color: teal;

	#probably {

		position: absolute; left: 50%; top: 50%;
		width: 100px; height: 100px;
		transition: all 1s linear;
		transform: translate(-50%, -50%);
		opacity: 0;
		z-index: 4;

		.flip {

			width: 100%; height: 100%;
			perspective: 1000px;

			.face {
				height: 100%;
				transition: all 2s linear;

				.front {
					background-size: contain;
					background-repeat: no-repeat;
				}

				.back {
					background-size: contain;
					background-repeat: no-repeat;
					transform: rotateY(180deg) scaleX(-1);
				}
			}
		}
	}

	#animation-life-sky {
		position: absolute; left: 0%; top: 0%;
		width: 100%; height: 0%;
		transition: all 1s linear;
		background: linear-gradient(DeepSkyBlue, LightSkyBlue);
		z-index: 1;
	}

	#animation-life-ground {
		position: absolute; left: 0%; top: 100%;
		width: 100%; height: 50%;
		transition: all 1s linear;
		background: linear-gradient(MediumSeaGreen, SeaGreen);
		z-index: 3;
	}

	#animation-life-bench {
		position: absolute; left: 50%; top: 50%; margin-top: 5px;
		width: 150px; height: 60px;
		transform: translateX(-50%);
		transition: all 1s linear;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		transform-origin: center;
		z-index: 2;
	}

	#animation-life-text {
		position: absolute; left: 0; top: 0%;
		width: 100%; height: 40%;
		color: #fff;
		z-index: 4;
	}
}

.flip {

	width:200px; height:200px;
	background-color:transparent;
	display:inline-block;
	vertical-align:top;
	perspective:1000px;

	// &:hover .face, &.hover .face {
	// 	transform:rotateY(180deg);
	// }

	.face {
		position:relative;
		width:100%; height:100%;
		transition:0.6s;
		transform-style:preserve-3d;

		.front, .back {
			position:absolute; top:0; left:0;
			width:100%; height:100%;
			backface-visibility:hidden;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}
		/* front pane, placed above back */
		.front {
			z-index: 2;
			/* for firefox 31 */
			transform:rotateY(0deg);
			background-color: transparent;
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
		}
		/* back, initially hidden pane */
		.back {
			transform:rotateY(180deg);
			background-color: transparent;
			color:#000000; line-height:200px; font-size:12px;
			vertical-align:middle;
		}
			.back-text {
				width:180px;
				padding:10px;
				line-height:15px; text-align:center;
				vertical-align:middle;
				display:inline-block;
			}
	}
}

</style>
