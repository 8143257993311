<template>

	<div class="box wide center">

		<div id="menu-button-box"></div>

		<div id="projects-content" class="box">

			<div id="projects-title" class="box wide">Projects</div>

			<div
				v-for="item in projects"
				class="box wide project"
				:style="{ color: item.color }"
				@click="go(item.route)"
			>

				<div
					class="project-background"
					:style="{
						backgroundColor: item.backgroundColor,
						backgroundImage: `url(${ item.cover })`,
						backgroundPosition: item.backgroundPosition ? item.backgroundPosition : 'center center',
						backgroundSize: item.backgroundSize ? item.backgroundSize : 'cover'
					}"
				></div>

				<div class="project-text">

					<div class="project-title">{{ item.name }}</div>

					<div class="project-subtitle">{{ item.intro }}</div>
				</div>

			</div>

		</div>

	</div>

</template>

<script>

export default {

	name: 'Projects',

	data() {
		return {
			projects: [
				{
					name: 'Soft Campaign',
					route: 'softcampaign',
					color: '#000',
					backgroundColor: 'gold',
					backgroundPosition: 'right center',
					backgroundSize: '50%',
					cover: require('@/assets/projects/softcampaign/cover.png'),
					intro: 'An app for businesses to create and manage promotional campaigns'
				},
				{
					name: 'Data Dashboard',
					route: 'dashboard',
					color: '#000',
					backgroundColor: '#f1f3f6',
					backgroundPosition: 'right center',
					backgroundSize: 'cover',
					cover: require('@/assets/projects/dashboard/cover.png'),
					intro: 'Various dashboard designs'
				},
				{
					name: 'The Agger Foundation',
					route: 'agger',
					color: '#fff',
					backgroundColor: '#000',
					backgroundPosition: 'right center',
					backgroundSize: 'contain',
					cover: require('@/assets/projects/agger/cover.png'),
					intro: 'Official website for former Denmark national team captain & Liverpool player Daniel Agger'
				},
				{
					name: 'Chuange',
					route: 'http://youlin.me/projects/chuange',
					color: '#000',
					backgroundColor: '#ccc',
					cover: require('@/assets/projects/chuange/cover.png'),
					intro: 'Official website for a famous Chinese dumpling restaurant in Qingdao'
				},
				{
					name: 'Projet 4',
					route: 'http://youlin.me/projects/project4',
					color: '#fff',
					backgroundColor: '#000',
					backgroundPosition: 'right center',
					backgroundSize: 'contain',
					cover: require('@/assets/projects/project4/cover.jpg'),
					intro: 'Website design for a Danish fashion store in Copenhagen'
				}
			]
		}
	},

	methods: {

		go(project) {

			if (project.indexOf('http://') >= 0) {

				window.open(project, '_blank')

			} else {

				this.$router.push({

					name: 'Project',

					params: { project }
				})
			}
		}
	}
}

</script>




<style lang="scss" scoped>

#projects-content {

	width: 100%; max-width: 1200px;
	padding-bottom: 100px;

	#projects-title {
		margin-top: 50px;
		font-size: 160px; font-weight: bold;
		z-index: 2;
	}

	@media screen and (max-width: 640px) {
		#projects-title {
			font-size: 120px;
		}
	}

	@media screen and (max-width: 480px) {
		#projects-title {
			font-size: 80px;
		}
	}

	@media screen and (max-width: 320px) {
		#projects-title {
			font-size: 60px;
		}
	}

	.project {
		background: #ccc;
		height: 400px;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		overflow: hidden;
		z-index: 1;

		&:nth-child(2) {
			margin-top: -72px;
		}

		@media screen and (max-width: 640px) {
			&:nth-child(2) {
				margin-top: -30px;
			}
		}

		.project-background {
			position: absolute; left: 0; top: 0;
			width: 100%; height: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			transition: transform 400ms linear;
		}

		.project-text {

			position: absolute; left: 50px; top: 100px;
			transition: all 400ms linear;
			max-width: 40%;

			.project-title {
				width: 100%;
				font-size: 50px; text-align: left;
			}

			.project-subtitle {
				margin-top: 20px;
				font-size: 22px; text-align: left;
				opacity: 0.5;
			}

			@media screen and (max-width: 640px) {
				.project-title {
					font-size: 30px;
				}

				.project-subtitle {
					font-size: 16px;
				}
			}
		}

		&:hover {

			.project-background {
				transform: scale(1.05);
			}

			.project-text {

				left: 40px; top: 80px;
			}
		}
	}
}

</style>
