<template>

	<div class="screen lfc">

		<div id="animation-klopp" :style="kloppStyles" v-if="showKlopp"></div>

		<transition>

			<div class="animation-text light box tall center" :style="textStyle">

				<transition>
					<div id="lfc-text-1" class="box wide center lfc-text lfc-text-1" :style="lfcTextStyle1">
						<span ref="lfcText1Span">a LFC follower by dawn</span>
					</div>
				</transition>

					<br/>

				<transition>
					<div id="lfc-text-2" class="box wide center mt-5 mb-5 lfc-text lfc-text-2" :style="lfcTextStyle2">
						<span ref="lfcText2Span">and this year is our year</span>
					</div>
				</transition>

					<br/>

				<transition>

					<div class="box wide center mt-20">

						<div id="lfc-text-3" class="box wide center font-fade lfc-text lfc-text-3" :style="lfcTextStyle3">
							<span ref="lfcText3Span">and I just want $10M</span>
						</div>

						<div id="lfc-text-4" class="box wide center mt-10 lfc-text lfc-text-4" :style="lfcTextStyle4">
							<span class="font-small font-fade" ref="lfcText4Span">
								*this line was added since Liverpool really won the league after 30 years
							</span>
						</div>

					</div>

				</transition>

			</div>

		</transition>

	</div>

</template>




<script>

export default {

	name: 'LFC',

	props: ['config'],

	data() {

		return {
			lfcTextStyle1: {},
			lfcTextStyle2: {},
			lfcTextStyle3: {},
			lfcTextStyle4: {},
			textStyle: {},
			klopp: require('@/assets/img/klopp.svg'),
			showKlopp: false,
			kloppStyles: {
				backgroundImage: `url(${ this.klopp })`,
				opacity: 0,
				transform: 'scale(1)'
			},
			resizing: 0
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		}
	},

	mounted() {

		window.addEventListener("resize", this.resize)

		this.kloppStyles = {
			...this.kloppStyles,
			backgroundImage: `url(${ this.klopp })`
		}

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			let lfcTextTransition = 'all 500ms cubic-bezier(0.17, 0.89, 0.42, 1.65)'

			if (step == 1) {

				this.lfcTextStyle1 = {
					height: 0,
					transition: 'none'
				}

				this.lfcTextStyle2 = {
					height: 0,
					transition: 'none'
				}

				this.lfcTextStyle3 = {
					height: 0,
					transition: 'none'
				}

				this.lfcTextStyle4 = {
					height: 0,
					transition: 'none'
				}

				this.lfcTextStyle1 = {
					height: this.$refs.lfcText1Span.clientHeight + 'px',
					transition: lfcTextTransition
				}

				setTimeout(() => {

					this.lfcTextStyle2 = {
						height: this.$refs.lfcText2Span.clientHeight + 'px',
						transition: lfcTextTransition
					}

				}, 1000)

				setTimeout(() => {

					this.lfcTextStyle3 = {
						height: this.$refs.lfcText3Span.clientHeight + 'px',
						transition: lfcTextTransition
					}

				}, 2000)

				setTimeout(() => {

					this.lfcTextStyle4 = {
						height: this.$refs.lfcText4Span.clientHeight + 'px',
						transition: lfcTextTransition
					}

				}, 2600)

			} else if (step == 2) {

				this.textStyle = {
					opacity		: 0.5,
					transform	: 'scale(0.2) translateY(200px)',
					transition: 'all 2s linear'
				}

				this.showKlopp = true

				this.kloppStyles = {
					...this.kloppStyles,
					opacity: 0,
					transform: 'scale(1)'
				}

				setTimeout(() => {

					let winw = $(window).width()
					let winh = $(window).height()
					let scale = winw > winh ? winw : winh
					scale /= 200

					this.kloppStyles = {
						...this.kloppStyles,
						opacity		: 1,
						transform	: 'scale(' + scale + ')'
					}
				})

			} else if (step == 3) {

				this.textStyle = {
					opacity		: 0,
					transform	: 'scale(0.2) translateY(200px)',
					transition: 'all 2s linear'
				}

				let winw = $(window).width()
				let winh = $(window).height()
				let scale = winw > winh ? winw : winh
				scale /= 100
				scale *= 15

				this.kloppStyles = {
					...this.kloppStyles,
					opacity		: 1,
					transform	: 'scale(' + scale + ')'
				}

			}
		},

		reverseAnimation(step) {

			let lfcTextTransition = 'all 500ms cubic-bezier(.37,-0.21,.67,-0.59)'

			if (step == 1) {

				this.lfcTextStyle1 = {
					height: this.$refs.lfcText1Span.clientHeight + 'px',
					transition: 'none'
				}

				this.lfcTextStyle2 = {
					height: this.$refs.lfcText2Span.clientHeight + 'px',
					transition: 'none'
				}

				this.lfcTextStyle3 = {
					height: this.$refs.lfcText3Span.clientHeight + 'px',
					transition: 'none'
				}

				this.lfcTextStyle4 = {
					height: 0,
					transition: lfcTextTransition
				}

				setTimeout(() => {

					this.lfcTextStyle3 = {
						height: 0,
						transition: lfcTextTransition
					}

				}, 600)

				setTimeout(() => {

					this.lfcTextStyle2 = {
						height: 0,
						transition: lfcTextTransition
					}

				}, 1600)

				setTimeout(() => {

					this.lfcTextStyle1 = {
						height: 0,
						transition: lfcTextTransition
					}

				}, 2600)

			} else if (step == 2) {

				let winw = $(window).width()
				let winh = $(window).height()
				let scale = winw > winh ? winw : winh
				scale /= 200

				this.kloppStyles = {
					...this.kloppStyles,
					opacity		: 1,
					transform	: 'scale(' + scale + ')',
					transition	: 'none'
				}

				setTimeout(() => {

					this.kloppStyles = {
						...this.kloppStyles,
						opacity		: 0,
						transform	: 'scale(0.1)',
						transition	: 'all 2s linear'
					}
				})

				setTimeout(() => {

					this.showKlopp = false

				}, 2000)

				setTimeout(() => {

					this.textStyle = {
						opacity		: 0.5,
						transform	: 'scale(0.2) translateY(200px)',
						transition: 'all 2s linear'
					}

					setTimeout(() => {

						this.textStyle = {
							opacity		: 1,
							transform	: 'scale(1) translateY(0px)',
							transition: 'all 2s linear'
						}
					})

				}, 500)

			} else if (step == 3) {

				this.textStyle = {
					opacity		: 0,
					transform	: 'scale(0.01) translateY(200px)',
					transition: 'all 2s linear'
				}

				setTimeout(() => {

					this.textStyle = {
						opacity		: 0.5,
						transform	: 'scale(0.2) translateY(200px)',
						transition: 'all 2s linear'
					}
				})

				this.showKlopp = true

				let winw = $(window).width()
				let winh = $(window).height()
				let scale = winw > winh ? winw : winh
				scale /= 100
				scale *= 15

				this.kloppStyles = {
					...this.kloppStyles,
					opacity		: 1,
					transform	: 'scale(' + scale + ')'
				}

				setTimeout(() => {

					scale = winw > winh ? winw : winh
					scale /= 200

					this.kloppStyles = {
						...this.kloppStyles,
						opacity		: 1,
						transform	: 'scale(' + scale + ')'
					}
				})
			}
		},

		resize() {

			this.textStyle = { transition: 'all 200ms linear' }

			clearTimeout(this.resizing)

			this.resizing = setTimeout(() => {

				this.lfcTextStyle1 = {
					height: this.$refs.lfcText1Span.clientHeight + 'px',
					transition: 'all 200ms linear'
				}

				this.lfcTextStyle2 = {
					height: this.$refs.lfcText2Span.clientHeight + 'px',
					transition: 'all 200ms linear'
				}

				this.lfcTextStyle3 = {
					height: this.$refs.lfcText3Span.clientHeight + 'px',
					transition: 'all 200ms linear'
				}

				this.lfcTextStyle4 = {
					height: this.$refs.lfcText4Span.clientHeight + 'px',
					transition: 'all 200ms linear'
				}

			}, 1000)
		}
	}
}

</script>




<style lang="scss" scoped>

.lfc {

	background-color: #ca2d25;

	.animation-text {

		transition: all 2s linear;
	}
}

.lfc-text {
	overflow: hidden;
}

#animation-klopp {
	position: absolute; left: 50%; top: 50%; margin-left: -50px; margin-top: -50px;
	width: 100px; height: 100px;
	transform-origin: 45px 80px;
	transform: scale(1);
	transition: all 2s linear;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0;
}

</style>
