<template>

	<div class="screen night">

		<div
			id="animation-night"
			class="animation"
			:style="animationStyles"
		></div>

		<div class="animation-text light box tall center">

			<div class="box center" ref="textWrap">

				<div class="box">a</div>

				<div class="box wide height-10" :class="{ hidden: hideBreak }"></div>

				<div
					class="box center dynamic-island"
					:class="dynamicIslandClass"
					ref="dynamicIsland"
					@mouseover="dynamicHover"
					:style="{
						width: dynamicIslandWidth,
						backgroundColor: dynamicIslandColor
					}"
				>
					<span ref="dynamicIslandContent" style="white-space: nowrap;">Dynamic Critic™</span>
				</div>

				<div class="box wide height-10" :class="{ hidden: hideBreak }"></div>

				<div class="box">of <span id="apple"></span> by night</div>

			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Night',

	props: ['config'],

	data() {

		return {
			animationStyles: {
				opacity		: 0.3,
				top			: $(window).height() + 'px',
				transform	: 'rotate(0deg)'
			},
			dynamicIslandWidth: 'auto',
			dynamicIslandColor: 'rgba(0, 0, 0, 0.1)',
			hideBreak: 'none',
			dynamicIslandClass: 'disabled',
			resizing: 0
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		}
	},

	mounted() {

		window.addEventListener("resize", this.resize)

		if (this.$refs.textWrap.clientWidth + 280 > window.innerWidth)
			this.hideBreak = false
		else
			this.hideBreak = true

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				this.dynamicIslandClass = 'disabled'

				this.animationStyles = {
					opacity		: 0.1,
					top			: $(window).height() + 'px',
					transform	: 'rotate(0deg)'
				}

				setTimeout(() => {

					this.animationStyles = {
						opacity		: 1,
						top			: '20px',
						transform	: 'rotate(220deg)'
					}
				})

				this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 50 + 'px'

				setTimeout(() => {

					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 100 + 'px'
					this.dynamicIslandColor = 'rgba(0, 0, 0, 0.5)'

				}, 400)

				setTimeout(() => {

					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 50 + 'px'

				}, 1200)

				setTimeout(() => this.dynamicIslandClass = '', 2000)

			} else if (step == 2) {

				this.dynamicIslandClass = 'disabled'

				this.animationStyles = {
					opacity		: 1,
					top			: '-50px',
					transform	: 'rotate(440deg)'
				}

				this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 40 + 'px'

				setTimeout(() => {

					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 80 + 'px'

				}, 800)

				setTimeout(() => this.dynamicIslandClass = '', 1600)
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.dynamicIslandClass = 'disabled'

				this.animationStyles = {
					opacity		: 0.1,
					top			: $(window).height() + 'px',
					transform	: 'rotate(0deg)'
				}

				this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 40 + 'px'

				setTimeout(() => {

					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 80 + 'px'

				}, 800)

				setTimeout(() => this.dynamicIslandClass = '', 1600)

			} else if (step == 2) {

				this.dynamicIslandClass = 'disabled'

				this.animationStyles = {
					opacity		: 1,
					top			: '-50px',
					transform	: 'rotate(440deg)'
				}

				setTimeout(() => {

					this.animationStyles = {
						opacity		: 1,
						top			: '20px',
						transform	: 'rotate(220deg)'
					}
				})

				this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 'px'

				setTimeout(() => {

					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 100 + 'px'
					this.dynamicIslandColor = 'rgba(0, 0, 0, 0.5)'

				}, 400)

				setTimeout(() => {

					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 50 + 'px'

				}, 1200)

				setTimeout(() => this.dynamicIslandClass = '', 2000)
			}
		},

		resize() {

			clearTimeout(this.resizing)

			this.resizing = setTimeout(() => {

				if (this.$refs.dynamicIslandContent)
					this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 50 + 'px'

				setTimeout(() => {

					if (this.$refs.dynamicIslandContent)
						this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 50 + 'px'

					if (this.$refs.textWrap.clientWidth + 280 > window.innerWidth)
						this.hideBreak = false
					else
						this.hideBreak = true

				}, 900)

			}, 900)
		},

		dynamicHover() {

			if (this.$refs.dynamicIsland.classList.contains('disabled'))
				return

			this.dynamicIslandClass = 'hover disabled'

			this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 100 + 'px'

			setTimeout(() => {

				this.dynamicIslandWidth = this.$refs.dynamicIslandContent.clientWidth + 50 + 'px'

			}, 800)

			setTimeout(() => this.dynamicIslandClass = '', 1600)
		}
	}
}

</script>




<style lang="scss" scoped>

.night {

	background-color: Indigo;

	#animation-night {
		position: absolute; left: 30%; top: 150px;
		width: 200px; height: 200px;
		border-radius: 50%;
		box-shadow: inset 20px 0px gold, inset 20px 0px gold;
		transform: rotate(0deg);
		transition: all 1900ms linear, opacity 1s linear;
		opacity: 0;
		z-index: 0;
	}

	.dynamic-island {

		margin: 0 10px;
		padding: 10px;
		border-radius: 50px;
		background-color: rgba(0, 0, 0, 0.1);
		transition: all 800ms cubic-bezier(0.17, 0.89, 0.42, 1.65);

		&.expand {

			background-color: rgba(0, 0, 0, 1);
		}
	}
}

#apple {
	margin-left: 1rem; margin-right: 1rem;
	font-size: 5rem;
}

.hidden {
	display: none;
}

</style>
