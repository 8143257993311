<template>

	<div class="tabs box">

		<div v-for="(item, index) in tabs" :class="'box tab ' + (active === index ? 'active' : '')">

			<button @click="onChange(item, index)">{{ item }}</button>

		</div>

	</div>

</template>

<script>

export default {

	name: "VueTab",

	props: ["tabs"],

	data() {
		return {
			active: 0
		}
	},

	methods: {

		onChange(item, index) {

			this.active = index
		}
	},

	mounted() {

	}
}

</script>

<style lang="scss" scoped>

.tabs {

	.tab {

		margin: 0 20px;
		cursor: pointer;

		button {

			background-color: transparent;
			color: $text-color;
			font-size: 22px;
			height: 50px;
			line-height: 50px;
			cursor: pointer;
		}

		&.active {

			button {

				color: $theme-color;
				border-bottom: 2px solid $theme-color;
			}
		}

		&:hover {

			button {

				color: $theme-color;
			}
		}
	}
}

.theme-alt {

	.tabs {

		.tab {

			button {

				color: $alt-text-color;
			}

			&.active {

				button {

					color: $alt-theme-color;
					border-bottom: 2px solid $alt-theme-color;
				}
			}

			&:hover {

				button {

					color: $alt-theme-color;
				}
			}
		}
	}
}

</style>
