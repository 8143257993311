function PALETTE() {
}




PALETTE.prototype.rand = function(brightness) {

	var randomColour = ""

	var r = Math.round(255 * Math.random());
	var g = Math.round(255 * Math.random());
	var b = Math.round(255 * Math.random());

	var c = "rgb(" + r + ", " + g + ", " + b + ")";

	if (brightness == null)
		randomColour = c;

	if (brightness == "light") {

		if (this.getBrightness(c) < 180) {
			this.rand("light");
		} else {
			randomLightColour = c;
		}

	} else if (brightness == "dark") {

		if (this.getBrightness(c) > 70) {
			this.rand("dark");
		} else {
			randomLightColour = c;
		}
	}

	return randomColour
}




PALETTE.prototype.getBG = function(hex) {
	return (hex.charAt(0)=="#") ? hex.substring(1,7):hex;
}

PALETTE.prototype.hexToR = function(hex) {
	let c = (hex.charAt(0) == "#") ? hex.substring(1, 7) : hex
	return parseInt(c.substring(0,2),16);
}

PALETTE.prototype.hexToG = function(hex) {
	let c = (hex.charAt(0) == "#") ? hex.substring(1, 7) : hex
	return parseInt(c.substring(2,4),16);
}

PALETTE.prototype.hexToB = function(hex) {
	let c = (hex.charAt(0) == "#") ? hex.substring(1, 7) : hex
	return parseInt(c.substring(4,6),16);
}




PALETTE.prototype.isRGB = function(c) {

	if (c.indexOf("rgb") >= 0)
		return true;

	return false;
}




PALETTE.prototype.getBrightness = function(color) {


	//This method will return a number in the range of 0 (dark) to 255 (light)

	var R, G, B;

	if (this.isRGB(color)) {

		var a = color.split("(");
		a = a[1].split(")");
		a = a[0].split(",");

		R = parseInt(a[0]);
		G = parseInt(a[1]);
		B = parseInt(a[2]);

	} else {

		R = this.hexToR(color);
		G = this.hexToG(color);
		B = this.hexToB(color);
	}

	//this.brightness = Math.sqrt(0.241*R*R + 0.691*G*G + 0.068*B*B);
	return 0.299*R + 0.587*G + 0.114*B;
}



PALETTE.prototype.isDark = function(c) {

	if (this.getBrightness(c) < 128)
		return true;

	return false;
}






PALETTE.prototype.autoShade = function(c, amount) {

	if (this.isDark(c)) {

		// lighten this color
		return this.shade(c, amount);

	} else {

		// darken this color
		return this.shade(c, -amount);
	}
}




PALETTE.prototype.autoBW = function(c, opacity) {

	if (this.isDark(c)) {

		// white
		return 'rgba(255, 255, 255, ' + opacity + ')'

	} else {
		
		// black
		return 'rgba(0, 0, 0, ' + opacity + ')'
	}
}





PALETTE.prototype.RGBtoHEX = function(rgb) {
	// rgb(0, 0, 0)
	var a = rgb.split("(");
	a = a[1].split(")");
	a = a[0].split(",");

	var r = parseInt(a[0]);
	var g = parseInt(a[1]);
	var b = parseInt(a[2]);

	r = r.toString(16);
	if (r.length == 1)
		r = "0" + r;

	g = g.toString(16);
	if (g.length == 1)
		g = "0" + g;

	b = b.toString(16);
	if (b.length == 1)
		b = "0" + b;

	var c = r + g + b;
	return "#" + c.toUpperCase();
}




PALETTE.prototype.HEXtoRGB = function(hex) {
	var c = (hex.charAt(0)=="#") ? hex.substr(1, 6) : hex;
	var r = c.substr(0, 2);
	var g = c.substr(2, 2);
	var b = c.substr(4, 2);
	r = parseInt(r, 16);
	g = parseInt(g, 16);
	b = parseInt(b, 16);
	return "rgb(" + r + ", " + g + ", " + b + ")";
}




PALETTE.prototype.HEXtoRGBA = function(hex, a) {
	var c = (hex.charAt(0)=="#") ? hex.substr(1, 6) : hex;
	var r = c.substr(0, 2);
	var g = c.substr(2, 2);
	var b = c.substr(4, 2);
	r = parseInt(r, 16);
	g = parseInt(g, 16);
	b = parseInt(b, 16);
	return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
}




PALETTE.prototype.getHSL = function(r, g, b) {

    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    }else{
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max){
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

	return new Array(h * 360, s * 100, l * 100);
}







PALETTE.prototype.shade = function(col, amt) {

	var hash = false;

	if (col.indexOf("rgb") >= 0) {
		col = this.RGBtoHEX(col);
	}

	if (col[0] == "#") {
		col = col.slice(1);
		hash = true;
	}

	var r = this.hexToR(col);
	var g = this.hexToG(col);
	var b = this.hexToB(col);
	r += amt;
	if (r > 255)
		r = 255;
	else if (r < 0)
		r = 0;
	g += amt;
	if (g > 255)
		g = 255;
	else if (g < 0)
		g = 0;
	b += amt;
	if (b > 255)
		b = 255;
	else if (b < 0)
		b = 0;

	r = r.toString(16);
	if (r.length == 1)
		r = "0" + r;

	g = g.toString(16);
	if (g.length == 1)
		g = "0" + g;

	b = b.toString(16);
	if (b.length == 1)
		b = "0" + b;

	return (hash ? "#" : "") + r + g + b;
}







var palette = new PALETTE()

export default palette
