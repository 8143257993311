<template>

	<div class="color-picker box center">

		<div
			v-if="!wheel"
			class="color-picker-picked"
			@click="showColorPicker"
			:style="{
				backgroundColor: picked
			}"
		></div>

		<div
			v-if="!wheel && random"
			class="color-picker-random ion-ios-color-filter-outline"
			@click="rand"
		>
		</div>

		<div
			class="color-picker-wheel-container box wide tall center"
			:style="{
				display: wheel ? 'flex' : display
			}">

			<div :id="'color-picker-wheel-' + id"></div>

			<flex-br class="mb-50" />

			<vue-button @click.native="hideColorPicker">OK</vue-button>

		</div>

	</div>

</template>

<script>

import iro from '@jaames/iro'

export default {

	name: "VueColor",

	props: ['color', 'random', 'wheel', 'show'],

	data() {
		return {
			colorPicker	: null,
			picked		: this.color ? this.color : '#fff',
			display		: 'none',
			id			: this.getID()
		}
	},

	computed: {

	},

	methods: {

		getID() {

			let id = ''

			for (let i = 0; i < 5; i++)
				id += Math.round(Math.random() * 10)

			return id
		},

		showColorPicker() {

			if (this.show)
				this.show()
			else
				this.display = 'flex'
		},

		hideColorPicker() {
			this.display = 'none'
		},

		getRandomColor() {

			var randomColour = ""

			var r = Math.round(255 * Math.random())
			var g = Math.round(255 * Math.random())
			var b = Math.round(255 * Math.random())

			var c = "rgb(" + r + ", " + g + ", " + b + ")"

			return c
		},

		rand() {

			let randomColour = this.getRandomColor()

			this.picked = randomColour
			this.colorPicker.color.set(this.picked)
		},

		randLight() {

			let randomColor = this.getRandomColor()

			if (this.getBrightness(randomColor) < 180) {

				this.randLight()

				return
			}

			this.picked = randomColor
			this.colorPicker.color.set(this.picked)
		},

		randDark() {

			let randomColor = this.getRandomColor()

			if (this.getBrightness(randomColor) > 70) {

				this.randDark()

				return
			}

			this.picked = randomColor
			this.colorPicker.color.set(this.picked)
		},

		getBrightness(color) {


			//This method will return a number in the range of 0 (dark) to 255 (light)

			var R, G, B;

			if (this.isRGB(color)) {

				var a = color.split("(");
				a = a[1].split(")");
				a = a[0].split(",");

				R = parseInt(a[0]);
				G = parseInt(a[1]);
				B = parseInt(a[2]);

			} else {

				R = this.hexToR(color);
				G = this.hexToG(color);
				B = this.hexToB(color);
			}

			//this.brightness = Math.sqrt(0.241*R*R + 0.691*G*G + 0.068*B*B);
			return 0.299*R + 0.587*G + 0.114*B;
		},

		isRGB(c) {

			if (c.indexOf("rgb") >= 0)
				return true;

			return false;
		},

		hexToR(hex) {
			return parseInt(hex.substring(0,2),16);
		},

		hexToG(hex) {
			return parseInt(hex.substring(2,4),16);
		},

		hexToB(hex) {
			return parseInt(hex.substring(4,6),16);
		}
	},

	mounted() {

		this.colorPicker = new iro.ColorPicker('#color-picker-wheel-' + this.id, {

			width: 300,
			color: this.picked
		})

		this.colorPicker.on('color:change', (color, changes) => {

			this.picked = color.hexString

			this.$emit('change', { color: this.picked })
		})
	},

	watch: {

		color(newVal, oldVal) {

			this.picked = newVal

			this.colorPicker.color.set(this.picked)
		}
	}
}
</script>


<style lang="scss" scoped>

.color-picker {

	display: flex;
	z-index: 9;

	.color-picker-picked {

		width: 50px; height: 50px;
		border-radius: 25px;
		border: 4px solid transparent;

		&:hover {
			border: 4px solid $highlight-color;
		}
	}

	.color-picker-random {

		margin-left: 20px;
		width: 55px; height: 70px;
		font-size: 70px; text-align: center;
		display: flex;

		&:hover {
			color: $highlight-color;
		}
	}

	.color-picker-wheel-container {

		position: fixed; left: 0; top: 0;
		width: 100%; height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 99;

		#color-picker-wheel {

			position: relative;
			margin-bottom: 50px;

		}
	}
}

</style>
