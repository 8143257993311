<template>

	<div class="container">

		<div id="menu-button-box"></div>

		<div class="section">
			This is a simple demo of animation controlled by scrolling
		</div>

		<div class="section scroll-animation height-1500">

			<div class="scroll-animation-container">
				<div class="scroll-animation-content animation-1"></div>
			</div>

		</div>

		<div class="section">
			It creates an illusion that the scrolling is paused when animation happens
		</div>

		<div class="section">
			Although in fact the page is being scrolled just as usual
		</div>

		<div class="section scroll-animation height-1500">

			<div class="scroll-animation-container">
				<div class="scroll-animation-content animation-2"></div>
			</div>

		</div>

		<div class="section">
			This effect is often seen on 'Learn More' pages of new iPhones
		</div>

		<div class="section">
			It is a nice trick for marketing websites
		</div>

		<div class="section">
			The end
		</div>

	</div>

</template>

<script>

export default {

	name: 'AnimationOnScroll',

	mounted() {

		$(".scroll-animation-container").css({
			height: $(window).height() + "px"
		})

		$(window).scroll(() => {
	        this.scrolling()
	    })
	},

	methods: {

		scrolling() {

			let scroll = $(window).scrollTop()

			$(".section.scroll-animation").each(function() {

				let winh = $(window).height()

				let startLine = $(this).offset().top
				let stopLine = startLine + $(this).height() - winh

				let animation = $(this).children(".scroll-animation-container").children(".scroll-animation-content")

				if (scroll >= startLine && scroll <= stopLine) {

					// Scrolling inside the animation container
					// Do animation stuff here

					// scroll length in pixels inside the .scroll-animation-container
					let progress = scroll - startLine


					// change background color while scrolling
					let colors = ['#47bac9', '#a0d615', '#17cf91', '#17adcf', '#8168b0']
					let i = Math.round(progress / (stopLine - startLine) * 100 / 20)
					let color = colors[i]

					$(this).children(".scroll-animation-container").css({
						position		: "fixed",
						top				: 0,
						backgroundColor	: color
					})


					if (animation.hasClass("animation-1")) {

						animation.css({
							width	: progress + "px",
							height	: progress + "px"
						})

					} else {

						animation.css({
							borderLeftWidth		: progress + "px",
							borderRightWidth	: progress + "px",
							borderBottomWidth	: progress + "px",
							transform			: "rotate(" + progress + "deg)"
						})
					}

				} else {

					// Out of animation container

					let top = 0

					if (scroll > stopLine) {

						// scroll down

						top = stopLine - startLine

					} else if (scroll < startLine) {

						// scroll up
						// back to initial status

						if (animation.hasClass("animation-1")) {

							animation.css({
								width	: 0 + "px",
								height	: 0 + "px"
							})

						} else {

							animation.css({
								borderLeftWidth		: 0 + "px",
								borderRightWidth	: 0 + "px",
								borderBottomWidth	: 0 + "px",
								transform			: "rotate(" + 0 + "deg)"
							})
						}
					}

					$(this).children(".scroll-animation-container").css({
						position: "absolute",
						top		: top + "px"
					})
				}

			})
		}
	}
}

</script>




<style lang="scss" scoped>

.container {

	position: relative;
	width: 100%; height: auto;

	.section {

		position: relative;
		width: 100%; min-height: 300px;
		background-color: #eee;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px;
		font-size: 30px; text-align: center; line-height: 50px;
		z-index: 1;

		&:nth-child(even) {
			background-color: #ddd;
		}

		&.scroll-animation {

			background-color: #aaa;
			overflow: hidden;
			padding: 0px;
			z-index: 2;

			.scroll-animation-container {

				position: absolute; left: 0; top: 0;
				width: 100%; height: 100%;
				background-color: #47bac9;
				display: flex;
				align-items: center;
				// justify-content: center;
				overflow: hidden;
				transition: background-color 1s linear;

				.scroll-animation-content.animation-1 {

					position: absolute; left: 50%; top: 50%;
					transform: translate(-50%, -50%);
					width: 0px; height: 0px;
					background-color: gold;
					border-radius: 50%;
					transition: all 200ms linear;
				}

				.scroll-animation-content.animation-2 {

					width: 0; height: 0;
					border-left: 0px solid transparent;
					border-right: 0px solid transparent;
					border-bottom: 0px solid gold;
					transition: all 200ms linear;
				}
			}
		}
	}
}

</style>
