<template>

	<div class="list box" :class="className">

			<div
				class="list-item box wide v-mid"
				v-for="(item, index) in list"
				@click="select(item)"
			>

				<div
					class="list-item-label box"
					v-if="item.label"
					ref="labels"
				>

					{{ item.label }}

				</div>

				<div class="list-item-value box fill v-mid">

					<div
					 	v-if="item.icon"
						class="list-item-icon box center tall width-50"
						:class="item.icon"
					>
					</div>

					<div class="list-item-text box fill">
						<span>{{ item.text ? item.text : item }}</span>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>

export default {

	name: "VueList",

	props: ['list', 'classes', 'overflow'],

	data() {
		return {

		}
	},

	computed: {

		className() {
			return `
				${ this.classes ? this.classes : '' }
				${ this.overflow ? 'overflow' : '' }
			`
		}
	},

	methods: {

		select(item) {

			this.$emit('select', { ...item })
		},

		calculateLabelWidth() {

			if (this.$refs.labels) {

				let maxWidth = 0

				$(this.$refs.labels).each(function() {

					if ($(this).width() > maxWidth)
						maxWidth = $(this).width()
				})

				let padding = 20

				$(this.$refs.labels).css({
					width: maxWidth + padding + 'px'
				})
			}
		}
	},

	watch: {

		list: function(newVal, oldVal) {

			if (this.$refs.labels) {

				$(this.$refs.labels).css({
					width: 'auto'
				})

				this.$nextTick(() => this.calculateLabelWidth())
			}
		}
	},

	mounted() {
		this.calculateLabelWidth()
	}
}
</script>


<style lang="scss" scoped>

$list-item-text-color: rgba(0, 0, 0, 0.7);
$list-item-text-color-hover: rgba(0, 0, 0, 1);
$list-item-background-hover: rgba(20, 96, 135, 0.1);
$list-item-border-bottom: 1px solid rgba(0, 0, 0, 0.3);

.list {

	position: relative;
	left: 0; top: 0;
	width: 100%; height: auto;

	.list-item {

		position: relative;
		padding-top: 15px; padding-bottom: 15px;
		width: 100%;
		padding-left: 20px; padding-right: 20px;
		color: $list-item-text-color;
		align-items: flex-start;
		align-content: flex-start;
		cursor: default;

		&:after {
			content: '';
			position: absolute; top: 100%; left: 0;
			width: 100%; height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
		}

		&:last-child:after {
			content: '';
			width: 0; height: 0;
			background-color: rgba(0, 0, 0, 0);
		}

		.list-item-label {
			font-size: 18px;
			padding-right: 20px;
			opacity: 0.5;
		}

		.list-item-value {

			font-size: 18px;
			overflow: hidden;

			.list-item-text {

				flex: 1;
				overflow: hidden;

				span {
					text-align: left;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	&.no-divider {

		.list-item {

			border-radius: 10px;


			&:after {
				content: none;
			}
		}
	}

	&.overflow {

		.list-item {

			height: auto;

			.list-item-value {

				.list-item-text {

					flex: 1;
					flex-wrap: wrap;

					span {
						text-overflow: inherit;
						overflow: visible;
						white-space: normal;
					}
				}
			}
		}
	}

	&.select {

		.list-item {

			cursor: pointer;

			&:hover {
				color: $list-item-text-color-hover;
				background-color: $list-item-background-hover;
			}
		}
	}
}

$alt-list-item-background-hover: rgba(255, 255, 255, 0.1);
$alt-list-item-text-color: rgba(255, 255, 255, 0.7);
$alt-list-item-text-color-hover: rgba(255, 255, 255, 1);
$alt-list-item-border-bottom: 1px solid rgba(255, 255, 255, 0.3);

.theme-alt {

	.list {

		.list-item {

			color: $alt-list-item-text-color;
		}

		&.select {

			.list-item {

				&:hover {
					color: $alt-list-item-text-color-hover;
					background-color: $alt-list-item-background-hover;
				}
			}
		}
	}
}

</style>
