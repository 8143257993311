<template>

	<screen>

		<div class="box wide screen-box">

			<Cover v-show="!status" @start="start" name="Design 2" :record="record" :isBest="isBest" />

			<div v-if="status" class="box stretch screen-header">
				<Header
					@restart="restart"
					@finish="finish"
					@remove="onToggleCheckbox"
					:targets="targets"
					:selected="selected"
					:completed="completed"
					:counter="counter"
				 />
			</div>

			<div v-if="status" class="box mt-10 screen-main-list">

				<div class="box width-30 mr-10 letter-list-scroll">

					<div v-for="letter in alphabet" class="box letter" @click="goLetter(letter)">{{ letter }}</div>

				</div>

				<div class="box fill city-list-scroll">

					<CityList :selected="selected" @toggle="onToggleCheckbox" :letter="letter" />

				</div>

			</div>

		</div>

	</screen>

</template>




<script>

import Screen from './Screen'
import SearchBox from './SearchBox'
import CityList from './CityList'
import Header from './Header'
import Cover from './Cover'

export default {

	name: 'Design2',

	components: {
		Screen,
		SearchBox,
		Header,
		Cover,
		CityList
	},

	props: ['targets', 'isBest'],

	data() {

		return {
			status: false,
			counter: '',
			selected: [],
			completed: false,
			record: null,
			alphabet: [
				'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
				'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
			],
			letter: ''
		}
	},

	computed: {

		check() {

			if (this.selected.length != this.targets.length)
				return false

			let error = null

			this.targets.forEach(target => {

				if (this.selected.filter(item => target == item).length == 0)
					error = true
			})

			return !error
		}
	},

	methods: {

		start() {

			this.status = true
			this.counter = 'reset'
			setTimeout(() => this.counter = 'start')
		},

		onToggleCheckbox(params) {

			if (params.status && !this.selected.includes(params.city))
				this.$set(this.selected, this.selected.length, params.city)
			else if (!params.status && this.selected.includes(params.city))
				this.selected = this.selected.filter(item => item != params.city)

			if (this.check) {

				this.counter = 'pause'

				this.completed = true
			}
		},

		goLetter(letter) {
			this.letter = letter
		},

		restart() {

			this.status = false
			this.selected = []
			this.completed = false
		},

		finish(e) {

			if (!this.record || e.min < this.record.min || e.sec < this.record.sec || e.ms < this.record.ms)
				this.record = e

				this.$emit('finish', { ...this.record, name: 'Design2' })
		}
	},

}

</script>




<style lang="scss" scoped>

.screen-box {
	height: 100%;
	flex-direction: column;
	flex-wrap: nowrap;
}


.screen-main-list {
	height: calc(100% - 100px);
	border-radius: 20px;
	overflow: hidden;
	padding: 0 20px 20px 20px;
	flex: 1;
}

.letter-list-scroll {
	height: 100%;
	overflow: scroll;
}

.city-list-scroll {
	height: 100%;
	overflow: scroll;
}

.letter {
	height: 18px; width: 18px;
	font-size: 14px; line-height: 18px;
	cursor: pointer;
	border-radius: 20px;
	text-align: center; color: #0a3e75;
	justify-content: center;

	&:hover {
		background-color: #cee4fb;
	}
}


</style>
