<template>

	<div class="screen blink">

		<div id="animation-bg" :style="{ backgroundColor, height }">

			<div id="storm" :style="{ backgroundColor: stormColor }"></div>

			<canvas id="blink-canvas-top"></canvas>

			<canvas id="blink-canvas-bottom"></canvas>

		</div>

		<div class="box wide tall center">

			<transition>
				<div class="animation-text animation-text-0 box tall center" v-if="getText && textBox % 2 == 0">
					<span :style="textStyle">{{ getText }}</span>
				</div>
			</transition>

			<transition>
				<div class="animation-text animation-text-1 box tall center" v-if="getText && textBox % 2 == 1">
					<span :style="textStyle">{{ getText }}</span>
				</div>
			</transition>

			<transition>
				<div class="box wide center mt-260" v-if="showButton">
					<custom-button classes="secondary" @click.native="storm">isn't it</custom-button>
				</div>
			</transition>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Blink',

	props: ['config'],

	data() {

		return {
			backgroundColor: '#fff',
			stormColor: 'transparent',
			height: '0px',
			textBox: 0,
			textStyle: {},
			words: [
				'',
				'',
				'',
				'hi',
				'',
				'is darkness your old friend?',
				'',
				'at the end of the storm it\'s a golden sky',
				'and you\'ll never walk alone',
				'and you\'ll never walk alone'
			],
			reversedWords: [
				'',
				'',
				'',
				'',
				'hi',
				'',
				'is darkness your old friend?',
				'',
				'at the end of the storm it\'s a golden sky',
				'and you\'ll never walk alone'
			]
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		},

		text() {

			if (this.reverse)
				return ''
			
			return this.words[this.step]
		},

		reversedText() {

			if (!this.reverse)
				return ''

			return this.reversedWords[this.step]
		},

		getText() {

			if (this.reverse)
				return this.reversedText
			else
				return this.text
		},

		showButton() {

			return this.reverse ? (this.step == 8 ? true : false) : (this.step == 7 ? true : false)
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			this.textBox++

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			this.textBox++

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			/*
				step 1: open & close eyes - size 100
				step 2: open & close eyes - size 200
				step 3: open eyes - size 300 - show text
				step 4: close eyes
				step 5: open eyes - size 300 - show text
				step 6: close eyes
				step 7: open eyes - full screen - show text
				step 8: show text
				step 9: fade text
			*/

			/*
				calculate the max size(height) when eyes fully open
				use screen width 650 & height 100 as a default ratio for step 1 etc.

				 max    100 * step
				----- = ----------
				width      650
			*/

			if (step == 9) {

				this.textStyle = {
					transition: 'none',
					transform: 'scale(1)',
					opacity: 1
				}

				setTimeout(() => {

					this.textStyle = {
						transition: 'all 1s linear',
						transform: 'scale(20)',
						opacity: 0
					}
				})

				return

			} else if (step == 8) {

				return
			}

			let winw = window.innerWidth
			let winh = window.innerHeight

			let max = winw * 100 * (step > 3 ? 3 : step) / 650

			if (step == 7) {

				max = window.innerHeight * 1.5

			} else {

				let maxh = winh * 0.6
				max = max > maxh ? maxh : max
			}

			let height = 0
			let start = new Date().getTime()

			let i = setInterval(() => {

				/*
					height    now - start
					------ = -------------
					  max    duration / 2
				*/

				let duration = this.config.duration

				if (step == 1 || step == 2)
					duration = this.config.duration / 2

				height = Math.round(max * (new Date().getTime() - start) / duration)

				if (step == 4 || step == 6)
					height = max - height

				this.height = height + 'px'

				this.draw('blink-canvas-top', height, true)
				this.draw('blink-canvas-bottom', height, false)

			}, 10)

			if (step == 1 || step == 2) {

				this.backgroundColor = 'white'

			} else if (step == 3 || step == 4) {

				this.backgroundColor = 'aqua'

			} else if (step == 5 || step == 6) {

				this.backgroundColor = 'DeepSkyBlue'

			} else if (step >= 7) {

				this.backgroundColor = 'gold'
				this.height = window.innerHeight * 1.5 + 'px'
			}


			if (step == 1 || step == 2) {

				setTimeout(() => {

					clearInterval(i)

					start = new Date().getTime()
					height = max

					i = setInterval(() => {

						/*
							height    now - start
							------ = -------------
							  max    duration / 2
						*/

						height = Math.round(max * (new Date().getTime() - start) / (this.config.duration / 2))
						height = max - height

						this.height = height + 'px'

						this.draw('blink-canvas-top', height, true)
						this.draw('blink-canvas-bottom', height, false)

					}, 10)

					setTimeout(() => {

						height = 0

						this.height = height + 'px'

						this.draw('blink-canvas-top', height, true)
						this.draw('blink-canvas-bottom', height, false)

						clearInterval(i)

					}, this.config.duration / 2)

				}, this.config.duration / 2)

			} else {

				setTimeout(() => {

					clearInterval(i)

				}, this.config.duration)
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.animate(1)

			} else if (step == 2) {

				this.animate(2)

			} else if (step == 3) {

				this.animate(4)

			} else if (step == 4) {

				this.animate(3)

			} else if (step == 5) {

				this.animate(4)

			} else if (step == 6) {

				this.animate(5)

			} else if (step == 7) {

				let max = window.innerHeight * 1.5

				let height = 0
				let start = new Date().getTime()

				let i = setInterval(() => {

					let duration = this.config.duration

					height = Math.round(max * (new Date().getTime() - start) / duration)
					height = max - height

					this.height = height + 'px'

					this.draw('blink-canvas-top', height, true)
					this.draw('blink-canvas-bottom', height, false)

				}, 10)

				setTimeout(() => {

					clearInterval(i)

				}, this.config.duration)

			} else if (step == 8) {

				this.backgroundColor = 'gold'
				this.height = window.innerHeight * 1.5 + 'px'

			} else if (step == 9) {

				this.backgroundColor = 'gold'
				this.height = window.innerHeight * 1.5 + 'px'

				this.textStyle = {
					transition: 'none',
					transform: 'scale(20)',
					opacity: 0
				}

				setTimeout(() => {

					this.textStyle = {
						transition: 'all 1s linear',
						transform: 'scale(1)',
						opacity: 1
					}
				})
			}
 		},

		draw(canvasID, radian, direction) {

			let winw = window.innerWidth

			let canvasHeight = 120

			let canvas = document.getElementById(canvasID)

			if (!canvas)
				return

			canvas.setAttribute("width", winw)
			canvas.setAttribute("height", canvasHeight)
			let ctx = canvas.getContext("2d")

			if (window.devicePixelRatio) {

				var hdCanvasWidth = canvas.getAttribute('width');
				var hdCanvasHeight = canvas.getAttribute('height');
				var hdCanvasCssWidth = hdCanvasWidth;
				var hdCanvasCssHeight = hdCanvasHeight;

				canvas.setAttribute('width', hdCanvasWidth * window.devicePixelRatio);
				canvas.setAttribute('height', hdCanvasHeight * window.devicePixelRatio);
				canvas.style.width = hdCanvasCssWidth;
				canvas.style.height = hdCanvasCssHeight;

				ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
			}

			ctx.lineWidth = 0
			ctx.strokeStyle = this.backgroundColor
			ctx.fillStyle = this.backgroundColor

			let x1 = 0
			let y1 = canvasHeight

			let x2 = x1 + 150 + radian
			if (x2 > winw * 0.3) x2 = winw * 0.3
			let y2 = canvasHeight - radian < 0 ? 0 : canvasHeight - radian

			let x3 = x1 + winw - x2
			let y3 = y2

			let x4 = x1 + winw
			let y4 = y1

			if (!direction) {

				// draw curve downwards

				y1 = 0
				y2 = radian > canvasHeight ? canvasHeight : radian
				y3 = y2
				y4 = y1
			}

			ctx.moveTo(x1, y1)

			ctx.bezierCurveTo(
				x2, y2,
				x3, y3,
				x4, y4
			)

			ctx.lineTo(x1, y1)
			ctx.closePath()

			ctx.stroke()
			ctx.fill()

			let updateColor = setInterval(() => {
				ctx.strokeStyle = $('#animation-bg').css('background-color')
				ctx.fillStyle = $('#animation-bg').css('background-color')
				ctx.fill()
			}, 100)

			setTimeout(() => clearInterval(updateColor), 1000)
		},

		storm() {

			this.stormColor = 'MediumSeaGreen'
			setTimeout(() => this.stormColor = 'LightSeaGreen', 0)
			setTimeout(() => this.stormColor = 'SlateBlue', 200)
			setTimeout(() => this.stormColor = 'DarkBlue', 400)
			setTimeout(() => this.stormColor = 'DarkKhaki', 600)
			setTimeout(() => this.stormColor = 'OrangeRed', 800)
			setTimeout(() => this.stormColor = 'DarkRed', 1000)
			setTimeout(() => this.stormColor = 'Maroon', 1200)
			setTimeout(() => this.stormColor = 'Indigo', 1400)
			setTimeout(() => this.stormColor = 'MediumPurple', 1600)
			setTimeout(() => this.stormColor = 'LightSeaGreen', 1800)
			setTimeout(() => this.stormColor = 'transparent', 2000)
		},

		getEndState() {

			this.backgroundColor = 'gold'
			this.height = window.innerHeight * 1.5 + 'px'
		}
	}
}

</script>




<style lang="scss" scoped>

.blink {

	#animation-bg {

		position: absolute; left: 0; top: 50%;
		width: 100%; height: 0;
		transform: translateY(-50%);
		background-color: #fff;
		transition: background-color 1s linear;
		display: block !important;

		#storm {
			position: absolute; left: 0; top: 0;
			width: 100%; height: 100%;
			background-color: transparent;
			transition: background-color 200ms linear;
		}

		#blink-canvas-top {
			position: absolute; left: 0; top: -119px;
			width: 100%; height: 120px;
		}

		#blink-canvas-bottom {
			position: absolute; left: 0; top: 99%; top: calc(100% - 1px);
			width: 100%; height: 120px;
		}
	}

	.animation-text-0, .animation-text-1 {
		position: absolute; top: 50%; left: 50%;
		transform: translate(-50%, -50%);
	}
}

</style>
