<template>

	<div class="popup" :class="className">

		<div class="popup-close ion-ios-close-empty" @click="close"></div>

		<slot></slot>

	</div>

</template>

<script>

export default {

	name: "VuePopup",

	props: ['display', 'classes'],

	data() {
		return {

		}
	},

	computed: {

		className() {
			return `${ this.classes }`
		}
	},

	methods: {

		close() {
			this.$router.go(-1)
		}
	},

	mounted() {

	}
}
</script>


<style lang="scss" scoped>


.popup-close {

    position: absolute; top: 0; right: 0;
    width: 50px; height: 50px;
    font-size: 50px; text-align: center; color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    &:hover {
        color: rgba(255, 255, 255, 1);
    }
}

</style>
