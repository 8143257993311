import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as Strings from '@/utils/string'

import vOutsideEvents from "vue-outside-events"
Vue.use(vOutsideEvents)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)





Vue.config.productionTip = false




// get all components
const components = require.context('./components', true, /\.vue$/)

components.keys().map(key => {
	let name = key.substr(key.lastIndexOf('/') + 1)
	name = Strings.pascalToKebab(name.match(/\w+/)[0])
	return Vue.component(name, components(key).default)
})




new Vue({

  render: h => h(App),

  router,

}).$mount('#app')
