<template>

	<div :id="id" ref="scroll-wrap" class="vue-scroll" :class="classNames">

		<slot></slot>

	</div>

</template>

<script>


export default {

	name: "VueScroll",

	props: ['classes', 'startY', 'resize'],

	data() {
		return {
			id: "vue-scroll-" + Math.round(Math.random() * 100000)
		}
	},

	computed: {

		classNames() {
			return this.classes
		}
	},

	watch: {

		resize: function (newVal, oldVal) {
			// this.refresh()
			this.$nextTick(() => this.refresh())
	    }
	},

	methods: {

		init() {

			if (this.startY == -1) {

				this.scrollToEnd()
			}
		},

		reset() {

			this.init()
		},

		refresh() {

			if (this.startY == -1) {

				this.scrollToEnd()
			}
		},

		scrollToEnd() {

			this.$refs['scroll-wrap'].scrollTop = this.$refs['scroll-wrap'].scrollHeight
		},

		scrollTo(y) {

			this.$refs['scroll-wrap'].scrollTop = y
		}
	},

	mounted() {

		this.init()
	}
}

</script>

<style lang="scss" scoped>

.vue-scroll {
	max-height: 100%;
	overflow: scroll;
}

</style>
