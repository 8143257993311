<template>

	<div class="box wide header">

		<div class="box mb-10 wide center mb-20">

			<div class="box center counter-box-wrap" :class="{ completed: completed ? true: false }">

				<div class="box mr-5">
					<div class="checkmark" ref="checkmark"></div>
				</div>

				<div class="box">
					<vue-counter :action="counter" @pause="finish" />
				</div>

			</div>

			<div class="close" @click="restart"></div>

		</div>

		<div class="box wide">

			<div class="box header-label pr-20">Select:</div>

			<div class="box fill">

				<div class="target box wide v-mid" v-for="target in targets">
					<span class="target-status" :class="selected.includes(target) ? 'selected' : ''"></span>
					<span>{{ target }}</span>
				</div>

				<div class="wrong target box wide v-mid" v-for="item in wrongSelection" @click="deselect(item)">
					<span class="wrong-status"></span>
					<span>{{ item }}</span>
				</div>

			</div>

		</div>

	</div>

</template>




<script>

import cities from './cities.js'

export default {

	name: 'Header',

	props: ['targets', 'completed', 'counter', 'selected'],

	data() {

		return {
		}
	},

	computed: {

		wrongSelection() {

			return this.selected.filter(item => !this.targets.includes(item))
		}
	},

	methods: {

		restart() {

			this.$emit('restart')
		},

		finish(params) {

			this.$emit('finish', params)
		},

		deselect(city) {

			this.$emit('remove', { status: false, city })
		}
	},

	watch: {

		completed(newVal, oldVal) {

			if (newVal === true)
				this.$refs.checkmark.style.width = '16px'
			else
				this.$refs.checkmark.style.width = '0px'
		}
	}

}

</script>




<style lang="scss" scoped>

.header {
	margin-bottom: 10px;
	padding-bottom: 20px;
	font-size: 16px;
	border-bottom: 1px solid #ccc;
	justify-content: flex-start;
	text-align: left;
}

.target {

	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}

	.target-status {
		margin-right: 10px;
		width: 10px; height: 10px;
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.1);

		&.selected {
			background-color: rgba(99, 222, 24, 0.6);
		}
	}

	.wrong-status {
		margin-right: 10px;
		width: 10px; height: 10px;
		border-radius: 10px;
		background-color: red;
	}

	&.wrong {
		cursor: pointer;

		&:hover {
			color: red;
		}
	}
}

.header-label {
	color: rgba(0, 0, 0, 0.5);
}

.counter-box-wrap {

	min-width: 50px; height: 26px;
	padding-left: 10px; padding-right: 15px;
	border-radius: 15px;
	font-size: 18px;
	background-color: rgba(0, 0, 0, 0.06);

	&.completed {
		background-color: rgba(99, 222, 24, 0.3);
	}

	.checkmark {
		position: relative;
		margin-left: -5px; margin-top: 1px;
		width: 0px; height: 16px;
		border-radius: 20px;
		background-color: #63de18;
		transition: width linear 200ms;
		overflow: hidden;

		&:before {
			position: absolute;
			content: "";
			left: 5px;
			top: 2px;
			width: 4px;
			height: 8px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
}

.close {
	position: absolute; top: 0px; right: 2px;
	background-color: rgba(0, 0, 0, 0.07);
	width: 24px; height: 24px;
	border-radius: 15px;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute; top: 5px;
		width: 1px; height: 14px;
		background-color: rgba(0, 0, 0, 0.3);
		transform-origin: center;
		transform: rotate(45deg);
	}

	&:after {
		content: '';
		position: absolute; top: 5px;
		width: 1px; height: 14px;
		background-color: rgba(0, 0, 0, 0.3);
		transform-origin: center;
		transform: rotate(-45deg);
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.2);

		&:before {
			background-color: rgba(0, 0, 0, 1);
		}

		&:after {
			background-color: rgba(0, 0, 0, 1);
		}
	}
}


</style>
