<template>

	<div class="screen bow-screen">

		<div id="animation-bow" class="animation">

			<div class="arrow-up-space">

				<div id="bow-arrow-up" class="arrow-up" :style="bowArrowUpStyles">
					<div class="arrow-up-1 arrow-up-arrow"></div>
					<div class="arrow-up-2 arrow-up-arrow"></div>
				</div>

			</div>

			<div id="bow-target" class="flip target" :style="targetStyles">

				<div class="face">

					<div class="front">

						<div class="text-fixed">
							The only way to do great work is to <span class="ion-ios-heart"></span> what you do
						</div>

						<div class="target-heart">
							<div class="target-heart-ring target-heart-ring-1 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-2 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-3 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-4 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-5 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-6 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-7 ion-ios-heart"></div>
							<div class="target-heart-ring target-heart-ring-8 ion-ios-heart"></div>
						</div>

					</div>

					<div class="back"></div>

				</div>

			</div>

			<div
				class="arrow-space"
				@mousedown="drawString"
				@mouseup="releaseArrow"
				v-touch:touchhold="drawString"
				v-touch:end="releaseArrow"
			>

				<div id="tip-draw" class="box wide tall center" :style="tipDrawStyles">
					Press and hold to aim
				</div>

				<div id="tip-release" class="box wide tall center" :style="tipReleaseStyles">
					Release to shoot
				</div>

				<div id="bow" class="bow" :style="bowStyles">
					<div class="bow-string-1" :style="bowString1Styles"></div>
					<div class="bow-string-2" :style="bowString2Styles"></div>
				</div>

				<div
					id="bow-img"
					class="bow svg"
					:style="{
						backgroundImage: `url(${ bowImg })`,
						...bowStyles
					}"
				></div>

				<div
					id="bow-arrow"
					class="bow-arrow"
					:style="{
						backgroundImage: `url(${ bowArrowImg })`,
						...bowArrowStyles
					}"
				></div>

				<div class="bow-aim-wrap" :style="bowAimWrapStyles">
					<div class="bow-aim" :style="bowAimStyles">
						<div class="bow-aim-line-h"></div>
						<div class="bow-aim-line-v"></div>
					</div>
				</div>

			</div>

			<div class="flip keep">

				<div class="face">

					<div class="front">

						<div class="text-fixed">
							If you haven't found it yet<br>
							keep looking<br>
							don't settle
						</div>

					</div>

					<div class="back"></div>

				</div>

			</div>

		</div>

	</div>

</template>




<script>

export default {

	name: 'Screen',

	props: ['config'],

	data() {

		return {
			bowReady: false,
			shooting: false,
			aiming: 0,
			aim: {
				x: 0,
				y: 0,
				xDirection: 1,
				yDirection: 1,
				speed: 10
			},
			bowImg: require('@/assets/img/bow.svg'),
			bowArrowImg: require('@/assets/img/bow-arrow.svg'),
			targetStyles: {},
			bowStyles: {},
			bowString1Styles: {},
			bowString2Styles: {},
			bowArrowStyles: {},
			bowArrowUpStyles: {},
			bowAimWrapStyles: {},
			bowAimStyles: {},
			tipDrawStyles: {},
			tipReleaseStyles: {}
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				let winw = $(window).width()
				let winh = $(window).height()
				let targetSize = $('#bow-target').width()
				let scale = 1

				for (let i = 1; i > 0; i -= 0.05) {

					if (targetSize * i < winw && targetSize * i < winh / 2) {

						scale = i

						break
					}
				}

				scale *= 3

				let top = winh / 4 * 3

				this.targetStyles = {

					transform: 'translateX(-50%) translateY(-' + top + 'px) scale(' + scale + ')'
				}

			} else if (step == 2) {

				let winw = $(window).width()
				let winh = $(window).height()
				let targetSize = $('#bow-target').width()
				let scale = 1

				for (let i = 1; i > 0; i -= 0.05) {

					if (targetSize * i < winw && targetSize * i < winh / 2) {

						scale = i

						break
					}
				}

				let top = winh - 50

				this.targetStyles = {
					transform: 'translateX(-50%) translateY(-' + top + 'px) scale(' + scale + ')'
				}

				setTimeout(() => {

					this.bowStyles = {
						bottom: '0px'
					}

					setTimeout(() => {

						this.bowArrowStyles = {
							bottom: '45px'
						}

						this.bowArrowUpStyles = {
							top			: 0,
							transform	: 'translateX(-50%) rotateX(30deg) scale(0.1)'
						}

						this.bowReady = true
						this.startAiming()

						this.tipDrawStyles = {
							display: 'flex'
						}

					}, 1000)

				}, 2000)

			} else if (step == 3) {

				this.bowReady = false
				this.cancelAiming()

				this.tipDrawStyles = { display: 'none' }
				this.tipReleaseStyles = { display: 'none' }

				this.bowArrowUpStyles = {
					top			: '-100px',
					transform	: 'translateX(-50%) rotateX(30deg) scale(0.01)',
					display		: 'none'
				}

				let winh = $(window).height()

				this.targetStyles = {
					transform: 'translateX(-50%) translateY(-' + winh + 'px) scale(0.01)',
					opacity: 0
				}

				this.bowStyles = {
					bottom		: '-200px',
					transform	: 'translateX(-50%)'
				}

				this.bowArrowStyles = {
					bottom		: '-200px',
					transform	: 'translateX(-50%)'
				}

				this.bowStyles = {
					bottom		: '-200px',
					transform	: 'translateX(-50%)'
				}
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.targetStyles = {
					transform: 'translateX(-50%) translateY(0px) scale(1)',
					opacity: 1,
					transition: 'transform 2s ease-out, opacity 2s ease-out'
				}

			} else if (step == 2) {

				this.bowReady = false
				this.cancelAiming()

				this.tipDrawStyles = { display: 'none' }
				this.tipReleaseStyles = { display: 'none' }

				this.bowArrowUpStyles = {
					top			: '-100px',
					transform	: 'translateX(-50%) rotateX(30deg) scale(0.01)',
					display		: 'none'
				}

				this.bowStyles = {
					bottom		: '-200px',
					transform	: 'translateX(-50%)'
				}

				this.bowArrowStyles = {
					bottom		: '-200px',
					transform	: 'translateX(-50%)'
				}

				this.bowStyles = {
					bottom		: '-200px',
					transform	: 'translateX(-50%)'
				}

				setTimeout(() => this.animate(1), 1000)

			} else if (step == 3) {

				this.bowReady = false
				this.cancelAiming()

				let winh = $(window).height()

				this.targetStyles = {
					transform: 'translateX(-50%) translateY(-' + winh + 'px) scale(0.01)',
					opacity: 0,
					transition: 'none'
				}

				setTimeout(() => {

					this.animate(3)
					this.animate(2)
				})
			}
		},

		startAiming() {

			if (this.aiming != 0)
				return

			this.bowAimWrapStyles = {
				opacity: 1
			}

			this.aiming = setInterval(() => {

				let aim = { ...this.aim }

				aim.x = aim.speed * aim.xDirection + aim.x

				if (aim.x > $('.bow-aim-wrap').width() - 50) {

					aim.xDirection = -1 * (Math.random() + 0.5)

				} else if (aim.x < 50) {

					aim.xDirection = 1 * (Math.random() + 0.5)
				}

				aim.y = aim.speed * aim.yDirection + aim.y

				if (aim.y > $('.bow-aim-wrap').height() - 40) {

					aim.yDirection = -1 * (Math.random() + 0.5)

				} else if (aim.y < 0) {

					aim.yDirection = 1 * (Math.random() + 0.5)
				}

				this.aim = aim

				this.bowAimStyles = {
					left: this.aim.x + 'px',
					top: this.aim.y + 'px'
				}

			}, 50)
		},

		cancelAiming() {

			this.bowAimWrapStyles = {
				opacity: 0
			}

			clearInterval(this.aiming)

			this.aiming = 0
		},

		drawString() {

			if (this.shooting || !this.bowReady)
				return

			this.startAiming()

			this.tipDrawStyles = {
				display: 'none'
			}

			this.tipReleaseStyles = {
				display: 'flex'
			}

			this.bowString1Styles = {
				transition: 'all 0s linear',
				transform: 'rotate(0deg)'
			}

			this.bowString2Styles = {
				transition: 'all 0s linear',
				transform: 'rotate(0deg)'
			}

			this.bowArrowStyles = {
				transition	: 'all 0s linear',
				bottom		: '45px',
				left		: '50%',
				transform	: 'translateX(-50%)'
			}

			setTimeout(() => {

				this.bowString1Styles = {
					transition	: 'all 200ms linear',
					transform	: 'rotate(18deg)'
				}

				this.bowString2Styles = {
					transition	: 'all 200ms linear',
					transform	: 'rotate(-18deg)'
				}

				this.bowArrowStyles = {
					transition	: 'all 200ms linear',
					bottom		: '-20px'
				}
			})
		},

		releaseArrow() {

			if (this.shooting || !this.aiming || !this.bowReady)
				return

			this.shooting = true

			this.cancelAiming()

			this.tipReleaseStyles = {
				display: 'none'
			}

			let intervals = [120, 100, 80, 60, 50, 50, 50]

			function waitFor(n) {

				let wait = 0

				for (let i = 0; i < n; i++) {

					wait += intervals[i]
				}

				return wait
			}

			let left = $('.bow-aim').position().left + $('.bow-aim-wrap').offset().left + $('.bow-aim').width() / 2
			let top = $('.bow-aim').position().top + $('.bow-aim').height() / 2 + 50
			let bottom = $('.arrow-space').height() - top - $('.bow-arrow').height()

			let arrowLeft = $('#bow-arrow').offset().left
			let arrowTop = $('#bow-arrow').offset().top

			let a = arrowLeft - left
			let b = bottom - (-20)
			let radians = Math.atan(a/b)
			/*
				radians   degrees
				------- = -------
				   Pi       180
			*/
			let degrees = -radians * 180 / Math.PI

			this.bowArrowStyles = {
				bottom		: bottom + 'px',
				left		: left + 'px',
				transition	: 'all 200ms linear',
				transform	: 'translateX(-50%) rotate(' + degrees + 'deg)'
			}

			this.bowString1Styles = {
				transition: 'all ' + intervals[0] + 'ms linear',
				transform: 'rotate(-10deg)'
			}

			this.bowString2Styles = {
				transition: 'all ' + intervals[0] + 'ms linear',
				transform: 'rotate(10deg)'
			}

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all ' + intervals[1] + 'ms linear',
					transform: 'rotate(6deg)'
				}

				this.bowString2Styles = {
					transition: 'all ' + intervals[1] + 'ms linear',
					transform: 'rotate(-6deg)'
				}

			}, waitFor(1))

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all ' + intervals[2] + 'ms linear',
					transform: 'rotate(-6deg)'
				}

				this.bowString2Styles = {
					transition: 'all ' + intervals[2] + 'ms linear',
					transform: 'rotate(6deg)'
				}

			}, waitFor(2))

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all ' + intervals[3] + 'ms linear',
					transform: 'rotate(3deg)'
				}

				this.bowString2Styles = {
					transition: 'all ' + intervals[3] + 'ms linear',
					transform: 'rotate(-3deg)'
				}

			}, waitFor(3))

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all ' + intervals[4] + 'ms linear',
					transform: 'rotate(-2deg)'
				}

				this.bowString2Styles = {
					transition: 'all ' + intervals[4] + 'ms linear',
					transform: 'rotate(2deg)'
				}

			}, waitFor(4))

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all ' + intervals[5] + 'ms linear',
					transform: 'rotate(-1deg)'
				}

				this.bowString2Styles = {
					transition: 'all ' + intervals[5] + 'ms linear',
					transform: 'rotate(1deg)'
				}

			}, waitFor(5))

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all ' + intervals[6] + 'ms linear',
					transform: 'rotate(0deg)'
				}

				this.bowString2Styles = {
					transition: 'all ' + intervals[6] + 'ms linear',
					transform: 'rotate(0deg)'
				}

			}, waitFor(6))

			setTimeout(() => {

				this.bowString1Styles = {
					transition: 'all 200ms linear'
				}

				this.bowString2Styles = {
					transition: 'all 200ms linear'
				}

			}, waitFor(7))



			setTimeout(() => {

				this.shooting = false

				this.tipDrawStyles = {
					display: 'flex'
				}

			}, waitFor(7))
		}
	}
}

</script>




<style lang="scss" scoped>

.bow-screen {

	background-color: Plum;

	#animation-bow {

		position: absolute; left: 0; top: 0%;
		width: 100%; height: 100%;

		.arrow-up-space {

			position: absolute; left: 0; top: 0%;
			width: 100%; height: 100%;
			perspective: 500px;
			z-index: 0;

			.arrow-up {

				position: absolute; left: 50%; top: 100%;
				width: 600px; height: 300px;
				transform: translateX(-50%) rotateX(30deg);
				transition: all 2s ease-out;

				.arrow-up-1 {
					position: absolute; left: 50%; top: 0%;
					transform: translateX(-50%);
					border-left: 300px solid transparent;
					border-right: 300px solid transparent;
					border-bottom: 300px solid rgba(255, 255, 255, 0.2);
				}

				.arrow-up-2 {
					position: absolute; left: 50%; top: 200px;
					transform: translateX(-50%);
					border-left: 300px solid transparent;
					border-right: 300px solid transparent;
					border-bottom: 100px solid Plum;
				}
			}
		}

		.flip.target {

			position: absolute; top: 100%; left: 50%;
			width: 3200px; height: 3200px;
			transform: translateX(-50%);
			transform-origin: center top;
			transition: transform 2s ease-out, opacity 2s ease-out;

			.face {

				height: auto;

				.front {

					width: 3200px; height: 2000px;
					transform:rotateX(-10deg);
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					background-color: rgba(255, 255, 255, 0.3);
					padding-top: 150px;

					.text-fixed {

						font-size: 120px; color: white;

						span {
							color: red;
						}
					}

					.target-heart {

						position: relative;
						flex: 1;
						width: 100%;
						color: red; font-size: 1600px;
						display: flex;
						align-items: center;
						justify-content: center;

						.target-heart-ring {

							position: absolute; left: 0; top: 0;
							width: 100%; height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;

							&.target-heart-ring-1 {
								font-size: 1600px; color: DarkRed;
							}

							&.target-heart-ring-2 {
								font-size: 1400px; color: Firebrick;
							}

							&.target-heart-ring-3 {
								font-size: 1200px; color: Crimson;
							}

							&.target-heart-ring-4 {
								font-size: 1000px; color: Tomato;
							}

							&.target-heart-ring-5 {
								font-size: 800px; color: orange;
							}

							&.target-heart-ring-6 {
								font-size: 600px; color: gold;
							}

							&.target-heart-ring-7 {
								font-size: 400px; color: yellow;
							}

							&.target-heart-ring-8 {
								font-size: 200px; color: red;
							}
						}
					}
				}
			}
		}

		.arrow-space {

			position: absolute; top: 5%; bottom: 0px; left: 0%;
			width: 100%;
			background-color: rgba(0,0,0,0);
			justify-content: center;
			align-items: center;
			align-content: center;
			text-align: center;

			@keyframes flashTips {
				0% {
					opacity: 0.1;
				}
				50% {
					opacity: 1;
				}
				100% {
					opacity: 0.1;
				}
			}

			#tip-draw {
				font-size: 30px; color: white;
				animation: flashTips 1.5s linear infinite;
				display: none;
			}

			#tip-release {
				font-size: 30px; color: white;
				animation: flashTips 0.8s linear infinite;
				display: none;
			}

			.bow {

				position: absolute; bottom: -200px; left: 50%;
				width: 400px; height: 200px;
				transform: translateX(-50%);
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				transition: all 1s ease-out;

				.bow-string-1 {
					position: absolute; left: 5px; bottom: 60px;
					width: 50%; height: 1px;
					background-color: #fff;
					transform-origin: left;
				}

				.bow-string-2 {
					position: absolute; right: 5px; bottom: 60px;
					width: 50%; height: 1px;
					background-color: #fff;
					transform-origin: right;
				}
			}

			.bow-arrow {
				position: absolute; bottom: -200px; left: 50%;
				width: 20px; height: 200px;
				transform: translateX(-50%);
				transform-origin: center top;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
			}

			.bow-aim-wrap {

				position: absolute; left: 50%; top: 50px;
				width: 280px; height: 220px;
				perspective: 100px;
				transform: translateX(-50%);
				opacity: 0;

				.bow-aim {

					position: absolute; left: 200px; top: 100px;
					width: 30px; height: 30px;
					border-radius: 15px;
					border: 1px solid #fff;
					transform: rotateX(-10deg);
					transition: all 50ms linear;

					.bow-aim-line-h {
						position: absolute; left: -10%; top: 50%;
						width: 120%; height: 1px;
						background-color: #fff;
					}

					.bow-aim-line-v {
						position: absolute; left: 50%; top: -10%;
						width: 1px; height: 120%;
						background-color: #fff;
					}
				}
			}
		}

		.flip.keep {

			position: absolute; top: 100%; left: 50%;
			width: 3200px; height: auto;
			transform: translateX(-50%);

			.face {

				height: auto;

				.front {

					width: 3200px; height: 2000px;
					transform:rotateX(-10deg);
					text-align: center;
					background-color: rgba(255, 255, 255, 0);
					padding-top: 150px;

					.text-fixed {

						font-size: 120px; color: white; line-height: 150px;
					}
				}
			}
		}
	}
}

</style>
