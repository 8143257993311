<template>

	<div class="panel box" :class="className">

		<div class="panel-header box wide v-mid">

			<div class="panel-header-title box center">{{ title ? title : '' }}</div>

			<div class="panel-header-options box fill right">

				<div v-if="hasOption('close')" class="box center panel-option ion-ios-close-empty" @click="close"></div>

			</div>

		</div>

		<div class="panel-body box wide">

			<slot></slot>

		</div>

		<div class="panel-footer box wide center">

			<div v-for="item in actions" class="panel-footer-action-item">

				<vue-button
					@click="onAction(item.action)"
					:classes="classes && classes.includes('round') ? 'round' : ''"
				>

					{{ item.name }}

				</vue-button>

			</div>

		</div>

	</div>

</template>

<script>

export default {

	name: "VuePanel",

	props: ['classes', 'title', 'options', 'actions'],

	data() {
		return {

		}
	},

	computed: {

		className() {
			return `${ this.classes }`
		}
	},

	methods: {

		close() {
			this.$emit('onClose')
		},

		hasOption(option) {

			if (!this.options)
				return false

			return this.options.includes(option)
		},

		onAction(action) {
			this.$emit(action)
		}
	},

	mounted() {

	}
}
</script>


<style lang="scss" scoped>


.panel {

	background-color: #fff;
	padding: 20px;
	border-radius: 30px;

	.panel-header {

		padding: 0px 0px 0px 20px;
		border-bottom: 1px solid #ccc;

		.panel-header-title {
			height: 50px;
			font-size: 20px;
		}

		.panel-header-options {

			.panel-option {

				width: 50px; height: 50px;
				font-size: 30px;
				cursor: pointer;

				&:hover {
					color: $highlight-color;
				}
			}
		}
	}

	.panel-body {

		padding: 30px 20px 30px 20px;
	}

	.panel-footer {

		padding: 20px 20px 0px 20px;
		border-top: 1px solid rgba(0, 0, 0, 0.3);

		.panel-footer-action-item {
			margin: 5px;
		}
	}
}

.theme-alt {

	.panel {

		background-color: #555;

		.panel-header {

			padding: 0px 0px 0px 20px;
			border-bottom: 1px solid #888;

			.panel-header-options {

				.panel-option {

					&:hover {
						color: $highlight-color;
					}
				}
			}
		}

		.panel-footer {

			border-top: 1px solid rgba(255, 255, 255, 0.3);
		}
	}
}

</style>
