<template>

<div class="textarea-wrap" :class="classNames">

	<label
		class="textarea-label"
		:style="styles ? { color: styles.color } : {}"
	>
		{{ label }}
	</label>

	<div class="textarea-placeholder" v-if="isEmpty">
		{{ placeholder ? placeholder : '' }}
	</div>

	<div class="textarea-box" :style="styles ? styles : {}"	>

		<div class="textarea-div" ref="div" v-html="divVal"></div>

		<textarea
			ref="textarea"
			:style="{ height: height + 'px' }"
			:value="val"
			@input="onInput"
			@focus="onFocus"
			@blur="onBlur"
			@keyup="onKeyUp"
			@keydown="onKeyDown"
			@keydown.enter="submit"
		>
		</textarea>

	</div>

</div>

</template>

<script>

var _ = require('lodash')

export default {

	name: 'VueTextarea',

	props: [
		'value',
		'label',
		'placeholder',
		'classes',
		'styles',
		'required',
		'requirement',
		'focus',
		'blur',
		'keyup',
		'keydown'
	],

	data() {
		return {
			val: '',
			divVal: '',
			height: 50,
			valid: true,
			validation: this.requirement ? this.requirement : [],
			isFocused: false
		};
	},

	computed: {

		isEmpty() {
			return !this.val || this.val.trim().length == 0 ? true : false
		},

		classNames() {
			return (
				(this.classes ? this.classes : '') +
				(this.isEmpty ? ' empty' : '') +
				(this.isFocused ? ' focus' : '') +
				(this.valid ? '' : ' alert')
			);
		}
	},

	methods: {

		onFocus() {

			this.isFocused = true

			this.$emit('focus')
		},

		onBlur() {

			this.isFocused = false

			if (this.blur)
				this.blur()

			this.validate()
		},

		onKeyUp(e) {

			this.$emit('keyup')

			this.resize()
		},

		onKeyDown(e) {

			this.$emit('keydown')

			this.resize()
		},

		onInput(e) {

			let val = e.target.value

			this.val = val
			this.divVal = val.replace(/\n/g, '<br/>')

			if (this.divVal.substr(-5) == '<br/>') {

				this.divVal += '_'
			}

			this.validate()

			this.$emit('input', { value: this.val })

			this.resize()
		},

		resize() {

			this.height = $(this.$refs.div).height() < 50 ? 50 : $(this.$refs.div).height() + 20
		},

		validate() {

			if (this.validation.length == 0) {

				this.valid = true

				return true
			}

			for (let i in this.validation) {

				let f = this.validation[i]

				if (!f(this.val)) {

					this.valid = false

					return false
				}
			}

			this.valid = true

			return true
		},

		submit() {

			this.$emit('submit', { value: this.val })

			setTimeout(() => {
				this.val = ''
				this.divVal = ''
			})
		}
	},

	watch: {

		value(newVal, oldVal) {

			this.val = newVal
		}
	},

	mounted() {

		if (this.value)
			this.val = this.value

		if (this.required)
			this.validation.push( (x) => x && x.trim().length > 0 )
	}
};

</script>

<style lang="scss" scoped>

$input-border-width: 1px;
$input-border-color: rgba(0, 0, 0, 1);

.textarea-wrap {

	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;

	.textarea-label {

		position: relative;
		left: 0;
		top: 0px;
		width: 100%;
		height: 20px;
		line-height: 20px;
		text-align: left;
		font-size: 14px;
		color: $label-color;
		transition: all 200ms linear;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.textarea-placeholder {
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 50px;
		line-height: 50px;
		text-align: left;
		font-size: 20px; color: $text-color;
		padding-left: 10px;
	}

	.textarea-box {

		position: relative;
		left: 0;
		top: 0;
		user-select: text;
		width: 100%;
		min-height: 50px;
		height: auto;
		border: $input-border-width solid $input-border-color;
		color: $text-color; text-align: left; font-size: 20px;
		border-radius: 10px;
		padding: 10px;
		outline: none;
		overflow: hidden;

		.textarea-div {
			width: 100%;
			min-height: 30px;
			opacity: 0;
		}

		textarea {
			position: absolute; left: 0; top: 0;
			width: 100%; height: 100%;
			outline: none;
			border: none;
			resize: none;
			background-color: transparent;
			padding: 10px;
			font-size: 20px;
			overflow: hidden;
		}
	}

	&.center {

		text-align: center;

		label {
			text-align: center;
		}
	}

	&.alert {

		.textarea-label {

			color: $danger-color;
		}

		.textarea-box {

			border-color: $danger-color;
			color: $danger-color;
		}
	}
}

$alt-input-border-width: 1px;
$alt-input-border-color: rgba(255, 255, 255, 1);

.theme-alt {

	.textarea-wrap {

		.textarea-label {

			color: $alt-label-color;
		}

		.textarea-placeholder {

			color: $alt-text-color;
		}

		.textarea-box {

			border: $alt-input-border-width solid $alt-input-border-color;
			color: $alt-text-color;
		}

		&.alert {

			.textarea-label {

				color: $alt-danger-color;
			}

			.textarea-box {

				border-bottom: $alt-input-border-width solid $alt-danger-color;
				color: $alt-danger-color;
			}
		}
	}
}

</style>
