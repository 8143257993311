<template>

	<div
		class="slider-wrap"
		:class="classNames"
		@mousedown="onMouseDown"
		@mouseup="onMouseUp"
		@mousemove="onMouseMove"
		v-touch:start="onMouseDown"
		v-touch:end="onMouseUp"
		v-touch:moving="onMouseMove"
		v-mouseup-outside="onMouseOutside"
	>

		<label class="slider-label">{{ label }}</label>

		<div class="slider-data mb-10">
			{{
				showData ?
					formattedData
					:
					(showPercentage ? percentage + '%' : 0)
			}}
		</div>

		<div class="slider-range" :class=" showRange ? 'with-range' : '' ">

			<div class="slider-range-min slider-range-data" v-show="showRange">{{ formattedMin }}</div>

			<div class="slider-main">

				<div class="slider-bar"></div>

				<div class="slider-handle"></div>

			</div>

			<div class="slider-range-max slider-range-data" v-show="showRange">{{ formattedMax }}</div>

		</div>

	</div>

</template>

<script>

export default {

	name: "VueSlider",

	props: [
		"label",
		"classes",
		"showData",
		"showPercentage",
		"min",
		"max",
		"showRange",
		"symbolBefore",
		"symbolAfter"
	],

	data() {
		return {
			position: 0,
			percentage: 0,
			value: 0,
			minValue: this.min ? this.min : 0,
			maxValue: this.max ? this.max : 100,
			isMouseDown: false
		}
	},

	computed: {

		classNames() {

			return this.classes + (this.showData || this.showPercentage ? ' show-data' : '')
		},

		formattedData() {

			return (this.symbolBefore ? this.symbolBefore : '') + this.value + (this.symbolAfter ? this.symbolAfter : '')
		},

		formattedMin() {

			return (this.symbolBefore ? this.symbolBefore : '') + this.minValue + (this.symbolAfter ? this.symbolAfter : '')
		},

		formattedMax() {

			return (this.symbolBefore ? this.symbolBefore : '') + this.maxValue + (this.symbolAfter ? this.symbolAfter : '')
		}
	},

	methods: {

		onMouseDown() {
			this.isMouseDown = true
		},

		onMouseUp() {
			this.isMouseDown = false
		},

		onMouseOutside(e, el) {
			this.isMouseDown = false
		},

		onMouseMove(event) {

			if (!this.isMouseDown) return

			let bar = $(event.currentTarget).find(".slider-bar")
			let handle = $(event.currentTarget).find(".slider-handle")

			let start = bar.offset().left
			let end = start + bar.width()

			let pos = event.pageX

			if (!pos) {
				pos = event.targetTouches[0].pageX
			}

			if (pos < start) {
				pos = start
			} else if (pos > end) {
				pos = end
			}

			this.position = pos - start

			this.percentage = Math.round(
				this.position / (end - start) * 100
			)

			this.value = Math.round(
				this.minValue + (this.percentage / 100) * (this.maxValue - this.minValue)
			)

			handle.css({
				left: this.position - handle.width() / 2 + "px"
			})
		}
	}
}
</script>

<style lang="scss" scoped>

* {
	box-sizing: border-box;
	user-select: none;
}

$slider-bar-color: $theme-color;
$slider-bar-color-hover: rgba(0, 0, 0, 1);
$slider-handle-color: rgba(255, 255, 255, 1);
$slider-handle-color-hover: rgba(255, 255, 255, 1);
$slider-handle-border: 1px solid #aaa;
$slider-handle-border-hover: 3px solid $theme-color;
$slider-data-font-size: $subtitle-font-size;

.slider-wrap {

	position: relative;
	width: 100%;
	max-width: 300px;
	height: auto;
	display: flex;
	flex-wrap: wrap;

	&:hover {

		.slider-range {

			.slider-main {

				.slider-bar {
					background-color: $slider-bar-color-hover;
				}

				.slider-handle {
					border: $slider-handle-border-hover;
					background-color: $slider-handle-color-hover;
				}
			}
		}
	}

	.slider-data {

		position: relative;
		flex: 0 0 100%;
		justify-content: center;
		font-size: $slider-data-font-size;
		display: none;
	}

	&.show-data {

		.slider-data {
			display: flex;
		}
	}

	.slider-range {

		position: relative;
		flex: 0 0 100%;
		display: flex;

		.slider-range-data {

			position: relative;
			width: 100px; height: 50px;
			line-height: 50px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.slider-range-min {

			float: left;
			text-align: right;
		}

		.slider-range-max {

			float: right;
			text-align: left;
		}

		.slider-main {

			position: relative;
			display: flex;
			width: 100%; height: 50px;

			.slider-bar {

				position: absolute;
				left: 0;
				top: 50%;
				width: 100%;
				height: 1px;
				background-color: $slider-bar-color;

				&:hover {
					background-color: $slider-bar-color-hover;
				}
			}

			.slider-handle {

				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 40px;
				height: 40px;
				border-radius: 20px;
				background-color: $slider-handle-color;
				border: $slider-handle-border;
				transition: border 200ms linear, background-color 200ms linear;

				&:hover {
					border: $slider-handle-border-hover;
				}
			}
		}

		&.with-range {

			.slider-main {
				margin-left: 30px; margin-right: 30px;
			}
		}
	}
}

$alt-slider-bar-color: rgba(255, 255, 255, 0.5);
$alt-slider-bar-color-hover: rgba(255, 255, 255, 1);
$alt-slider-handle-color: #555;
$alt-slider-handle-color-hover: #555;
$alt-slider-handle-border: 1px solid #aaa;
$alt-slider-handle-border-hover: 3px solid darken($alt-highlight-color, 10%);
$alt-slider-data-font-size: $alt-subtitle-font-size;

.theme-alt {

	.slider-wrap {

		color: $alt-text-color;

		&:hover {

			.slider-range {

				.slider-main {

					.slider-bar {
						background-color: $alt-slider-bar-color-hover;
					}

					.slider-handle {
						border: $alt-slider-handle-border-hover;
						background-color: $alt-slider-handle-color-hover;
					}
				}
			}
		}

		.slider-range {

			.slider-main {

				.slider-bar {

					background-color: $alt-slider-bar-color;

					&:hover {
						background-color: $alt-slider-bar-color-hover;
					}
				}

				.slider-handle {

					background-color: $alt-slider-handle-color;
					border: $alt-slider-handle-border;

					&:hover {
						border: $alt-slider-handle-border-hover;
					}
				}
			}
		}
	}
}
</style>
