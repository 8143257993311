var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-search-wrap",class:_vm.classNames,style:({
		backgroundColor: _vm.styles && _vm.styles.backgroundColor ? _vm.styles.backgroundColor : ''
	})},[_c('div',{staticClass:"tag-search-box"},[_c('div',{staticClass:"tag-search-main"},[_vm._l((_vm.tags),function(tag){return _c('div',{staticClass:"tag-search-tag",style:({
					backgroundColor: _vm.styles && _vm.styles.tagColor ? _vm.styles.tagColor : '',
					color: _vm.styles && _vm.styles.tagTextColor ? _vm.styles.tagTextColor : ''
				})},[_c('div',[_vm._v(_vm._s(tag.name))]),_c('div',{staticClass:"tag-delete bi-x-lg",on:{"click":function($event){return _vm.removeTag(tag)}}})])}),_c('div',{staticClass:"tag-search-input-box",style:({
					color: _vm.styles && _vm.styles.textColor ? _vm.styles.textColor : ''
				})},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isTyping),expression:"!isTyping"}],staticClass:"tag-search-input-placeholder"},[_vm._v(_vm._s(_vm.placeholder ? _vm.placeholder : 'Search'))]),_c('div',{ref:"input",staticClass:"tag-search-input",attrs:{"contenteditable":"true"},on:{"keydown":_vm.onKeyDown,"keyup":_vm.onKeyUp,"focus":_vm.onFocus}})])],2),_c('div',{staticClass:"tag-search-action"},[_c('div',{staticClass:"tag-search-button bi-search",style:({
					color: _vm.styles && _vm.styles.buttonTextColor ? _vm.styles.buttonTextColor : '',
					backgroundColor: _vm.styles && _vm.styles.buttonColor ? _vm.styles.buttonColor : ''
				}),on:{"click":_vm.search}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmpty),expression:"!isEmpty"}],staticClass:"tag-search-reset bi-ban",style:({
					color: _vm.styles && _vm.styles.textColor ? _vm.styles.textColor : '',
				}),on:{"click":_vm.reset}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }