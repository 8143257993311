<template>

	<div class="toggle-wrap" :class="classNames" @click="onClick">

		<div class="toggle-bar"></div>

		<div class="toggle-handle"></div>

	</div>

</template>

<script>

export default {

	name: "VueToggle",

	props: ["size"],

	data() {

		return {
			'switch': false
		}
	},

	computed: {

		classNames() {
			return (this.size ? 'size-' + this.size : '') + ' ' + (this.switch ? 'on' : '')
		}
	},

	methods: {

		onClick() {

			this.switch = !this.switch

			if (this.switch)
				this.$emit("switch-on")
			else
				this.$emit("switch-off")
		}
	}
}
</script>

<style lang="scss" scoped>

* {
	box-sizing: border-box;
	user-select: none;
}

$toggle-bar-color-off: rgba(0, 0, 0, 0.1);
$toggle-bar-color-on: #37bf65;
$toggle-handle-color: #fff;
$toggle-handle-border-color: #ddd;

.toggle-wrap {

	position: relative;
	width: 100%;
	width: 100px;
	height: 50px;

	.toggle-bar {

		position: absolute;
		left: 25px;
		top: 0px;
		width: 50px;
		height: 50px;
		background-color: $toggle-bar-color-off;
		transition: all 200ms linear;

		&:before {

			position: absolute;
			left: -25px;
			top: 0;
			width: 50px;
			height: 50px;
			border-radius: 25px;
			clip-path: inset(0px 25px 0px 0px);
			background-color: $toggle-bar-color-off;
			content: " ";
			transition: all 200ms linear;
		}

		&:after {

			position: absolute;
			left: 25px;
			top: 0;
			width: 50px;
			height: 50px;
			border-radius: 25px;
			clip-path: inset(0px 0px 0px 25px);
			background-color: $toggle-bar-color-off;
			content: " ";
			transition: all 200ms linear;
		}
	}

	.toggle-handle {

		position: absolute;
		left: 2px;
		top: 2px;
		width: 46px;
		height: 46px;
		background-color: $toggle-handle-color;
		border: 1px solid $toggle-handle-border-color;
		border-radius: 25px;
		transition: all 200ms linear;
	}

	&.on {

		.toggle-bar {

			background-color: $toggle-bar-color-on;

			&:before {
				background-color: $toggle-bar-color-on;
			}

			&:after {
				background-color: $toggle-bar-color-on;
			}
		}

		.toggle-handle {
			left: 52px;
		}
	}

	@for $i from 1 through 10 {

		$half: #{$i * 5px};
		$size: #{$i * 10px};

		&.size-#{$i * 10} {

			width: #{$i * 20px};
			height: $size;

			.toggle-bar {

				left: $half;
				width: $size;
				height: $size;

				&:before {

					left: -$half;
					width: $size;
					height: $size;
					border-radius: $half;
					clip-path: inset(0px $half 0px 0px);
				}

				&:after {

					left: $half;
					width: $size;
					height: $size;
					border-radius: $half;
					clip-path: inset(0px 0px 0px $half);
				}
			}

			.toggle-handle {

				width: #{$i * 10px - 4px};
				height: #{$i * 10px - 4px};
				border-radius: $half;
			}

			&.on {

				.toggle-handle {
					left: #{$i * 10px + 2px};
				}
			}
		}
	}
}

$alt-toggle-bar-color-off: rgba(255, 255, 255, 0.1);
$alt-toggle-bar-color-on: #37bf65;
$alt-toggle-handle-color: #777;
$alt-toggle-handle-border-color: #444;

.theme-alt {

	.toggle-wrap {

		.toggle-bar {

			background-color: $alt-toggle-bar-color-off;

			&:before {

				background-color: $alt-toggle-bar-color-off;
			}

			&:after {

				background-color: $alt-toggle-bar-color-off;
			}
		}

		.toggle-handle {

			background-color: $alt-toggle-handle-color;
			border: 1px solid $alt-toggle-handle-border-color;
		}

		&.on {

			.toggle-bar {

				background-color: $alt-toggle-bar-color-on;

				&:before {
					background-color: $alt-toggle-bar-color-on;
				}

				&:after {
					background-color: $alt-toggle-bar-color-on;
				}
			}
		}
	}
}
</style>
