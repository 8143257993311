<template>

	<div :class="classes" ref="container">

		<vue-list
			:list="menu"
			classes="menu-items select no-divider"
			@select="select"
		/>

		<!-- <div v-if="overflow" class="overflow-indicator ion-ios-arrow-down"></div> -->

	</div>

</template>

<script>

export default {

	name: "VueMenu",

	props: ['menu', 'display'],

	data() {
		return {
			mounted: false
		}
	},

	computed: {

		classes() {
			return `
				menu
				${ this.display ? '' : 'collapsed' }
				${ 'height-' + this.height }
				${ this.overflow ? 'overflow' : '' }
			`
		},

		winh() {
			return $(window).height()
		},

		top() {
			return this.mounted && this.$refs.container ? $(this.$refs.container).offset().top : 0
		},

		overflow() {

			let totalHeight = this.menu.length * 50

			if (totalHeight > this.winh - this.top - 50)
				return true

			return false
		},

		height() {

			let totalHeight = this.menu.length * 50

			if (this.overflow) {

				return Math.floor((this.winh - this.top) / 10) * 10

			} else {

				return this.menu.length * 50
			}

		}
	},

	methods: {

		select(item) {

			this.$emit('select', item)
		}
	},

	mounted() {
		this.mounted = true
	}
}
</script>


<style lang="scss" scoped>

.menu {

	position: relative;
	left: 0; top: 0;
	width: 100%; height: auto;
	overflow: scroll;
	transition: height 200ms ease-out;

	&.collapsed {
		height: 0 !important;
		overflow: hidden;
	}

	.menu-items {

		.menu-item {


		}
	}

	&.overflow {

		.menu-items {
			padding-bottom: 50px;
		}
	}

	.overflow-indicator {
		position: absolute;
		left: 0; bottom: 0;
		width: 100%; height: 50px;
		text-align: center;
		line-height: 50px;
		color: rgba(0, 0, 0, 0.7);
		font-size: 30px;
		background-color: $theme-color;
	}
}

.theme-alt {

	.menu {

	}
}

</style>
