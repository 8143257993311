<template>

	<div class="screen bye" :style="{ backgroundColor }">

		<div id="animation-runner" class="animation">

			<canvas id="sea-canvas"></canvas>

			<div class="ground" :style="groundStyle"></div>

			<div class="runner" :class="runnerClass">

				<div class="head"></div>

				<div class="torso"></div>

				<div class="bottom"></div>

				<div class="arm left">

					<div class="arm-upper">

						<div class="arm-sleeve"></div>

						<div class="arm-lower"></div>

					</div>

				</div>

				<div class="arm right">

					<div class="arm-upper">

						<div class="arm-sleeve"></div>

						<div class="arm-lower"></div>

					</div>

				</div>

				<div class="leg left">

					<div class="leg-upper">

						<div class="leg-short"></div>

						<div class="leg-lower"></div>

					</div>

				</div>

				<div class="leg right">

					<div class="leg-upper">

						<div class="leg-short"></div>

						<div class="leg-lower"></div>

					</div>

				</div>

			</div>

		</div>

		<transition>

			<div id="runner-text" class="animation-text box wide center" v-if="showText">
				I'll tell you all about it when I see you again
			</div>

		</transition>

		<transition>

			<div id="animation-credit" class="animation-text" v-if="showCredit">
				<credit :config="creditConfig" />
			</div>

		</transition>

	</div>

</template>




<script>

export default {

	name: 'Screen',

	props: ['config'],

	data() {

		return {
			backgroundColor: 'Aqua',
			showText: false,
			groundStyle: {},
			seaWave: 0,
			seaColor: "LightSkyBlue",
			seaCanvas: null,
			seaCtx: null,
			waveX: 0,
			waveY: window.innerHeight - 160,
			waveLength: 200,
			waveAmplitude: 20,
			waveAmplitudeStep: 5,
			maxWaveAmplitude: 35,
			minWaveAmplitude: 5,
			runnerClass: 'day',
			showCredit: false
		}
	},

	computed: {

		step() {
			return this.config && this.config.step ? this.config.step : 0
		},

		reverse() {
			return this.config && this.config.reverse ? true : false
		},

		creditConfig() {

			if (this.config.step > 2) {

				return {
					step		: this.config.step - 2,
					duration	: this.config.duration,
					reverse		: this.config.reverse
				}

			} else {

				return {}
			}
		}
	},

	mounted() {

		if (this.reverse)
			this.reverseAnimation(this.step)
		else
			this.animate(this.step)
	},

	beforeDestroy() {
		clearInterval(this.seaWave)
	},

	watch: {

		step() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		},

		reverse() {

			if (this.reverse)
				this.reverseAnimation(this.step)
			else
				this.animate(this.step)
		}
	},

	methods: {

		animate(step) {

			if (step == 1) {

				this.groundStyle = {
					height: '100px'
				}

				this.seaWave = setInterval(() => {

					this.wave()

				}, 50)

				setTimeout(() => this.showText = true, 1000)

			} else if (step == 2) {

				this.runnerClass = 'day center'

			} else if (step == 3) {

				this.showText = false

				this.runnerClass = 'night center'

				this.backgroundColor = '#00547a'

				this.groundStyle = {
					...this.groundStyle,
					backgroundColor: '#007052'
				}

				setTimeout(() => {

					this.seaColor = "Black"
					this.showCredit = true

				}, 1000)
			}
		},

		reverseAnimation(step) {

			if (step == 1) {

				this.showText = false

			} else if (step == 2) {

				this.runnerClass = 'day'

			} else if (step == 3) {

				setTimeout(() => {

					this.seaColor = "LightSkyBlue"
					this.showCredit = false

					this.showText = true
					this.backgroundColor = 'Aqua'

					this.groundStyle = {
						...this.groundStyle,
						backgroundColor: 'MediumSeaGreen'
					}

					this.runnerClass = 'day center'

				}, 2000)
			}
		},

		drawWave(x, y) {

			this.seaCtx.moveTo(x, y)
			this.seaCtx.bezierCurveTo(
				x + 100, y + this.waveAmplitude,
				x + 80, y - this.waveAmplitude,
				// x + 50, y + this.waveAmplitude,
				// x + 50, y - this.waveAmplitude,
				x + this.waveLength, y
			)
			this.seaCtx.lineTo(x + this.waveLength, 0)
			this.seaCtx.lineTo(x, 0)
			this.seaCtx.closePath()

			this.seaCtx.stroke()
			this.seaCtx.fill()
		},

		wave() {

			let winh = $(window).height()
			let winw = $(window).width()

			this.seaCanvas = document.querySelector("#sea-canvas")
			$("#sea-canvas").attr("width", winw)
			$("#sea-canvas").attr("height", winh)
			this.seaCtx = this.seaCanvas.getContext("2d");

			if (window.devicePixelRatio) {

				var hdCanvasWidth = $(this.seaCanvas).attr('width')
				var hdCanvasHeight = $(this.seaCanvas).attr('height')
				var hdCanvasCssWidth = hdCanvasWidth;
				var hdCanvasCssHeight = hdCanvasHeight;

				$(this.seaCanvas).attr('width', hdCanvasWidth * window.devicePixelRatio)
				$(this.seaCanvas).attr('height', hdCanvasHeight * window.devicePixelRatio)
				$(this.seaCanvas).css('width', hdCanvasCssWidth)
				$(this.seaCanvas).css('height', hdCanvasCssHeight)

				this.seaCtx.scale(window.devicePixelRatio, window.devicePixelRatio)
			}

			// this can clear canvas
			this.seaCtx.canvas.width = window.innerWidth
			this.seaCtx.canvas.height = window.innerHeight

			this.seaCtx.lineWidth = 0
			this.seaCtx.strokeStyle = this.seaColor
			this.seaCtx.fillStyle = this.seaColor

			this.waveX += 10

			// if (this.waveY > window.innerHeight / 2)
			// 	this.waveY-=5

			this.waveAmplitude += this.waveAmplitudeStep
			if (this.waveAmplitude > this.maxWaveAmplitude)
				this.waveAmplitudeStep = -2
			else if (this.waveAmplitude < this.minWaveAmplitude)
				this.waveAmplitudeStep = 2

			this.drawWave(this.waveX, this.waveY)


			for (let i = this.waveX + this.waveLength; i < window.innerWidth; i+=this.waveLength) {

				this.drawWave(i, this.waveY)
			}

			for (let i = this.waveX - this.waveLength; i >= -this.waveLength; i -= this.waveLength) {

				this.drawWave(i, this.waveY)
			}
		}
	}
}

</script>




<style lang="scss" scoped>

.bye {

	background-color: Aqua;

	#animation-runner {

		position: absolute; left: 0; top: 0;
		width: 100%; height: 100%;
		align-items: center;
		justify-content: center;

		#sea-canvas {
			position: fixed; left: 0; top: 0;
		}

		.ground {

			position: absolute; left: 0; bottom: 0;
			width: 100%; height: 0;
			background-color: MediumSeaGreen;
		}

		@keyframes moveRightArm {
			0% {
				transform: translateX(-50%) rotate(30deg);
			}
			50% {
				transform: translateX(-50%) rotate(-30deg);
			}
			100% {
				transform: translateX(-50%) rotate(30deg);
			}
		}

		@keyframes moveLeftArm {
			0% {
				transform: translateX(-50%) rotate(-30deg);
			}
			50% {
				transform: translateX(-50%) rotate(30deg);
			}
			100% {
				transform: translateX(-50%) rotate(-30deg);
			}
		}

		@keyframes moveRightUpperLeg {
			0% {
				transform: translateX(-50%) rotate(-60deg);
			}
			50% {
				transform: translateX(-50%) rotate(30deg);
			}
			100% {
				transform: translateX(-50%) rotate(-60deg);
			}
		}

		@keyframes moveLeftUpperLeg {
			0% {
				transform: translateX(-50%) rotate(30deg);
			}
			50% {
				transform: translateX(-50%) rotate(-60deg);
			}
			100% {
				transform: translateX(-50%) rotate(30deg);
			}
		}

		@keyframes moveLeftLowerLeg {
			0% {
				transform: translateX(-50%) rotate(0deg);
			}
			50% {
				transform: translateX(-50%) rotate(60deg);
			}
			100% {
				transform: translateX(-50%) rotate(0deg);
			}
		}

		@keyframes moveRightLowerLeg {
			0% {
				transform: translateX(-50%) rotate(60deg);
			}
			50% {
				transform: translateX(-50%) rotate(0deg);
			}
			100% {
				transform: translateX(-50%) rotate(60deg);
			}
		}

		.runner {

			position: absolute; left: -20%; bottom: 0px;
			width: 400px; height: 400px;
			transform: translateX(-50%) scale(0.8);
			transform-origin: bottom center;
			transition: left 2s linear;

			&.center {
				left: 50%;
			}

			.head {

		  		position: absolute; left: 50%; top: 20px;
		  		width: 70px; height: 70px;
				border-radius: 40px;
		  		transform: translateX(-50%);
		  		background-color: rgba(0, 0, 0, 0.1);
				z-index: 1;
			}
			&.day .head {
				background-color: Bisque;
			}
			&.night .head {
				background-color: rgba(0, 255, 0, 0.3);
				border: 1px solid Lime;
			}

			.torso {

		  		position: absolute; left: 50%; top: 100px;
		  		width: 70px; height: 100px;
		  		transform: translateX(-50%);
		  		background-color: rgba(0, 0, 0, 0.1);
				z-index: 2;
			}
			&.day .torso {
				background-color: #eee;
			}
			&.night .torso {
				background-color: rgba(0, 255, 0, 0.3);
				border: 1px solid Lime;
			}

			.bottom {

		  		position: absolute; left: 50%; top: 200px;
		  		width: 70px; height: 20px;
		  		transform: translateX(-50%);
		  		background-color: rgba(0, 0, 0, 0.1);
				z-index: 2;
			}
			&.day .bottom {
				background-color: Black;
			}
			&.night .bottom {
				background-color: rgba(0, 255, 0, 0.3);
				border: 1px solid Lime;
			}

			.arm {
				position: absolute; left: 50%; top: 100px;
				width: 100px; height: 100px;
				transform: translateX(-50%);

				&.left {
					margin-left: 35px;
					z-index: 1;
				}

				&.right {
					margin-left:-35px;
					z-index: 4;
					.arm-upper {
						animation: moveRightArm 600ms infinite;
					}
				}

				.arm-upper {

					position: absolute; left: 50%; top: 15px;
					width: 30px; height: 50px;
					background-color: rgba(0, 0, 0, 0.1);
					transform: translateX(-50%);
					animation: moveLeftArm 600ms infinite;
					transform-origin: 50% 0;

					.arm-sleeve {
						position: absolute; left: 0; top: 0;
						width: 30px; height: 30px;
						background-color: rgba(0, 0, 0, 0.1);
					}

					&:before {
						position: absolute; left: 0; top: -15px;
						width: 30px; height: 30px;
						background-color: rgba(0, 0, 0, 0.1);
						border-radius: 15px;
						content: "";
					}

					&:after {
						position: absolute; left: 0; bottom: -15px;
						width: 30px; height: 30px;
						background-color: rgba(0, 0, 0, 0.1);
						border-radius: 15px;
						content: "";
					}

					.arm-lower {

						position: absolute; left: 50%; top: 50px;
						width: 30px; height: 50px;
						background-color: rgba(0, 0, 0, 0.1);
						transform: translateX(-50%) rotate(-45deg);
						transform-origin: 50% 0;
					}
				}
			}
			&.day .arm {

				.arm-upper {

					background-color: Bisque;

					.arm-sleeve {
						background-color: White;
					}

					&:before {
						background-color: White;
					}

					&:after {
						background-color: Bisque;
					}

					.arm-lower {
						background-color: Bisque;
					}
				}

				&.left {

					.arm-upper {

						background-color: NavajoWhite;

						&:after {
							background-color: NavajoWhite;
						}

						.arm-lower {
							background-color: NavajoWhite;
						}
					}
				}
			}
			&.night .arm {

				.arm-upper {

					background-color: rgba(0, 255, 0, 0.3);
					border: 1px solid Lime;

					.arm-sleeve {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}

					&:before {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}

					&:after {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}

					.arm-lower {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}
				}
			}


			.leg {
				position: absolute; left: 50%; top: 200px;
				width: 100px; height: 100px;
				transform: translateX(-50%);

				&.left {
					margin-left: 15px;
					z-index: 1;
					.leg-upper {
						animation: moveLeftUpperLeg 600ms infinite;
						.leg-lower {
							animation: moveLeftLowerLeg 600ms infinite;
						}
					}
				}

				&.right {
					margin-left:-15px;
					z-index: 3;
				}

				.leg-upper {

					position: absolute; left: 50%; top: 20px;
					width: 40px; height: 70px;
					background-color: rgba(0, 0, 0, 0.1);
					transform: translateX(-50%);
					animation: moveRightUpperLeg 600ms infinite;
					transform-origin: 50% 0;

					.leg-short {
						position: absolute; left: 0; top: 0px;
						width: 40px; height: 40px;
						background-color: rgba(0, 0, 0, 0.1);
					}

					&:before {
						position: absolute; left: 0; top: -20px;
						width: 40px; height: 40px;
						background-color: rgba(0, 0, 0, 0.1);
						border-radius: 20px;
						content: "";
					}

					&:after {
						position: absolute; left: 0; bottom: -20px;
						width: 40px; height: 40px;
						background-color: rgba(0, 0, 0, 0.1);
						border-radius: 20px;
						content: "";
					}

					.leg-lower {

						position: absolute; left: 50%; top: 70px;
						width: 40px; height: 70px;
						background-color: rgba(0, 0, 0, 0.1);
						transform: translateX(-50%) rotate(45deg);
						transform-origin: 50% 0;
						animation: moveRightLowerLeg 600ms infinite;
					}
				}
			}
			&.day .leg {

				.leg-upper {

					background-color: Bisque;

					.leg-short {
						background-color: Black;
					}

					&:before {
						background-color: Black;
					}

					&:after {
						background-color: Bisque;
					}

					.leg-lower {
						background-color: Bisque;
					}
				}

				&.left {

					.leg-upper {

						background-color: NavajoWhite;

						&:after {
							background-color: NavajoWhite;
						}

						.leg-lower {
							background-color: NavajoWhite;
						}
					}
				}
			}
			&.night .leg {

				.leg-upper {

					background-color: rgba(0, 255, 0, 0.3);
					border: 1px solid Lime;

					.leg-short {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}

					&:before {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}

					&:after {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}

					.leg-lower {
						background-color: rgba(0, 255, 0, 0.3);
						border: 1px solid Lime;
					}
				}
			}
		}
	}

	#runner-text {
		position: absolute; top: 20%; left: 50%;
		transform: translate(-50%, 0);
		height: auto;
		transition: all 1s linear;
		color: #fff;
	}

	#animation-credit {

		position: absolute; left: 0; top: 0%;
		width: 100%; height: 50%;
		overflow: hidden;
		z-index: 1;
		perspective: 400px;
	}
}

</style>
