<template>

	<div
		:class="classes"
	>

		<vue-menu
			:menu="menu"
			:display="true"
			@select="onSelect"
		/>

	</div>

</template>

<script>

	export default {

		name: "VueSidebar",

		props: ['menu', 'display'],

		data() {
			return {
				collapsed: false
			}
		},

		computed: {

			classes() {
				return (
					'sidebar' +
					(this.collapsed ? ' collapsed' : '')
				)
			}
		},

		methods: {

			toggleMenu() {
				this.collapsed = !this.collapsed
			},

			onSelect(item) {
				this.$emit('select', item)
			}
		},

		mounted() {

		}
	}

</script>


<style lang="scss" scoped>

$sidebar-background: rgba(20, 96, 135, 0.1);

.sidebar {

	position: fixed;
	left: 0; top: 0;
	width: 250px; height: 100%;
	padding: 15px;
	background-color: $sidebar-background;
	overflow: hidden;

	.collapsed {
		width: 50px;
	}
}

$alt-sidebar-background: rgba(255, 255, 255, 0.1);

.theme-alt {

	.sidebar {

		background-color: $alt-sidebar-background;

	}
}

</style>
