<template>

	<div class="box wide center">

		<div id="menu-button-box"></div>

		<div class="box wide left max-width-1400 padding-50">

			<div class="box wide pt-50 pb-50">

				<div class="box wide v-mid">

					<span class="font-40 mr-30">Select From A Long List</span>

					<div class="box">
						<vue-button classes="round" size="50" @click="participate">Participate</vue-button>
					</div>

				</div>

			</div>

			<div class="box wide font-18">
				Selecting items from a long list can be time-consuming and frustrating. Here you can try different designs to speed up the process.<br>
				<br>
				1. Simple list sorted alphabetically<br>
				<br>
				2. Add letters on the left-hand side for quick navigation<br>
				<br>
				3. Add a search box
			</div>
			
			<div id="participate" class="box wide center pt-50 pb-50">

				<div class="box design">
					<Design1 :targets="targets" @finish="finish" :isBest="best == 'Design1'" />
				</div>

				<div class="box design">
					<Design2 :targets="targets" @finish="finish" :isBest="best == 'Design2'" />
				</div>

				<div class="box design">
					<Design3 :targets="targets" @finish="finish" :isBest="best == 'Design3'" />
				</div>

			</div>

		</div>

	</div>

</template>

<script>

import cities from './components/cities.js'

import Design1 from './components/Design1'
import Design2 from './components/Design2'
import Design3 from './components/Design3'

export default {

	name: 'CaseStudySelectFromALongList',

	components: {
		Design1,
		Design2,
		Design3,
	},

	data() {

		return {
			score: {}
		}
	},

	computed: {

		targets() {

			let total = 3
			let arr = []

			let partition = Math.floor(cities.length / total)

			while (arr.length < total) {

				let index = partition * arr.length + Math.floor(Math.random() * partition)

				if (!cities[index])
					continue

				if (!arr.includes(cities[index].name))
					arr.push(cities[index].name)
			}
			
			return arr
		},

		best() {

			let bestDesign = ''
			let best = 0

			Object.keys(this.score).forEach(item => {

				let total = this.totalMS(this.score[item])

				if (best == 0 || total < best) {
					bestDesign = item
					best = total
				}
			})
			
			return bestDesign
		}
	},

	methods: {

		go(routeName) {

			if (routeName == this.$route.name)
				return

			this.$router.push({

				name: routeName
			})

			window.scrollTo(0, 0)
		},

		participate() {

			document.getElementById('participate').scrollIntoView()
		},

		finish(e) {

			this.$set(this.score, e.name, {
				min: e.min,
				sec: e.sec,
				ms: e.ms
			})
		},

		totalMS(score) {
			return score.min * 60 * 1000 + score.sec * 1000 + score.ms
		}
	}
}

</script>




<style lang="scss" scoped>

.design.box {
	margin: 10px;
}

</style>
