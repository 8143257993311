<template>

	<div class="container box wide center">

		<div id="equation-part-1" class="equation-icon-box box">

			<div
				class="equation-icon box wide"
				:style="{
					backgroundImage: `url(${ iconUniverse })`,
					borderRadius: '20px'
				}"
			></div>

			<div class="equation-icon-text box wide center">Universe</div>

		</div>

		<div id="plus-1" class="arithmetic box center">+</div>

		<div id="equation-part-2" class="box center">

			<div class="equation-icon-box box">

				<div
					class="equation-icon box wide"
					:style="{ backgroundImage: `url(${ iconEmotion })` }"
				></div>

				<div class="equation-icon-text box wide center">Emotions</div>

			</div>

			<div id="plus-2" class="arithmetic">+</div>

			<div class="equation-icon-box box">

				<div
					class="equation-icon box wide"
					:style="{ backgroundImage: `url(${ iconApple })` }"
				></div>

				<div class="equation-icon-text box wide center">Apple</div>

			</div>

			<div id="equals-1" class="arithmetic">=</div>

			<div class="equation-icon-box box">

				<div
					class="equation-icon box wide"
					:style="{ backgroundImage: `url(${ iconFinder })` }"
				></div>

				<div class="equation-icon-text box wide center">Finder</div>

			</div>

		</div>

		<div id="equals-2" class="arithmetic box center">=</div>

		<div id="equation-part-3" class="equation-icon-box box">

			<div
				class="equation-icon box wide"
				:style="{ backgroundImage: `url(${ logo })` }"
			></div>

			<div class="equation-icon-text box wide center">Emotional Universe</div>

		</div>

	</div>

</template>

<script>

export default {

	name: 'EmotionalUniverse',

	data() {

		return {
			iconUniverse: require('@/assets/img/logo/emotional-universe/universe.png'),
			iconEmotion: require('@/assets/img/logo/emotional-universe/emotion.png'),
			iconApple: require('@/assets/img/logo/emotional-universe/apple.png'),
			iconFinder: require('@/assets/img/logo/emotional-universe/finder.png'),
			logo: require('@/assets/img/logo/emotional-universe/logo.png')
		}
	}
}

</script>




<style lang="scss" scoped>

.container {

	position: fixed;
	width: 100%; height: 100%;
	background-color: #1c1041;
	overflow: scroll;
	padding: 50px 20px 50px 20px;

	#equation-part-2 {
		border-radius: 30px;
		padding: 30px;
		background-color: rgba(255, 255, 255, 0.2);
	}

	.equation-icon-box {

		width: 100px;

		.equation-icon {
			width: 100px; height: 100px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			overflow: hidden;
		}

		.equation-icon-text {
			color: white;
			margin-top: 20px;
		}
	}

	.arithmetic {
		margin: 30px;
		color: white; font-size: 50px;
	}
}

@media screen and (max-width: 960px) {

	.container {
		align-content: flex-start;
	}

	#equation-part-1 {
		flex: 1 0 100%;
		width: 100%;
	}

	#plus-1 {
		flex: 1 0 100%;
		width: 100%;
	}

	#equals-2 {
		flex: 1 0 100%;
		width: 100%;
		transform: rotate(90deg);
	}

	#equation-part-3 {
		flex: 1 0 100%;
		width: 100%;
	}
}

@media screen and (max-width: 580px) {

	#plus-2 {
		flex: 1 0 100%;
		width: 100%;
	}

	#equals-1 {
		flex: 1 0 100%;
		width: 100%;
		transform: rotate(90deg);
	}
}

</style>
