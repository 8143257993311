import { render, staticRenderFns } from "./Design2.vue?vue&type=template&id=40fce217&scoped=true&"
import script from "./Design2.vue?vue&type=script&lang=js&"
export * from "./Design2.vue?vue&type=script&lang=js&"
import style0 from "./Design2.vue?vue&type=style&index=0&id=40fce217&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fce217",
  null
  
)

export default component.exports